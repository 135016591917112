$large-break-point: 992px;
$tablet-break-point: 768px;
$mobile-break-point: 576px;

$font-size: 16px;
$font-family: "Inter";

$authTextColor: rgba(97, 105, 115, 1);
$btnPrimaryBg: rgba(51, 122, 183, 1);
$headerColor: rgba(255, 255, 255, 0); //rgba(37, 55, 65, 1);
$ittBlue: rgba(93, 145, 203, 1);
$ittBlue2: rgba(0, 110, 220, 1); //#006edc
$ittBlueLight: rgba(38, 109, 211, 1); //#266dd3
$ittBlueLighter: rgba(225, 242, 255, 1); //#e1f2ff
$ittLandingBg: rgba(241, 246, 250, 1);
$ittOrange: rgba(236, 134, 21, 1);
$ittOrangeLighter: rgb(255, 237, 212);
$ittPortalBg: rgba(249, 250, 252, 1);
$ittPortalLeftNav: rgba(243, 243, 243, 1);
$ittPortalBlue: rgba(74, 91, 99, 1);
$ittPortalBlueDark: rgba(37, 55, 65, 1);
$ittPortalGrey: rgba(223, 229, 234, 1);
$ittPortalGrey3: rgba(51, 51, 51, 1); //#333333
$ittPortalGrey9B: rgba(155, 155, 155, 1); //#9b9b9b
$ittPortalGrey6: rgba(102, 102, 102, 1); //#666666
$ittPortalGreyA: rgba(170, 170, 170, 1); //#aaaaaa
$ittPortalGreyC: rgba(204, 204, 204, 1); //#cccccc
$ittPortalGreyD: rgba(221, 221, 221, 1); //#dddddd
$ittPortalGreyDF: rgba(223, 223, 223, 1); //#dfdfdf;
$ittPortalGreyE: rgba(238, 238, 238, 1); //#eeeeee
$ittPortalGreyF4: rgba(244, 244, 244, 1); //#f4f4f4;
$ittPortalGreyF7: rgba(247, 247, 247, 1); //#f7f7f7;
$ittPortalGreyF47: rgba(255, 255, 255, 0.28); //#ffffff47
$ittPortalGreyLight2: rgba(194, 194, 194, 1);
$ittPortalGreyLight3: rgb(250, 250, 250);
$ittPortalRedDark: rgba(237, 46, 46, 1); //#ed2e2e
$ittPortalRedLight: rgba(253, 241, 241, 1); //#fdf1f1
$ittPortalRedLight2: rgba(252, 238, 238, 1); //#fceeee
$ittPortalPink: rgba(255, 245, 243, 1); //#fff5f3
$ittPortalGreen: rgba(10, 186, 152, 1); //#0ABA98
$ittPortalGreenLight: rgba(237, 247, 238, 1); //#edf7ee
$ittPortalGreenLight2: rgba(238, 252, 247, 1); //#eefcf7
$ittPortalGreenSuccess: rgba(0, 188, 139, 1); //#00BC8B
$ittPortalYellow: rgba(255, 194, 0, 1); //#ffc200;
$ittPortalYellowWarning: rgba(255, 252, 216, 1); //#fffcd8
$ittPortalPurple: rgba(186, 85, 211, 1); //#ba55d3
$ittPortalPurpleLight: color-mix(
  in srgb,
  $ittPortalPurple 10%,
  white
); //#ba55d3-light
$ittPortalBlack: rgba(0, 0, 0, 1);
$ittPortalWhite: rgba(255, 255, 255, 1);

$header-height: 8.6875rem;
$header-height-upper-part: 4.6875rem;
$header-height-lower-part: $header-height - $header-height-upper-part;
$header-height-upper-part-mobile: 3.5rem;
$header-height-lower-part-mobile: 2.9375rem;
$header-height-mobile: $header-height-upper-part-mobile +
  $header-height-lower-part-mobile;
$header-height-not-authenticated: 40px; //50px;
$hierarchy-pinned-break-point: 1200px;
$header-break-point: 1110px;
$header-break-point-2: $tablet-break-point;
$expiring-message-height: 5.5rem;
$grid-margin-top: 1.125rem;
$min-grid-buttons-height: 3.3rem;
$min-grid-filters-applied-height: 3.3rem;
$msg-inline-padding: 1.25rem;
$msg-svg-size: 1.5rem;
$menu-item-mobile-height: 35px;

//$header-z-index: 10;
$back-to-top-z-index: 10000;
$overlay-z-index: #{$back-to-top-z-index + 1};
$expiring-message-z-index: #{$back-to-top-z-index + 2};
$header-z-index: #{$back-to-top-z-index + 3};
$account-selector-z-index: #{$back-to-top-z-index + 3};
$slide-over-z-index: #{$back-to-top-z-index + 4};
$confirm-dialog-z-index: #{$back-to-top-z-index + 5};
$loader-z-index: #{$back-to-top-z-index + 13};
$message-handler-z-index: #{$back-to-top-z-index + 14};
$header-profile-menu-popover-z-index: #{$back-to-top-z-index + 16};
$google-maps-z-index: #{$back-to-top-z-index + 16};
$modal-z-index: #{$back-to-top-z-index + 17};

$slide-over-field-container: 0.5rem;

#analysis-cards-toolbar-container {
  position: fixed;
  bottom: 4rem;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 4;
  pointer-events: none;
  .analysis-cards-toolbar {
    background-color: black;
    user-select: none;
    color: $ittPortalWhite;
    text-align: center;
    display: flex;
    align-items: center;
    box-shadow: 0 0.125rem 0.25rem 0.125rem rgba(0, 0, 0, 0.08);
    pointer-events: auto;
    @include roundedcornersmixin(1.5rem);
  }
  .how-it-works {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
  }
  .select-bar {
    display: flex;
    flex-direction: column;
    button {
      background-color: $ittPortalGrey3;
      color: #fff;
      font-size: 0.75rem;
      padding: 0 0.5rem 0 0.5rem;
      margin: 0;
      text-align: right;
      width: fit-content;
      border: 0.05rem solid white;
    }
    &-top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $ittPortalGreyF47;
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0.5rem 1.3rem;

      svg,
      img {
        cursor: pointer;
        width: 0.938rem;
        height: 0.938rem;
      }
      .element-with-tooltip {
        display: flex;
        .element-tooltip {
          position: relative;
          top: auto;
          right: auto;
          height: auto;
          .tooltip {
            &.opposite {
              font-size: 0.7rem;
              color: black;
            }
          }
        }
      }
      .icon {
        color: white;
      }
    }
    &-bottom {
      display: flex;
      width: 100%;
      &-list {
        display: flex;
        list-style: none;
        width: 100%;
        justify-content: space-between;
        padding: 0 0.5rem;
        @media (max-width: $mobile-break-point) {
          padding-inline: 0.4rem;
        }
        li {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-inline: 1rem;
          @media (max-width: $mobile-break-point) {
            padding-inline: 0.4rem;
          }
          &.selected {
            img {
              background-color: $ittPortalGreen;
            }
          }
          svg,
          img {
            width: 1.375rem;
            height: 1.375rem;
            margin-top: 0.35rem;
            margin-bottom: 0.35rem;

            @media (max-width: $mobile-break-point) {
              width: 1rem;
              height: 1rem;
            }
          }

          label {
            font-size: 0.594rem;
          }
        }
        &-color-container {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          line-height: 0;
          align-items: start;
          padding: 0 1rem 0 1rem;

          &:after {
            content: "";
            position: absolute;
            top: 0.3rem;
            left: 0;
            height: 75%;
            border-left: 0.063rem solid white;
          }
          span {
            font-size: 0.594rem;
          }
          &-list {
            display: flex;
            list-style: none;
            width: 100%;
            gap: 0;
            margin: 0.5rem 0;
            padding: 0;
            justify-content: space-between;

            li {
              cursor: pointer;
              @include roundedcornersmixin(50%);
              border: 0.188rem solid white;
              width: 1.313rem;
              height: 1.313rem;
              margin: 0 0.25rem;
              &:first-of-type {
                margin-left: 0;
              }
              &:last-of-type {
                margin-right: 0;
              }
              &.selected {
                border-color: $ittPortalGreen;
              }
              @media (max-width: $mobile-break-point) {
                width: 1rem;
                height: 1rem;
                margin: 0 0.125rem;
              }
            }
          }
        }
        &-annotations {
          width: 8rem;
        }
      }
      &-divider {
        display: flex;
        width: 100%;
      }
    }
  }
}

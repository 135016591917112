.sensor-settings {
  $padding: 1.5rem;
  margin-top: 0.125rem;
  cursor: pointer;
  color: $ittBlueLight;
  .svg-inline--fa {
    margin-right: 0.25rem;
    color: $ittBlueLight;
    @include fontsize(0.625rem, normal, 900);
  }
  .slide-over-container {
    cursor: default;
    .svg-inline--fa {
      color: $ittPortalGrey3 !important;
      font-size: 0.875rem !important;
    }
    .form-button-container {
      box-shadow: 0 -0.125rem 0.25rem 0 rgba(0, 0, 0, 0.08) !important;
    }
    .field-with-slide-over {
      .form-button-container {
        box-shadow: none !important;
      }
    }
  }
  h2.title {
    margin: 0;
    margin-left: $padding;
    margin-top: 2rem;
    @include fontsize(1.5rem, normal, 700);
    padding: 0;
    color: $ittPortalGrey3;
  }
  .message {
    padding-left: $padding;
    padding-right: $padding;
    margin-top: 0.125rem;
    @include fontsize(0.75rem, normal, 400);
    color: $ittBlueLight;
  }
  .sensor-status-popup {
    padding-left: $padding;
    padding-right: $padding;
    margin-top: 1.125rem;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .form-fields-container {
    padding-left: $padding;
    padding-right: $padding;
    .form-field {
      background-color: #fff;
      color: $ittPortalGrey3;
    }
  }
  #estimatedBattery {
    font-size: 0.625rem;
    background-color: $ittPortalGreyF4;
    color: $ittBlueLight;
  }
  #sensor-settings-form {
    .form-fields-container {
      min-height: calc(100vh - 21rem);
    }
    .form-tabs {
      margin-top: 1.5rem;
      .form-tabs__content {
        .tab {
          padding: 0;
        }
      }
    }
    .form-field-with-unit,
    .form-group {
      max-width: 350px;
    }

    .form-section {
      width: auto;
      padding: 0;
      margin-top: 1rem;

      @media (max-width: $mobile-break-point) {
        width: 100%;
        overflow-x: hidden;
      }
      &:not(#form-section-sensorOrientationSettings) {
        padding: 0.625rem;
        background-color: $ittPortalGreyF4;
        @include roundedcornersmixin(0.75rem);
        &.transparent-bg {
          background-color: transparent;
          border: 0.125rem solid $ittPortalGreyF4;
          padding: 0.625rem 1.375rem;
        }
      }
      .form-section-content {
        padding-top: 0;
      }
    }

    .form-button-container {
      .form-group {
        max-width: unset;
      }
    }

    #sensorSettingsBandTab-form-sections-id,
    #sensorSettingsThresholdsTab-form-sections-id,
    #sensorSettingsGeneralTab-form-sections-id,
    #sensorSettingsOtherTab-form-sections-id,
    #sensorSettingsAdminTab-form-sections-id {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      @media (max-width: $tablet-break-point) {
        grid-template-columns: 1fr;
      }
    }

    #sensorSettingsGeneralTab-form-sections-id {
      #form-section-sensorOrientationSettings {
        margin-left: 1rem;
        margin-top: 1rem;
        padding-top: 2.375rem;
        background-image: url("../../../assets/images/sensor-position.png");
        background-position: 10% 32%;
        background-repeat: no-repeat;
        border: 3px solid $ittPortalGreyF7;
        @include roundedcornersmixin(0.5rem);
        height: auto;
        display: flex;
        justify-content: right;
        @media (max-width: $tablet-break-point) {
          margin-left: 0;
        }

        .form-section-title {
          color: $ittPortalGrey3;
          @include fontsize(1.125rem, normal, 700);
          padding-bottom: 0.625rem;
        }
        .form-section-content {
          $right-padding: 3rem;
          width: #{calc(39% + $right-padding)};
          padding-right: $right-padding;
        }

        &.ia3 {
          background-image: url("../../../assets/images/sensor-ia3-position.png");
        }
      }
    }
    #form-section-sensorCheckboxesSettings {
      .form-section-title {
        padding-top: 1rem;
      }
      .form-section-fields {
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
        @media (max-width: $mobile-break-point) {
          grid-template-columns: auto;
        }

        .form-group {
          margin: 0;
        }
      }
    }

    #sensorSettingsAdminTab {
      .form-section-title {
        color: $ittPortalGrey3;
        @include fontsize(1.125rem, normal, 700);
        margin-top: 1rem;
      }

      #form-section-sensorAdvancedSettingsFftOptions {
        .field-with-slide-over {
          padding-right: 1.1rem;
        }
      }
    }
    #form-section-sensorAdvancedSettings,
    #form-section-sensorCheckboxesSettings {
      .form-section-fields {
        display: grid;
        grid-gap: 0.5rem;
        @media (max-width: 1200px) {
          grid-template-columns: auto;
        }
        .form-group {
          margin: 0;
        }
      }
    }
    #form-section-sensorAdvancedSettings {
      .form-section-fields {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    #form-section-sensorCheckboxesSettings {
      .form-section-fields {
        grid-template-columns: unset;
      }
    }
    #sensorSettingsActionsTab {
      #form-section-sensorCheckboxesSettings {
        .form-section-fields {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

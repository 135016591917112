.sensor-status-popup {
  $ia3-status-break-point: 1050px;
  min-width: 60vw;
  display: grid;
  grid-template-areas:
    "sensor-status-popup-last-communication sensor-status-popup-details sensor-status-popup-measurements"
    "sensor-status-popup-battery sensor-status-popup-details sensor-status-popup-measurements";
  grid-template-columns: 0.8fr 3fr 5fr;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  padding: 1.5rem 1rem;
  align-items: center;
  color: $ittPortalGrey3;
  @include fontsize(0.625rem, normal, 400);
  &.ia3 {
    min-width: 80vw;
    grid-template-columns: 1fr 3fr 11fr;
    .sensor-status-popup-measurements-row {
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
    }
    @media (max-width: $ia3-status-break-point) {
      grid-template-columns: 1fr 4fr;
      grid-template-areas:
        "sensor-status-popup-last-communication sensor-status-popup-details "
        "sensor-status-popup-battery sensor-status-popup-measurements";
      .sensor-status-popup-measurements {
        border-left: 1px solid $ittPortalGreyDF;
      }
    }
  }
  &.pressure {
    .sensor-status-popup-measurements-row {
      grid-template-columns: 3fr 2fr 2fr !important;
    }
  }

  .upgradeToIa3,
  .upgradeToIa3 ~ div {
    background-color: $ittPortalGreyF7;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .sensor-value-container {
    display: flex;
    &.upgradeToIa3 {
      justify-content: center;
    }
    &:not(.upgradeToIa3) {
      $brms-gap: 0.25rem;
      &[class*="brms"] {
        padding: $brms-gap 0;
      }
      &[class*="brms1_"] {
        border-top: 1px solid $ittPortalGreyDF;
        border-bottom: 1px solid $ittPortalGreyDF;
        width: 100%;
      }
      &.brms1_x-threshold {
        border-left: 1px solid $ittPortalGreyDF;
        @include roundedcornersmixin($brms-gap 0 0 $brms-gap);
      }
      &.brms1_z-threshold {
        border-right: 1px solid $ittPortalGreyDF;
        @include roundedcornersmixin(0 $brms-gap $brms-gap 0);
        margin-right: #{2 * $brms-gap};
      }
    }
  }
  .form-group {
    $check-box-size: 0.75rem;
    height: $check-box-size !important;
    width: $check-box-size !important;
    padding: 0 !important;
    margin: 0 0.25rem 0 0 !important;
    .form-field {
      width: $check-box-size !important;
      height: $check-box-size !important;
      min-height: $check-box-size !important;
      min-width: $check-box-size !important;
      padding: 0;
      margin-right: 0.125rem;

      label {
        padding: 0 !important;
        margin: 0 !important;
        height: 0 !important;
        width: 0 !important;
        display: inline-block !important;
      }

      input[type="checkbox"] {
        &:checked {
          ~ .checkmark:after {
            left: 0.2rem;
            top: 0.1rem;
            width: 0.3rem;
            height: 0.45rem;
          }
        }
        + .checkmark {
          width: $check-box-size;
          height: $check-box-size;
        }
      }
    }
  }

  svg {
    font-size: 1rem;
    color: $ittPortalGrey9B;
    .svg-inline--fa {
      margin-right: 0.25rem;
      @include fontsize(0.875rem, normal, 900);
    }
  }

  .upgrade-to-ia3-container {
    position: absolute;
    right: 15%;
    bottom: 9%;
    .upgrade-to-ia3-btn {
      text-decoration: none;
      @include roundedcornersmixin(0.25rem);
      svg {
        font-size: 1.25rem;
        color: $ittPortalGrey3;
      }
    }
  }

  .sensor-status-popup-last-communication {
    grid-area: sensor-status-popup-last-communication;
    justify-items: center;
    display: grid;

    > div {
      text-align: center;
    }
  }
  .sensor-status-popup-battery {
    grid-area: sensor-status-popup-battery;
    justify-items: center;
    display: grid;
  }
  .sensor-status-popup-details {
    grid-area: sensor-status-popup-details;
    border-left: 1px solid $ittPortalGreyDF;
    padding-left: 2rem;
    height: 100%;
    display: grid;
    align-content: center;
    font-size: 0.75rem;
    line-height: 1rem;
    .sensor-name {
      @include fontsize(0.9375rem, normal, 700);
      margin-bottom: 0.0625rem;

      #btn-sensor-name-system-structure-download {
        margin-left: 0.5rem;
        svg {
          color: $ittPortalBlack !important;
        }
      }
    }
    .mounting-type {
      margin-bottom: 0.125rem;
    }
    .sensor-position {
      margin-bottom: 0.1875rem;
    }
    .popup-trigger-button-container {
      display: inline;
      .sensor-see-more-btn {
        margin-right: 0.625rem;
      }
      button {
        background: none;
        color: $ittBlueLight;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        font-size: 0.75rem;
        margin-right: 0.25rem;
        font-weight: normal;
        width: auto;
      }
    }
    .popup-overlay {
      padding: 0.875rem;
      &::after {
        height: 0;
        width: 0;
      }
    }
    .asset-page-sensor-see-more-popup-title {
      font-weight: bold;
      font-size: 0.75rem;
      margin-bottom: 0.313rem;
    }
    .asset-page-sensor-see-more-popup-description {
      margin: 0;
    }
    .asset-page-sensor-see-more-popup-value {
      margin: 0 0 0.5rem 0;
      &:last-of-type {
        margin: 0;
      }
    }
    p {
      font-size: 0.625rem;
    }
    a,
    button {
      display: inline-block;
      width: auto;
      color: $ittBlueLight;
      text-decoration: none;
      border: 0;
      background-color: transparent;
      margin: 0;
      padding: 0;
      @include fontfamily($font-family, 0.75rem, 1rem, 400);
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
      svg {
        margin-right: 0.25rem;
        color: $ittBlueLight !important;
        font-size: 0.75rem !important;
      }
    }
    .sensor-status-see-more-icon {
      margin-right: 0.25rem;
      color: $ittBlueLight;
      font-size: 0.75rem;
    }
  }
  .sensor-status-popup-measurements {
    position: relative;
    grid-area: sensor-status-popup-measurements;
    padding-left: 1rem;
    height: 100%;
    display: grid;
    align-content: center;

    .sensor-status-popup-measurements-row-header {
      text-align: center;
    }
    .sensor-status-popup-measurements-row {
      display: grid;
      grid-template-columns: 4fr 3fr 3fr 3fr 3fr;
      grid-auto-flow: column;
      grid-gap: 0;
      justify-items: center;

      div {
        word-break: break-word;
        padding-right: 0.5rem;
        padding-bottom: 0.25rem;
        &:last-of-type {
          padding-right: 0;
        }
      }

      & > div:first-of-type {
        justify-self: right;
      }

      svg {
        font-size: 1rem !important;
        color: $ittPortalGrey9B;
      }

      .alarm,
      svg.alarm {
        color: $ittPortalRedDark !important;
      }
      .warning,
      svg.warning {
        color: $ittPortalYellow !important;
      }
      .normal,
      svg.normal {
        color: $ittPortalGreenSuccess !important;
      }
    }
  }
}

#back-to-sensors {
  $back-to-sensors-padding: 1rem;
  $back-to-sensors-arrow-padding: 0.25rem;
  $z-index: 5;
  position: absolute;
  left: $back-to-sensors-padding;
  @include fontsize(0.875rem, 0.875rem, 700);
  z-index: #{$z-index + 1};
  top: 1.6rem;
  padding: 0.5625rem 1.1875rem 0.5625rem #{2 * $back-to-sensors-arrow-padding};
  border-right: 1px solid $ittPortalGreyF7;
  a {
    text-decoration: none;
    color: $ittPortalGrey3;
    &:after {
      content: "";
      left: #{-1 * $back-to-sensors-arrow-padding};
      @include arrow($ittPortalGrey3, 0.25rem);
      @include vertical-align(absolute);
      @include transform(translateY(-50%) rotate(135deg));
    }
  }
}

#modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 0;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $modal-z-index;

  @keyframes show-element {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &.active {
    width: 100vw;
    height: 100vh;

    #modal-box {
      width: 90%;
      padding: 3% 1%;
      height: fit-content;
      @media (max-width: $tablet-break-point) {
        padding: 1% 1%;
        width: 95%;
      }
      @media (min-aspect-ratio: 1/1) {
        width: fit-content;
        max-width: 90%;
        height: 95%;
      }
      div {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    #modal-close-btn {
      animation: show-element 1s linear;
    }
  }

  #modal-box {
    position: relative;
    display: block;
    background: white;
    margin: auto;
    width: 0;
    //height: 0;
    padding: 0;
    @include transition(all 1s ease-in 0s);
    @include vertical-align(relative);
    @include roundedcornersmixin(1rem);

    #modal-close-btn {
      opacity: 1;
      background: transparent;
      position: absolute;
      top: 0;
      right: 1rem;
      font-size: 2rem;
      cursor: pointer;
      color: #fff;
      border: 0;
      z-index: 2;
      @include transition(all 1s ease-in 0s);
    }
  }
}

.slide-over {
  $slide-over-padding: 1rem;
  $slide-over-padding-top: #{2.5 * $slide-over-padding};
  $max-width: 400px;
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: $slide-over-z-index;
  background-color: rgba(0, 0, 0, 0.4);
  @include transition(left 0.2s ease-in 0s);

  &.active {
    left: 0;

    .slide-over-container {
      left: 0;
      .form-button-container {
        position: sticky;
        max-width: none;
      }
    }
  }

  .slide-over-footer {
    $slide-over-footer-gap: 1.25rem;
    grid-area: slide-over-footer;
    background-color: #fff;
    display: grid;
    grid-column-gap: $slide-over-footer-gap;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    justify-items: right;
    width: 100%;

    button {
      $slide-over-footer-btn-width: 18.625rem;
      width: 100%; //$slide-over-footer-btn-width;
      @media (max-width: #{2*$slide-over-footer-btn-width + $slide-over-footer-gap}) {
        width: auto;
        padding: 1rem 2rem;
      }

      + button {
        justify-self: left;
      }
    }
  }

  .slide-over-container {
    left: -100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    height: 100%;
    width: 100%;
    max-width: $max-width;
    padding: #{$slide-over-padding-top} $slide-over-padding 0 $slide-over-padding;
    overflow-y: auto;
    @include transition(left 0.8s ease-in);

    &.one-third-screen {
      min-width: 33.33%;
      max-width: 550px;
      @media (max-width: $tablet-break-point) {
        min-width: 50%;
      }
      @media (max-width: $mobile-break-point) {
        min-width: 100%;
      }
    }

    &.half-screen {
      min-width: 50%;
      max-width: 650px;

      @media (max-width: $mobile-break-point) {
        min-width: 100%;
      }
    }
    &.two-thirds-screen {
      min-width: 66.66%;
      max-width: 950px;

      @media (max-width: $mobile-break-point) {
        min-width: 100%;
      }
    }

    &.full-screen {
      $z-index-menu: 5;
      $slide-over-field-container: 0.5rem;
      width: 100%;
      max-width: 100%;
      height: 100%;
      //padding: 0;
      overflow-y: hidden;

      section {
        @include roundedcornersmixin(0.5rem);
        padding: 0 2.625rem 2rem 2.625rem;
        margin: 0;
        @media (max-width: $mobile-break-point) {
          margin: 0 0 1rem 0;
          padding: 0 2rem 2rem 2rem;
        }
      }

      .form-sections-menu {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: $z-index-menu;
        padding-left: 11rem;

        li {
          display: none;
        }
      }

      .form-fields-container {
        min-height: calc(100vh - 5rem);
      }
      .form-button-container {
        max-width: 100%;
        padding: 0 25% 0.5rem 25%;
        position: sticky;
      }
    }
    &.with-scroll {
      overflow-y: auto;
    }

    &[id$="-manage-columns-container"] {
      .form-button-container {
        padding-top: 1rem;
        .form-group {
          grid-template-columns: 1fr;
          .btn {
            width: 50%;
            margin: 0 auto;
          }
        }
      }
    }

    .header {
      margin-bottom: $slide-over-padding;
      &.splitted {
        display: grid;
        grid-gap: $slide-over-padding;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: "filters-title filters-remove-all";
        align-items: center;
      }
      .title {
        grid-area: filters-title;
        color: $ittPortalGrey3;
        @include fontsize(1.5rem, normal, 700);
      }
      .remove-all {
        grid-area: filters-remove-all;
        justify-self: right;
        color: $ittBlueLight;
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        @include fontsize(0.875rem, normal, 400);
      }
    }
    .sensor-status-popup {
      width: 100%;
    }
    .form-fields-container {
      min-height: calc(100vh - 7.5rem);
    }

    .form-group__radio {
      min-height: calc(100vh - 8.5rem);
      &.hideRadioIcon {
        min-height: calc(100vh - 10.5rem);
        margin-bottom: 0;
      }
      &.radio-with-search {
        min-height: calc(100vh - 10.5rem);
      }
    }
    .form-button-container {
      position: relative;
      bottom: 0;
      @include transform(translateY(0));
      width: 100%;
      max-width: $max-width;
      text-align: center;
      background-color: #fff;
      z-index: 3;
      left: 0;
      padding: 0 10% 0.5rem 10%;
      @include box-shadow(0 -0.125rem 0.25rem 0 rgba(0, 0, 0, 0.08%));
      &.extra-bottom-gap {
        bottom: #{0.5 * $slide-over-padding};
      }
      .form-group {
        display: grid;
        grid-gap: 0.8125rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        margin: 0 !important;
      }
    }
    .manage-columns-list {
      $icon-width: 1.25rem;
      color: $ittPortalGrey3;
      width: calc(100% - #{$icon-width});
      min-height: calc(100vh - 10rem);
      padding: 0;
      margin: 0;
      //display: grid;
      //grid-template-columns: 1fr;
      //align-items: stretch;
      li {
        display: grid;
        position: relative;
        list-style: none;
        height: 3.3125rem;
        align-items: center;
        cursor: all-scroll;
        border-bottom: 1.5px solid $ittPortalGreyF7;
        @include fontsize(0.875rem, normal, 400);
        .manage-columns-list-content {
          //svg
          img {
            position: absolute;
            right: #{-1 * $icon-width};
            font-size: $icon-width;
            color: $ittPortalGreyDF;
          }
        }
      }
    }

    .close-btn {
      justify-self: end;
      position: absolute;
      top: 0;
      margin-top: 2rem;
      margin-right: 2.5rem;
      padding: 0.75rem 2.5rem;
      background-color: #ffffff;
      border: 1px solid $ittPortalGreyDF;
      font-size: 0.75rem;

      span {
        text-transform: uppercase;
      }
    }

    .hierarchy-box {
      margin-right: 0;
      margin-top: 1.5rem;
    }
  }
}

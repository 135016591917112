.i-alert-popup {
  position: absolute;
  z-index: 4;
  padding: 1.5rem 1rem;
  height: fit-content;
  //width: fit-content;
  min-width: 11rem;
  top: -1rem;
  right: 100%;
  background-color: #ffffff;
  border: 1px solid $ittPortalGreyDF;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0.125px 0.5px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.125px 0.5px 0 rgba(0, 0, 0, 0.08);

  &.sensor-status-popup {
    padding-bottom: 1.5rem;
    min-width: 60vw;
  }

  &.simple-display-text {
    top: 0.3rem;
  }

  &.opposite {
    left: 100%;
    right: auto;

    &:after {
      left: calc(-0.5rem + -1px);
      right: auto;
      @include transform(rotate(135deg));
    }
  }
  &.top {
    left: auto;
    right: auto;
    top: 3.1rem;

    &:after {
      left: auto;
      right: auto;
      margin: auto;
      top: calc(-0.5rem + -1px);
      @include transform(rotate(225deg));
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 1rem;
    right: calc(-0.5rem + -1px);
    height: 1rem;
    width: 1rem;
    border: solid $ittPortalGreyDF;
    border-width: 0 1px 1px 0;
    display: inline-block;
    @include transform(rotate(-45deg));
    background-color: #fff;
    margin: auto;
  }
  .form-group {
    @include fontsize(0.875rem, normal, 700);

    &.form-group__radio {
      min-height: fit-content;
    }

    &.form-group__checkbox {
      border: 0;
    }

    &--checkbox {
      margin: 0;
      padding-top: 0;
      padding-bottom: 1rem;
      border-bottom: none;
    }

    &--radio,
    &--checkbox {
      .form-field {
        label {
          padding: 1rem 0 1rem 1.8rem !important;
          border-bottom: 1px solid $ittPortalGreyF7;
          color: $ittPortalGrey3;
          @include fontsize(0.75rem, normal, 400);
          &:before {
            top: 0;
            bottom: 0;
            margin: auto;
          }
          svg {
            margin-right: 0.5rem;
            font-size: 0.625rem;
            color: #000;
          }

          input[type="checkbox"] + .checkmark,
          input[type="radio"]:checked:after {
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }

  & #notificationPreferences-field-container {
    width: fit-content;
    margin-top: -0.58rem;
    padding: 0 0 0 0;

    .checkbox-label {
      padding: 1rem 0 1rem 1.3rem;
      font-size: 0.75rem;
      border-bottom: 1px solid $ittPortalGreyF7;
    }

    .form-field {
      svg {
        width: 0.625rem;
        height: 0.625rem;
        color: $ittPortalBlack;
        margin: 0 0.38rem 0 0.3rem;
      }

      p {
        font-size: 0.875rem;
        margin: 0;
      }

      span {
        margin: 0;
        font-size: 0.75rem;
        color: $ittPortalBlack;

        &.checkmark {
          top: 1rem;
          left: 0.15rem;
        }
      }
    }
  }
}

#asset-train-edit-slide-over-container {
  padding: 0;
}
#assetTrainEditContent {
  $sidebar-break-point: 630px;
  grid-template-columns: 24% 76%;
  @media (max-width: $sidebar-break-point) {
    grid-template-columns: 100%;
  }

  .hierarchy-box .edit-hierarchy {
    z-index: 2;
  }
  .missing-diagnostics {
    &.value-not-defined .touched,
    .touched:placeholder-shown {
      border: 1px solid $ittPortalYellow;
      @include roundedcornersmixin(0.25rem);
    }
  }

  .form-button-container {
    width: 69.3%;
    z-index: 3;
    padding: 0 0 1.5rem 0;
    text-align: center;
    box-shadow: none;
    background-color: transparent;

    .form-group {
      margin: 0;
      padding: 0;
      max-width: none;
      width: 100%;
      display: block;

      button {
        width: 100%;
        margin: 0;
      }
    }
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    #sidebar-title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: 2.5rem;
    }
    .hierarchy-box {
      background-color: #ffffff;
      width: 90%;
      line-height: normal;
      padding: 0.25rem 0.25rem 0.25rem 0.5rem;
      text-align: left;
      margin-top: 2.438rem;
      margin-bottom: 2.313rem;
    }
    .form-step-required-icon,
    .form-step-missing-icon {
      display: inline-block;
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 50%;
      margin-right: 0.313rem;
      position: relative;
      top: 1px;
      background-color: $ittPortalYellow;
      ~ span {
        margin-right: 0.125rem;
      }
    }
    .form-step-required-icon {
      background-color: $ittPortalRedDark;
    }
  }
  #asset-hierarchy-popover {
    height: 100%;
    top: 0;
  }

  .slide-over-container {
    .form-button-container {
      .form-group {
        display: grid;
      }
    }
  }

  .content {
    .form-fields-container {
      max-width: 100%;
      padding: 0 0 0 2rem;

      #driver-sensors-x {
        #driver-drive-end-sensor-x {
          fill: $ittPortalGreenSuccess;
        }
        #driver-non-drive-end-sensor-x {
          fill: $ittBlueLight;
        }
      }
      #driven-sensors-x {
        #driven-drive-end-sensor-x {
          fill: $ittPortalGreenSuccess;
        }
        #driven-non-drive-end-sensor-x {
          fill: $ittBlueLight;
        }
      }
      #intermediate-sensors-x {
        #gearbox-drive-end-sensor-x {
          fill: $ittPortalGreenSuccess;
        }
        #gearbox-motor-drive-end-sensor-x {
          fill: $ittPortalGreenSuccess;
        }
        #gearbox-non-drive-end-sensor-x {
          fill: $ittBlueLight;
        }
        #gearbox-motor-non-drive-end-sensor-x {
          fill: $ittBlueLight;
        }
      }
      .title {
        margin-top: 3.5rem;
        text-align: left;
      }
      .subtitle {
        margin-top: -1.45rem;
        text-align: left;
        margin-left: 3rem;
        color: $ittPortalRedDark;
        font-size: 0.75rem;

        .extra-subtitle {
          &:first-child {
            color: $ittPortalYellow;
            &::before {
              content: "\2022";
              color: $ittPortalGrey3;
              position: relative;
              left: -0.25rem;
            }
          }
          &:nth-child(2),
          &:nth-child(3) {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            &::before {
              content: "\2716";
              margin-right: 0.313rem;
            }
          }
          &:nth-child(2) {
            color: $ittPortalGreenSuccess;
          }
          &:nth-child(3) {
            color: $ittBlueLight;
            margin-left: auto;
            margin-right: -0.3rem;
          }
        }
      }
      .form__step {
        .form-sections-container {
          display: grid;
          padding-right: 1.5rem;
        }
        &[data-step]:not([data-step="1"]) {
          .subtitle {
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: fit-content;
          }
        }
      }
      .form-section-fields {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0 1rem;
        @media (max-width: $tablet-break-point) {
          grid-template-columns: 1fr 1fr;
        }
        @media (max-width: $mobile-break-point) {
          grid-template-columns: 1fr;
        }
      }
      #form-section-firstStepTextFieldsSection {
        padding: 0;
        .form-section-fields {
          .form-group {
            flex-basis: 19.625rem;
            margin-right: 1rem;
          }
        }
      }

      #form-section-uploadImageAndSvgSection {
        .form-section-fields {
          display: flex;
        }
        svg {
          order: 2;
          button:not(.delete-sensor-btn) {
            margin: 0;
            color: #ffffff;
            background-color: $ittPortalGrey3;
          }
        }

        .delete-sensor-btn {
          margin-top: 0;
          width: auto;
          font-size: unset;
        }
      }
      #form-section-svgFieldsSection {
        padding: 0;
        .form-section-fields {
          display: grid;
          grid-template-columns: 1fr;
        }
      }
      #form-section-restStepsFieldsSection {
        padding: 0 0 2rem 0;
      }
    }

    .radio-label {
      font-weight: bold;
    }

    .form-group__radio {
      $item-size: 11.375rem;
      .form-field-radio-images-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, $item-size);
        grid-gap: 1rem;
        padding-bottom: 1rem;
        padding-left: 0.3rem;
        margin-right: 1.5rem;

        label {
          width: 100%;
          height: $item-size;

          &.selected {
            .form-field-radio-single-image-container {
              outline: 0.25rem solid black;
              @include roundedcornersmixin(0.2rem);
            }
          }

          padding-left: 0 !important;

          &:before {
            width: 0;
            height: 0;
          }

          .form-field-radio-single-image-container {
            background-color: rgb(247, 247, 247);
            margin-top: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;

            > img {
              margin-top: auto;
            }

            .form-field-radio-single-image-text-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: auto;
            }

            .choice-selected-icon {
              position: absolute;
              top: 0.25rem;
              right: -1px;
              font-size: 2rem;
              color: $ittPortalGrey3;
            }

            .choice-label {
              font-size: 1rem;
              font-weight: bold;
              color: $ittPortalGrey3;
            }

            .choice-desc {
              font-size: 0.875rem;
              color: $ittPortalGrey3;
              padding-top: 0.125rem;
              padding-bottom: 1rem;
            }

            .path {
              fill: $ittPortalGrey3;
            }
          }
        }

        [type="radio"] {
          display: none;

          &:checked {
            &:after {
              width: 0;
            }
          }
        }
      }
    }
  }
}

.checkbox-container {
  $checkbox-width: 1rem;
  position: relative;
  margin: 0 auto;
  width: $checkbox-width;

  label {
    display: inline-block;
    width: $checkbox-width;
    height: $checkbox-width;
    border: 2px solid $ittPortalGreyF7;
    cursor: pointer;
    @include roundedcornersmixin(0.25rem);
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    + .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: $checkbox-width;
      width: $checkbox-width;
      border: 2px solid $ittPortalGreyDF;
      @include roundedcornersmixin(3px);
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    &:hover ~ .checkmark {
      background-color: $ittPortalGreyC;
    }
    &:checked ~ .checkmark {
      background-color: $ittPortalGreen;
      border: 0;
      &:after {
        display: inline-block;
        left: #{0.35 * $checkbox-width};
        top: #{0.075 * $checkbox-width};
        width: #{0.3 * $checkbox-width};
        height: #{0.7 * $checkbox-width};
        border: solid white;
        border-width: 0 2px 2px 0;
        @include transform(rotate(45deg));
      }
    }
  }
}

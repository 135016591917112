.hierarchy-popover {
  $padding-hierarchy-line: 0.625rem;
  $padding-popover: 2.25rem;
  $padding-popover-small: 1.25rem;

  height: calc(100vh - #{$header-height-upper-part});
  top: $header-height-upper-part;
  @media (max-width: $header-break-point) {
    height: calc(100vh - #{$header-height-mobile});
    top: $header-height-mobile;
  }

  #hierarchy-pin {
    margin-left: 1rem;
    cursor: pointer;
    &.is-blue {
      color: $ittBlueLight;
    }
  }

  .change-hierarchy-level {
    padding: 0.25rem $padding-popover;
    border-bottom: 1px solid $ittPortalGreyDF;
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto max-content;
    @media (max-width: $header-break-point-2) {
      padding: 0 $padding-popover-small;
    }

    form {
      justify-self: right;
      max-width: 18rem;

      .form-fields-container {
        min-height: auto;
        .form-group {
          margin: 0;
        }
        .required {
          display: none;
        }
        .arrow-down {
          bottom: 0;
          top: 0;
          margin: auto;
        }
        #numVisibleLevels,
        #numVisibleLevels-on-form {
          padding-top: 0;
          padding-bottom: 0;
          height: auto;
          @include fontsize(0.625rem, 0.625rem, 700);
        }
      }
    }
  }
  .hierarchy-tree-view {
    padding: 0 $padding-popover;
    text-align: left;
    @media (max-width: $header-break-point-2) {
      padding: 0 $padding-popover-small;
    }

    .selected {
      text-decoration-line: underline;
      text-underline-offset: 0.25rem;
    }

    ul {
      $padding-area: 1rem;
      padding: 0;

      .hierarchy-area-label {
        padding-left: $padding-area;
      }
      .hierarchy-zone-label {
        padding-left: #{4 * $padding-area};
      }
    }
    .hierarchy-account,
    .hierarchy-plant,
    .hierarchy-area,
    .hierarchy-zone {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 3fr 2fr;
      padding: 0;
      &-label {
        padding: $padding-hierarchy-line 0;
      }
    }
    .hierarchy-edit-reorder {
      font-weight: 400;
      width: 100%;
      text-align: right;
      padding: $padding-hierarchy-line 0;
      border-left: 1px solid $ittPortalGreyF7;
      div {
        display: inline-block;
        cursor: pointer;
      }
      .hierarchy-edit,
      .fa-bars {
        cursor: pointer;
        margin-left: 2rem;
      }
    }
    .hierarchy-plants {
      padding: 0;
    }
    &-header {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: max-content auto auto;
      align-items: center;

      @media (max-width: $mobile-break-point) {
        grid-template-columns: auto;
      }

      &-account-collapse {
        &-buttons {
          display: flex;
          gap: 0.5rem;

          .collapse-all,
          .expand-all {
            color: $ittBlueLight;
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: none;
            cursor: pointer;
            @include fontsize(0.75rem, normal, 400);
          }
        }
      }

      .account-name {
        justify-self: left;
        cursor: pointer;
        @include fontsize(1.5rem, normal, 700);
        @media (max-width: $mobile-break-point) {
          justify-self: center;
        }
        li {
          list-style: none;
          padding: 0;
        }
      }
      .add-plant-search {
        justify-self: right;
        display: flex;
        flex-direction: column;
        align-items: end;
        #hierarchy-add-new-plant {
          @include fontsize(0.75rem, normal, 400);
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }

  #numVisibleLevels-slide-over-container {
    padding: 2.5rem 1rem 0 1rem;
  }
  [id$="-container"] {
    padding: 0;
    @media (max-width: $header-break-point-2) {
      min-width: 100%;
      max-width: none;
    }
    #asset-hiearchy-level-container {
      #asset-hiearchy-level-title {
        padding: 1.5rem 0 0.75rem 2.25rem;
        font-size: 1.375rem;
        font-weight: bold;
        max-width: fit-content;
      }
    }
  }
}

#header-hierarchy-form-popover {
  #header-hierarchy-form-popover-container {
    $header-hierarchy-form-gap: 1rem;
    padding: 2.5rem 0 0 0;

    #delete-hierarchy {
      position: absolute;
      right: $header-hierarchy-form-gap;
      cursor: pointer;
    }

    .google-map-hierarchy {
      width: 100%;
      height: calc(100vh - 15rem);
    }

    form {
      #newPlant-field-container,
      #newArea-field-container,
      #newZone-field-container {
        padding-left: $header-hierarchy-form-gap;
        padding-right: $header-hierarchy-form-gap;
      }
      .form__step .title {
        text-align: left;
        padding-left: $header-hierarchy-form-gap;
      }
    }
  }
  .form-button-container {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.hierarchy-popover.anchor-top {
  top: 0;
  height: calc(100vh);
}

#gateway-page {
  #gateway-page-container {
    background-color: $ittPortalGreyF7;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    scroll-behavior: smooth;
  }

  #gateway-page-nav {
    display: flex;
    justify-content: left;
    padding: 0.45rem 0 0.64rem 0;
    align-items: center;
    position: relative;
    height: 4.813rem;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 1150px) {
      display: block;
      height: fit-content;
    }
    #back-to-sensors {
      position: relative;
      top: 0;
    }

    h2 {
      margin: 0 0 0 2.25rem;
    }
  }

  #gatewaySensorPage-sensor-section {
    background-color: $ittPortalGreyF7;
    height: 100vh;
    #gatewaySensorGrid {
      background-color: white;
      padding-top: 1rem;

      #gatewaySensorGrid-filters-container {
        min-width: 0;
        #gatewaySensorGrid-gridFiltersForm {
          .form-fields-container {
            min-height: calc(100vh - 9rem);
          }
        }
        .slide-over-footer {
          padding-inline: 2rem;
        }
      }

      #rssiBars-slide-over-container {
        min-width: 0;
        #rssiBars-field-container {
          label {
            display: flex;
            align-items: center;
            span {
              color: $ittPortalBlack;
            }
          }
        }
      }
    }

    #gatewaySensorPage-sensor-info-container {
      background-color: white;
      padding: 1rem;
      margin: 1rem 2rem 1.5rem 2rem;
      @include roundedcornersmixin(0.5rem);
      &-title {
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 700;
        margin: 1rem 0 0.5rem 1rem;
      }
    }
  }
}

* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  scroll-behavior: smooth;
  font-size: $font-size !important;
  overflow-x: hidden;
  background-color: #fff;
}
html {
  height: 100%;
  //overflow-y: hidden;
  -ms-overflow-style: scrollbar;
}
body {
  margin: 0;
  @include inter-regular;
  @include transition(transform 1s linear);
  overflow: hidden;
  color: $ittPortalGrey3;
}
a,
button,
li,
input,
textarea {
  @include transition(all 0.3s ease 0s);
  font-family: inherit;
}
a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent;
}
p {
  margin: 0 0 0.625rem;
}
.displayNone {
  display: none !important;
}
.img-responsive {
  display: block;
  width: inherit;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
.clearboth {
  clear: both;
}
.no-padding {
  margin: 0 !important;
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.sr-only {
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.centered {
  text-align: center !important;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  width: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
  }
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.blurry-text {
  color: $ittPortalGreyC !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.btn {
  @include roundedcornersmixin(0.25rem);
  border: 0;
  font-weight: bold;
  font-size: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  &-primary {
    background-color: $ittPortalGrey3;
    color: #fff;
  }
  &-orange {
    border-color: $ittOrange;
    background: $ittOrange;
  }
  &-rerun-diagnostics {
    background-color: #fff;
    border: solid 1px $ittPortalGreyDF;
    border-radius: 0.25rem;
    padding: 0.4rem 0.438rem;
    margin: 0 0.25rem 0 0;
    font-size: 0.625rem;
    font-weight: bold;
    cursor: pointer;
    max-height: 2.25rem;
    word-wrap: break-word;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
  }

  &-add-new {
    $icons-width: 1rem;
    background-color: $ittPortalGreenSuccess;
    color: $ittPortalGrey3;
    padding: 1rem 1rem 1rem 2.5 * $icons-width;
    display: inline-block;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    border: none;
    @include roundedcornersmixin(0.25rem);
    @include fontsize(0.75rem, normal, 700);
    &:before {
      font-family: "Font Awesome 5 Free";
      content: "\f067";
      font-weight: 900;
      font-size: 1rem;
      position: absolute;
      top: $icons-width * 0.75;
      left: $icons-width;
      width: $icons-width;
      height: $icons-width;
    }
  }
  &__grid {
    border: 1px solid $ittPortalGreyDF;
    padding: 0.75rem 0.5rem;
    background-color: transparent;
    color: $ittPortalGrey3;
    @include fontsize(0.625rem, normal, 700);
    @include roundedcornersmixin(0.25rem);

    svg {
      font-size: 0.5rem;
      margin-right: 0.3125rem;
      display: inline-block;
      vertical-align: initial;
    }
  }
  &__export-reports {
    color: black;
    background-color: white;
    border: 1px solid $ittPortalGreyDF;
    $icons-width: 1rem;
    &:before {
      font-family: "Font Awesome 5 Free";
      content: "\f15b";
      font-weight: 900;
      font-size: 1rem;
      position: absolute;
      left: 7.5rem;
      width: 1rem;
      height: 1rem;
      z-index: 4;
    }
  }
}

#waitLoader {
  $spinSize: 2.5rem;
  display: none;
  position: fixed;
  z-index: $loader-z-index;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8) !important;
  &.active {
    display: block;
  }
  .fa-spin {
    position: absolute;
    top: calc(50% - #{$spinSize});
    left: calc(50% - #{$spinSize});
    color: $ittPortalGreen;
    font-size: #{2 * $spinSize};
  }
  #extra-message-container {
    color: $ittPortalGreyC;
    margin-top: 12.5%;
  }
}
.form__error {
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-top: 0.25rem;
}
.form__error {
  color: $ittPortalRedDark;
  .green {
    color: $ittPortalGreen;
  }
}

.sensor-priority,
.asset-train-priority {
  .priorityTitle {
    font-weight: 700;
    text-align: center;
  }
  &.low .priorityTitle,
  .fa-arrow-down {
    color: $ittPortalGreenSuccess !important;
  }
  &.high .priorityTitle,
  .fa-arrow-up {
    color: $ittPortalRedDark !important;
  }
  &.medium .priorityTitle,
  .fa-minus {
    color: $ittPortalYellow !important;
  }
}
.asset-train-priority {
  &.selection-box {
    background-color: transparent;
    border: 1px solid $ittPortalGreyF4;
  }
}
.sensor-priority {
  #priority-field-container .choice-desc {
    display: none !important;
  }
}

.switch-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 2.875rem;

  label {
    @include fontsize(0.875rem, normal, 700);
    color: $ittPortalGrey3;
  }

  .btn-color-mode-switch {
    $padding: 0.85rem;
    $padding-inner: 0.25rem;
    display: inline-block;
    margin: 0;
    position: relative;
    height: 100%;

    > label.btn-color-mode-switch-inner {
      margin: 0;
      width: 280px;
      height: 100%;
      background-color: #fff;
      overflow: hidden;
      position: relative;
      transition: all 0.3s ease;
      @include roundedcornersmixin(0.25rem);
      display: grid;
      align-items: center;
      cursor: pointer;

      &:before {
        content: attr(data-on);
        position: absolute;
        width: calc(50% - #{2 * $padding-inner});
        right: $padding-inner;
      }
      &:after {
        content: attr(data-off);
        width: calc(50% - #{2 * $padding-inner});
        height: calc(100% - #{2 * $padding-inner});
        background: $ittPortalGrey3;
        color: #fff;
        @include roundedcornersmixin(0.25rem);
        @include transition(all 0.3s ease);
        position: absolute;
        left: $padding-inner;
        top: $padding-inner;
        text-align: center;
        padding: 0;
        display: grid;
        align-items: center;
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
      width: 50%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: 1;
      margin: 0;

      &:checked {
        + label.btn-color-mode-switch-inner {
          &:after {
            content: attr(data-on);
            left: 50%;
            /*padding-top: calc(#{$padding - $padding-inner});*/
            padding: 0;
            background: $ittPortalGrey3;
          }
          &:before {
            content: attr(data-off);
            right: auto;
            left: $padding-inner;
          }
        }
      }
    }
  }
}
.grecaptcha-badge {
  display: none !important;
}
.pac-container {
  z-index: $google-maps-z-index !important;
}
.no-print {
  @media print {
    display: none !important;
  }
}

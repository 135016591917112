#message-container {
  $top: 100px;
  $default-space: 20px;
  $padding-right: 2.25rem;
  $remove-img-size: 0.75rem;
  position: fixed;
  width: 50%;
  min-width: 280px;
  height: 0;
  top: #{-1 * $top};
  left: #{"max(25%, 1rem)"};
  z-index: $message-handler-z-index;

  .message-container-inner {
    padding: $msg-inline-padding 1.5rem $msg-inline-padding $padding-right;
    display: flex;
  }
  .message {
    position: relative;
    width: 100%;
    top: #{-1 * $top};
    @include transition(top 0.5s linear);
    @include roundedcornersmixin(0.25rem);

    &.active {
      top: #{2 * $top};
      + .message.active {
        margin-top: $default-space;
      }
    }
    &.hide {
      @include transition(all 0.5s linear);
      display: block !important;
      height: 0 !important;
      padding: 0 !important;
      opacity: 0;
      margin: 0 !important;
      border: 0 !important;
      overflow: hidden;
    }
    .svg-inline--fa,
    img {
      width: $msg-svg-size;
      height: $msg-svg-size;
      align-self: center;
      margin-right: 0.813rem;
      @include fontsize(1.5, normal, 900);
    }
    ul {
      margin-bottom: 0;
      margin-top: 0;
    }
    .message-content {
      display: inline-block;
      font-size: 0.875rem;
      width: #{"calc(100% - 4.25rem)"};
    }
    &#message-success {
      color: $ittPortalGreenSuccess;
      background-color: $ittPortalGreenLight;
    }
    &#message-error {
      color: $ittPortalRedDark;
      background-color: $ittPortalPink;
    }
    &#message-info {
      color: $ittBlueLight;
      background-color: $ittBlueLighter;
    }
    .close-button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: $padding-right;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
      border: 0;
      @include fontsize(0.8em, 0.8em, 700);
      img {
        width: $remove-img-size;
        height: $remove-img-size;
        margin: 0;
      }
    }
  }
}

.form-step {
  $number-width: 2rem;
  $gap: 0.5rem;
  $color-active: $ittPortalGrey3;
  $color-inactive: $ittPortalGreyDF;

  display: grid;
  grid-gap: $gap;
  grid-template-columns: $number-width auto;
  grid-template-areas: "number right";
  align-items: center;

  &.active,
  &.previous {
    .form-step-number {
      background-color: $color-active;
      &:before,
      &:after {
        background-color: $color-active;
      }
      &:first-of-type {
        margin-top: 0;
        &:before {
          display: none;
        }
      }
    }
    .form-step-right {
      .form-step-label {
        margin-top: 0;
        color: $color-active;
      }
    }
  }

  &.previous {
    .form-step-right {
      .form-step-label {
        font-size: 0.75rem;
      }
    }
  }

  &:last-of-type {
    .form-step-number {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }
  }
  .form-step-number {
    grid-area: number;
    position: relative;
    background-color: $color-inactive;
    color: #fff;
    text-align: center;
    height: $number-width;
    @include fontsize(1em, $number-width, 700);
    @include roundedcornersmixin(50%);
    margin: #{1.5 * $gap} 0;
    &:before {
      content: "";
      position: absolute;
      top: #{-1 * $gap};
      left: calc($number-width / 2);
      right: 0;
      width: 2px;
      height: $gap;
      background-color: $color-inactive;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: #{-1 * $gap};
      left: calc($number-width / 2);
      width: 2px;
      height: $gap;
      background-color: $color-inactive;
    }
  }
  .form-step-right {
    grid-area: right;
    text-align: left;
    .form-step-label {
      color: $color-inactive;
      margin-top: $gap;
      @include fontsize(1.125rem, normal, 700);
    }
    .form-step-edit {
      color: $ittBlueLight;
      cursor: pointer;
      display: inline-block;
      @include fontsize(0.75rem, normal, 400);
    }
  }
}

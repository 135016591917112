.slide-over-form-with-sidebar {
  $slide-over-form-with-sidebar-break-point: 630px;
  $border-padding: 1.85rem;
  $max-width: 468px;

  display: grid;
  grid-gap: 0;
  overflow-y: auto;
  grid-template-columns: 25% 75%;
  grid-template-rows: auto;
  grid-template-areas: "sidebar content";
  background-color: $ittPortalGreyF7;
  padding: 0;
  height: 100%;
  min-height: auto;
  @media (max-width: $slide-over-form-with-sidebar-break-point) {
    grid-template-columns: 100%;
    min-height: 100vh;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    grid-template-areas:
      "sidebar"
      "content";
  }
  &.slide-over-container.full-screen {
    @media (max-width: $slide-over-form-with-sidebar-break-point) {
      overflow-y: auto;
    }
  }
  .content {
    grid-area: content;
    position: relative;
    min-height: 100vh;
    display: grid;
    align-items: center;
    justify-items: center;
    z-index: 2;
    background-color: #fff;
    box-shadow: -10px 0 15px 0 rgba(0, 0, 0, 0.04);

    @media (max-width: $slide-over-form-with-sidebar-break-point) {
      border-right: none;
      padding: 5.25rem 0 0 0;
      height: fit-content;
      min-height: calc(100vh - 13rem);
      align-content: start;
    }
    .form-fields-container {
      padding: 0 2rem;
      max-width: $max-width;
      width: 100%;
      margin: 0 auto;
      @media (max-height: 500px) {
        padding: 1rem 2rem 0;
        margin-bottom: 7rem;
      }
      &.full-width {
        max-width: none;
      }
    }
    .form-button-container {
      position: absolute;
      bottom: 0;
      width: 70%;
      margin: 0 auto;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 2;
      @media (max-width: $max-width) {
        width: 100%;
      }
      @include box-shadow(0 -0.125rem 0.25rem 0 rgba(0, 0, 0, 0.08));
      .form-group {
        max-width: $max-width;
        width: 100%;
        margin: 1rem auto;
        padding: 0 2rem;
      }
    }
  }
  .sidebar {
    grid-area: sidebar;
    display: grid;
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-align: center;
    justify-self: left;
    align-self: center;
    align-content: center;
    justify-content: left;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-left: 1.875rem;

    @media (max-width: $slide-over-form-with-sidebar-break-point) {
      min-height: auto;
      height: fit-content;
      //margin-top: calc(0.5rem + #{$header-height});
      margin-top: 0.5rem;
      padding-bottom: 1rem;
    }
  }
}

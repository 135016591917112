.google-map-container {
  $form-padding: 1rem;
  margin-top: #{1.5 * $form-padding};
  position: relative;

  .switch-container {
    position: absolute;
    top: 1rem;
    right: 0.875rem;
    z-index: 2;

    #map-edit-mode-form {
      position: absolute;
      z-index: 2;
      width: 100%;
      right: 0;
      top: 2.3rem;

      .form-button-container {
        .form-group {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 0.5rem;
        }
      }
    }
  }

  .google-autocomplete-form {
    z-index: 2;
    position: absolute;
    width: calc(100% - #{2 * $form-padding});
    top: $form-padding;
    left: $form-padding;

    .form-fields-container {
      min-height: auto;
      .form-group .form-field input[type="text"] {
        background-color: #fff;
      }
    }
  }
}
.sensor-info-window {
  @include fontsize(0.625rem, normal, 400);
  a {
    color: $ittBlueLight;
    text-decoration: underline;
  }
  .sensor-status-box {
    display: inline-flex;
    min-width: 3rem;
    background-color: $ittPortalGreyF7;
    @include roundedcornersmixin(0.25rem);
    padding: 0.25rem;
    margin-bottom: 0.25rem;
    &.blue {
      background-color: $ittBlueLighter;
    }
    &.pink,
    &.alarm {
      background-color: $ittPortalPink;
    }
    &.warning {
      background-color: $ittPortalYellowWarning;
    }

    .sensor-status-icon {
      $total-width: 1rem;
      $icons-width: 0.625rem;
      width: $total-width;
      height: $icons-width;
      position: relative;
      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: $icons-width;
        position: absolute;
        top: #{calc(($total-width - $icons-width) / 2)};
        left: 0;
        width: $icons-width;
        height: $icons-width;
        z-index: 4;
      }
      &.normal {
        &:before {
          content: "\f00c";
        }
      }
      &.warning {
        &:before {
          content: "\f071";
        }
      }
      &.alarm {
        &:before {
          content: "\f057";
        }
      }
      &.syncTimeNeeded {
        &:before {
          content: "\f06a";
        }
      }
    }
  }
}

#sensorsGrid {
  #slide-over-asset-container-id {
    overflow: inherit;
    .grid-data {
      height: calc(100vh - 19rem);
      @media (max-height: 685px) {
        height: calc(100vh - 16rem);
      }
      @media (max-height: 600px) {
        height: calc(100vh - 18.4rem);
      }
      @media (max-width: $mobile-break-point) {
        height: calc(100vh - 20rem);
      }
    }
    #assets-grid {
      max-height: 83%;
      overflow: hidden;
    }
  }
  .maintenance-status-container {
    width: 100%;
    height: 100%;
    overflow-wrap: unset;
    &.maintenance-severe {
      background: $ittPortalRedLight;
      svg {
        color: $ittPortalRedDark;
      }
    }
    &.maintenance-warning {
      background: $ittPortalYellowWarning;
      svg {
        color: $ittPortalYellow;
      }
    }
    &.maintenance-info {
      background: $ittPortalPurpleLight;
      svg {
        color: $ittPortalPurple;
      }
    }
    .maintenance-status {
      margin: 1.563rem 1.875rem 1.563rem 1.844rem;
      display: inline-block;
      .maintenance-icon {
        margin-right: 0.313rem;
        .grid-image {
          height: 0.875rem;
        }
      }
    }
  }
}
#assets-grid-title {
  margin-top: 0.313rem;
  .add-new-asset {
    display: inline-block;
    margin-left: 1rem;
  }
}

#hwsw-update-page {
  #hwsw-update-page-container {
    padding: 0;
    #hwswRevisionUpdateForm {
      .tab.active {
        max-height: unset;
      }
      .form-sections-container {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 100%;

        @media (max-width: $tablet-break-point) {
          grid-template-columns: 100%;
          grid-template-rows: auto;
        }
      }
      section {
        padding-right: 0;
        padding-bottom: 0;
        &#form-section-basicInfoSection {
          padding-right: 2rem;
          width: 100%;

          .form-section-content {
            @include roundedcornersmixin(0.5rem);
            margin: 0;
          }
          @media (max-width: $tablet-break-point) {
            padding-left: 0;
          }
        }
        &#form-section-sensorSection {
          background: #fff;
          @include box-shadow(0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.08));
          height: fit-content;
          @media (max-width: $tablet-break-point) {
            @include box-shadow(none);
            padding: 0 1rem 0 0;
          }
          .form-section-content {
            @media (max-width: 1400px) and (min-width: $tablet-break-point) {
              padding-top: 0;
            }
          }
          #sensorsGrid {
            @media (max-width: 1400px) and (min-width: $tablet-break-point) {
              margin-top: 0;
            }
            .grid-data-found {
              @media (max-width: 1400px) and (min-width: $tablet-break-point) {
                padding-top: 1.6rem;
              }
            }
          }
        }
        @media (max-width: 1220px) and (min-width: $tablet-break-point) {
          .form-group {
            &.half-width {
              display: block;
              width: 100%;
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }

    .grid-buttons-applied-filters-container {
      display: none;
    }
    .form-button-container .form-group {
      display: grid;
      grid-gap: 0.8125rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      margin: 0 !important;
    }
    .hwsw-revision-update-additional-info {
      margin-top: 1rem;
    }
    #sensors-field-container {
      .form-field {
        max-height: 85vh;
        .grid {
          overflow-y: hidden;
        }
      }
    }
  }
}

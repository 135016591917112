.element-with-tooltip {
  position: relative;
  display: grid;
  .column-content {
    $right-margin: 0.5rem;
    $element-svg-width: 0.825rem;
    max-width: calc(100% - #{$element-svg-width + $right-margin});
    width: fit-content;
    white-space: nowrap;
    @media (max-width: 1310px) {
      max-width: none;
      white-space: normal;
    }

    + .element-tooltip {
      margin-left: 0.3rem;
      position: static;
      right: $right-margin;
      align-self: center;
      width: $element-svg-width;
      height: $element-svg-width;
      z-index: unset;

      svg {
        margin: 0 0 0 0.2rem;
        @media (max-width: 1310px) {
          margin: 0;
        }
      }

      .tooltip {
        font-size: 0.66rem;
        position: relative;
        left: 2rem;
        top: -2.43rem;

        &.opposite {
          left: 200%;
        }
      }

      .i-alert-popup {
        &.opposite:after {
          top: 0.77rem;
        }
      }
    }

    .icon {
      position: static;
    }
  }
  .element-tooltip {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    right: 1rem;
    top: 1rem;
    position: absolute;
    z-index: 4;
    color: $ittPortalGrey3;
    .tooltip {
      font-size: 0.875rem;
      right: 135%;
      box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.08);
      &.opposite {
        left: 100%;
      }
      &.top {
        left: auto;
        right: -0.8rem;
        bottom: 1.5rem;
        top: auto;
        &:after {
          content: "";
          position: absolute;
          top: calc(100% - 0.4rem);
          right: 1rem;
          height: 1rem;
          width: 1rem;
          border: solid $ittPortalGreyDF;
          border-width: 0 0 1px 1px;
          display: inline-block;
          @include transform(rotate(-45deg));
          background-color: #fff;
          margin: auto;
        }
      }

      &.bottom {
        left: auto;
        right: -0.8rem;
        bottom: auto;
        top: 1.5rem;
        &:after {
          content: "";
          position: absolute;
          top: calc(-0.5rem - 1px);
          right: 1rem;
          height: 1rem;
          width: 1rem;
          border: solid $ittPortalGreyDF;
          border-width: 1px 1px 0 0;
          display: inline-block;
          @include transform(rotate(-45deg));
          background-color: #fff;
          margin: auto;
        }
      }
    }
    .i-alert-popup {
      padding: 0.5rem 0.5rem 0.5rem 0.7rem;

      &.opposite:after {
        top: 1.1rem;
      }
    }
  }
}

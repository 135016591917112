@mixin analysisCardsButton() {
  width: fit-content;
  height: 1.438rem;
  padding: 0.375rem 0.688rem 0.313rem 0.813rem;
  border-radius: 0.25rem;
  font-size: 0.625rem;
  font-weight: bold;
}

#analysisCardsGrid {
  $arrow-down-width: 0.188rem;
  #analysisCardsGrid-grid-data {
    #priority-watch-form,
    #severity-form {
      .radio-label {
        font-size: 1.375rem;
        font-weight: bold;
      }

      .radio-label {
        font-size: 1rem;
      }

      .form-group__radio {
        min-height: auto;
      }

      .form-button-container {
        position: absolute;
        bottom: 0;
      }

      .fa-arrow-down {
        color: $ittPortalGreen;
      }

      .fa-minus {
        color: $ittOrange;
      }

      .fa-arrow-up {
        color: $ittPortalRedDark;
      }
    }

    #analysis-cards-card-due-date-form {
      #dueDate-field-container {
        .arrow-down {
          right: 0.7rem !important;
        }
      }
    }

    .form .form-group__radio {
      label {
        color: rgb(51, 51, 51);
        margin-bottom: 2rem;
        font-size: 0.875rem;
      }
      input[type="text"] {
        &:not(:placeholder-shown) + label,
        &:focus + label {
          font-size: 0.75rem;
        }
      }
      .search-field-placeholder {
        font-size: 1rem;
      }
    }

    #assignee-search {
      margin-bottom: 1rem;
    }

    .analysis-cards-severity-container {
      &-content {
        display: flex;
        gap: 0.3rem;
        width: fit-content;
        align-items: center;
        padding: 0.375rem 0.5rem;
        background-color: $ittPortalGreyF7;
        @include roundedcornersmixin(0.25rem);
        cursor: pointer;
      }

      svg {
        font-size: 0.625rem;

        &.red {
          color: $ittPortalRedDark;
        }

        &.orange {
          color: $ittOrange;
        }

        &.green {
          color: $ittPortalGreen;
        }
      }

      &.red {
        color: $ittPortalRedDark;
      }

      &.orange {
        color: $ittOrange;
      }

      &.green {
        color: $ittPortalGreen;
      }
    }

    .slide-over .slide-over-container .header {
      margin-bottom: 0;
    }

    #dueDate-button-delete {
      right: 1.4rem;
      top: 2.6rem;
      padding: 0;
    }

    .slide-over .slide-over-container {
      .delete-btn-with-arrow {
        svg {
          color: $ittPortalBlack;
        }
      }
      #dueDateTitle-field-container {
        label {
          color: $ittPortalBlack;
          font-size: 1.2rem;
          font-weight: bold;
        }
      }

      .header {
        .title {
          margin-bottom: 1rem;
        }
      }

      #dueDate-field-container {
        width: 100%;

        .form-field {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          input {
            min-width: 100%;
            width: 100%;
          }

          .arrow-down {
            right: 2.4rem;
          }

          svg {
            color: $ittPortalBlack;
          }
        }
      }

      .form-button-container {
        z-index: 4;
      }
    }

    #cardStatus-field-container {
      .form-field {
        .radio-label {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }

        label {
          color: $ittPortalBlack;
          margin: 0.6rem 0;
          padding-bottom: 1.2rem;
          font-size: 0.875rem;
          border-bottom: 1.5px solid $ittPortalGreyF7;
        }
      }
    }

    .analysis-cards-type {
      display: flex;
      flex-direction: column;
      padding: 0.1rem 0.7rem 0.2rem 0;

      &-title {
        display: flex;
        flex-direction: column;
        @include analysisCardsButton;
        background-color: $ittBlueLighter;
        color: $ittBlueLight;
        height: auto;
        width: auto;
      }

      &-severity {
        &-minor {
          @include analysisCardsButton;
          background-color: $ittPortalGreenLight2;
          color: $ittPortalGreen;
        }
        &-major {
          @include analysisCardsButton;
          background-color: $ittOrangeLighter;
          color: $ittOrange;
        }
        &-critical {
          @include analysisCardsButton;
          background-color: $ittPortalRedLight2;
          color: $ittPortalRedDark;
        }
      }
    }

    .analysis-cards-action-btn {
      font-weight: bold;
      color: $ittBlueLight;
      cursor: pointer;
    }

    .analysis-cards-assignee {
      display: flex;
      gap: 0.3rem;
      cursor: pointer;

      &-content-profile-img {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    .analysis-cards-last-comment {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }

    .analysis-card-menu-button-priority {
      display: flex;
      flex-direction: column;
      width: fit-content;
      padding: 0.375rem 0.5rem;
      background-color: #f7f7f7;
      @include roundedcornersmixin(0.25rem);
      cursor: pointer;

      svg {
        font-size: 0.625rem;
      }

      .green,
      .open {
        background-color: $ittPortalGreen !important;
        border: none;
        height: 0.563rem;
        width: 0.563rem;
        border-radius: 50%;
        display: inline-block;
      }

      .red,
      .closed {
        background-color: $ittPortalRedDark !important;
        border: none;
        height: 0.563rem;
        width: 0.563rem;
        border-radius: 50%;
        display: inline-block;
      }

      &-title {
        font-weight: bold;
        margin-bottom: 0.25rem;
      }

      &-content {
        display: flex;
        gap: 0.25rem;
        align-items: center;

        .high,
        .critical {
          color: $ittPortalRedDark;
        }

        .medium,
        .major {
          color: $ittOrange;
        }

        .low,
        .minor {
          color: $ittPortalGreen;
        }

        &-profile-img {
          width: 0.563rem;
          height: 0.563rem;
        }

        &-chevron-down {
          height: $arrow-down-width;
          width: $arrow-down-width;
          @include arrow($ittPortalBlack, 0.1rem);
          transform: rotate(45deg);
        }
      }
    }

    .analysis-cards-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $arrow-down-width;

      &-due-date {
        display: flex;
        align-items: center;
        font-weight: bold;
        cursor: pointer;

        svg {
          margin-left: 0.4rem;
          font-size: 0.375rem;
        }
      }

      .green {
        color: $ittPortalGreen;
      }

      .red {
        color: $ittPortalRedDark;
      }

      .orange {
        color: $ittOrange;
      }
    }

    .analysis-cards-quarterly-report,
    .analysis-cards-card-status {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.2rem;
      padding: 0.25rem 0.4rem 0.25rem 0.275rem;
      border-radius: 0.25rem;

      &-value {
        display: flex;
        align-items: center;
        gap: 0.2rem;
      }

      .green-circle,
      .active-circle,
      .open-circle {
        background-color: $ittPortalGreen !important;
        border: none;
        height: 0.375rem;
        width: 0.375rem;
        border-radius: 50%;
      }

      .red-circle,
      .closed-circle {
        background-color: $ittPortalRedDark !important;
        border: none;
        height: 0.375rem;
        width: 0.375rem;
        border-radius: 50%;
      }

      .archived-circle {
        background-color: $ittPortalGrey6 !important;
        border: none;
        height: 0.375rem;
        width: 0.375rem;
        border-radius: 50%;
      }

      &-chevron-down {
        color: $ittPortalBlack;
        font-size: 0.375rem;
      }

      &-green-border,
      &-red-border {
        @include roundedcornersmixin(0.25rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        padding: 0.25rem 0.4rem 0.25rem 0.4rem;
        cursor: pointer;
      }
      &-green-border {
        background-color: $ittPortalGreenLight2;
        border: 1px solid $ittPortalGreenSuccess;
        .green-circle {
          background-color: $ittPortalGreen !important;
          border: none;
          height: 0.375rem;
          width: 0.375rem;
          border-radius: 50%;
        }
      }

      &-red-border {
        background-color: $ittPortalRedLight2;
        border: 1px solid $ittPortalRedDark;
        .red-circle {
          background-color: $ittPortalRedDark !important;
          border: none;
          height: 0.375rem;
          width: 0.375rem;
          border-radius: 50%;
        }
      }
    }

    .analysis-card-menu-button-published {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.25rem 0.4rem 0.25rem 0.275rem;
      background-color: #f7f7f7;
      @include roundedcornersmixin(0.25rem);
      cursor: pointer;

      &-content-img {
        width: 0.875rem;
        height: 0.875rem;
      }

      &-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.2rem;
        align-items: center;
        font-size: 0.625rem;

        &-chevron-down {
          height: $arrow-down-width;
          width: $arrow-down-width;
          @include arrow($ittPortalBlack, 0.1rem);
          transform: rotate(45deg);
        }
      }
      &-chevron-down {
        height: $arrow-down-width;
        width: $arrow-down-width;
        @include arrow($ittPortalBlack, 0.1rem);
        transform: rotate(45deg);
      }

      .green {
        @include boolet($ittPortalGreen, $size: 0.375rem);
      }

      .red {
        @include boolet($ittPortalRedDark, $size: 0.375rem);
      }

      &.green {
        border: 1px solid $ittPortalGreen;
        background-color: #eefcf7 !important;
      }

      &.red {
        border: 1px solid $ittPortalRedDark;
        background-color: #fceeee !important;
      }
    }

    .analysis-cards-card-status.open {
      background-color: $ittPortalGreenLight2;
      border: 1px solid $ittPortalGreenSuccess;
    }

    .analysis-cards-published-green-border {
      background-color: $ittPortalGreenLight2;
      border: 1px solid $ittPortalGreenSuccess;
    }
    .analysis-cards-published-red-border,
    .closed {
      background-color: $ittPortalRedLight2 !important;
      border: 1px solid $ittPortalRedDark;
    }

    .archived {
      background-color: $ittPortalGrey !important;
      border: 1px solid $ittPortalGrey6;
    }

    .analysis-cards-more-actions-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
      width: 3.813rem;
      border-radius: 0.25rem;
      border: solid 1px $ittPortalGreyDF;
      background-color: $ittPortalWhite;
      font-size: 0.625rem;
      padding: 0.5rem 0 0.5rem 0;

      svg {
        height: 0.875rem;
        width: 0.875rem;
        color: $ittPortalBlack;
      }
    }
  }

  .form .form-group__radio.hideRadioIcon {
    label {
      background-color: $ittPortalGreyF7 !important;
      border: 0;
      display: -webkit-box;
      &.selected {
        border: 1px solid $ittPortalGrey3 !important;
      }
      > span {
        display: block;
        position: relative;
        @include fontsize(1rem, normal, 700);
        text-transform: uppercase;
        margin: 0 auto;
        &:before {
          position: absolute;
          content: "";
          margin-left: -1rem;
          margin-top: 0.3rem;
        }
        span {
          text-transform: none;
          @include fontsize(0.75rem, normal, 400);
        }
      }
      &.green > span:before {
        @include boolet($ittPortalGreen, $size: 0.563rem);
      }
      &.red > span:before {
        @include boolet($ittPortalRedDark, $size: 0.563rem);
      }
      &.grey > span:before {
        @include boolet($ittPortalGrey6, $size: 0.563rem);
      }
    }
  }

  #severity-form {
    label {
      > span {
        text-transform: none;
      }
    }
  }

  #analysis-cards-card-due-date-form {
    #dueDate-field-container {
      .arrow-down {
        right: 2rem;
      }
    }
  }
}

@keyframes bell-ringing {
  0% {
    @include transform(rotate(35deg));
  }
  12.5% {
    @include transform(rotate(-30deg));
  }
  25% {
    @include transform(rotate(25deg));
  }
  37.5% {
    @include transform(rotate(-20deg));
  }
  50% {
    @include transform(rotate(15deg));
  }
  62.5% {
    @include transform(rotate(-10deg));
  }
  75% {
    @include transform(rotate(5deg));
  }
  100% {
    @include transform(rotate(0deg));
  }
}

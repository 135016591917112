.grid-message-above {
  $padding-right: 2.25rem;
  margin: 1rem;
  @include roundedcornersmixin(0.25rem);
  padding: $msg-inline-padding 1.5rem $msg-inline-padding $padding-right;
  background: $ittBlueLighter;
  //text-align: center;
  color: $ittBlueLight;
  font-size: 0.875rem;
  @media (max-width: $large-break-point) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .svg-inline--fa,
  img {
    width: $msg-svg-size;
    height: $msg-svg-size;
    align-self: center;
    margin-right: 0.813rem;
    @include fontsize(1.5, normal, 900);
    vertical-align: middle;
  }
}
.grid {
  $extra-header-height-mobile: 0.7rem;
  $checkbox-width: 1rem;
  color: #444;
  display: grid;
  flex-wrap: wrap;
  overflow-x: auto;
  position: relative;
  margin: $grid-margin-top 0 0 0;
  grid-template-areas:
    "grid-above-table"
    "grid-data";

  &.grid__with-applied-filters {
    .grid-above-table {
      height: #{$min-grid-buttons-height + $min-grid-filters-applied-height};
      @media (max-width: $header-break-point) {
        height: #{$min-grid-buttons-height + $extra-header-height-mobile +
          $min-grid-filters-applied-height};
      }
    }
    .grid-data {
      height: calc(
        100vh - #{$header-height + $grid-margin-top + $min-grid-buttons-height +
          $min-grid-filters-applied-height}
      );
      @media (max-width: $header-break-point) {
        height: calc(
          100vh - #{$header-height-mobile + $grid-margin-top +
            $min-grid-buttons-height + $extra-header-height-mobile +
            $min-grid-filters-applied-height}
        );
      }
    }
    &.grid__with-msg {
      .grid-data {
        height: calc(
          100vh - #{$header-height + $grid-margin-top + $min-grid-buttons-height +
            $min-grid-filters-applied-height + 3 * $msg-inline-padding +
            $msg-svg-size}
        );
        @media (max-width: $header-break-point) {
          height: calc(
            100vh - #{$header-height-mobile + $grid-margin-top +
              $min-grid-buttons-height + $extra-header-height-mobile +
              $min-grid-filters-applied-height + 2.9 * $msg-inline-padding +
              $msg-svg-size}
          );
        }
      }
    }
  }

  &.grid__with-msg {
    .grid-data {
      height: calc(
        100vh - #{$header-height + $grid-margin-top + $min-grid-buttons-height +
          $min-grid-filters-applied-height + $msg-inline-padding + $msg-svg-size}
      );
      @media (max-width: $header-break-point) {
        height: calc(
          100vh - #{$header-height-mobile + $grid-margin-top +
            $min-grid-buttons-height + $extra-header-height-mobile +
            $min-grid-filters-applied-height + $msg-svg-size * 1.2}
        );
      }
    }
  }

  #grid-observer {
    width: 100%;
    height: 10rem;
    &:empty {
      height: 0;
    }
    .splash {
      height: calc(100% - 1rem);
      .fa-circle-notch {
        margin-top: 1rem;
      }
    }
  }

  input[type="checkbox"] {
    + .checkmark {
      height: $checkbox-width !important;
      width: $checkbox-width !important;
    }
  }

  a {
    color: $ittBlueLight;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  :where(.grid-header-row, .grid-data-row, .grid-buttons-container, .grid-applied-filters-container) {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .search-expanding-input {
    $icons-width: 1rem;
    position: relative;
    width: fit-content;
    height: $icons-width;
    display: flex;
    min-width: 3rem;

    &:before {
      cursor: pointer;
      top: 0.4rem;
    }

    input {
      cursor: pointer;
      font-weight: bold;
      font-size: 1rem;
      &:not(:placeholder-shown),
      &:focus {
        cursor: text;
        margin: 0 1rem 0 0;
        width: 11rem;
        & + label {
          opacity: 1;
          top: -0.2rem;
          font-size: 0.75rem;
          color: $ittPortalGrey9B;
          padding-left: #{2 * $icons-width};
        }
      }

      & + label {
        width: 0;
        padding: 0;
        opacity: 0;
        overflow-wrap: normal;
      }

      &::placeholder {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }

  .grid-popup {
    position: absolute;
    z-index: 3;
    padding: 1.5rem 1rem 0.5rem 1rem;
    height: fit-content;
    width: fit-content;
    min-width: 11rem;
    top: -1rem;
    right: 100%;
    background-color: #ffffff;
    border: 1px solid $ittPortalGreyDF;
    @include roundedcornersmixin(0.25rem);
    @include box-shadow(0 0.125px 0.5px 0 rgba(0, 0, 0, 0.08));

    &.opposite {
      left: 100%;
      right: auto;

      &:after {
        left: calc(-0.5rem + -1px);
        right: auto;
        @include transform(rotate(135deg));
      }
    }
    &.top {
      left: auto;
      right: auto;
      top: 3.1rem;

      &:after {
        left: auto;
        right: auto;
        margin: auto;
        top: calc(-0.5rem + -1px);
        @include transform(rotate(225deg));
      }
    }
    &.sensor-status-popup {
      min-width: 60vw;
      padding-bottom: 1.5rem;
      &.ia3 {
        min-width: 80vw;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 1rem;
      right: calc(-0.5rem + -1px);
      height: 1rem;
      width: 1rem;
      border: solid $ittPortalGreyDF;
      border-width: 0 1px 1px 0;
      display: inline-block;
      @include transform(rotate(-45deg));
      background-color: #fff;
      margin: auto;
    }
    .form-group {
      @include fontsize(0.875rem, normal, 700);

      &--checkbox {
        margin: 0;
        padding-top: 0;
        padding-bottom: 1rem;
        border-bottom: none;
      }
      &--radio,
      &--checkbox {
        .form-field {
          label {
            padding: 1rem 0 1rem 1.8rem !important;
            border-bottom: 1px solid $ittPortalGreyF7;
            color: $ittPortalGrey3;
            @include fontsize(0.75rem, normal, 400);
            &:before {
              top: 0;
              bottom: 0;
              margin: auto;
            }
            svg {
              margin-right: 0.5rem;
              font-size: 0.625rem;
              color: #000;
            }
            input[type="checkbox"] + .checkmark,
            input[type="radio"]:checked:after {
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
  .grid-above-table {
    $results-width: 7.5rem;
    $results-width-mobile: 4rem;
    grid-area: grid-above-table;
    display: grid;
    grid-template-columns: calc(100% - #{$results-width}) $results-width;
    width: 100%;
    align-items: center;
    height: #{$min-grid-buttons-height};
    @media (max-width: $header-break-point) {
      height: #{$min-grid-buttons-height + $extra-header-height-mobile};
    }
    @media (max-width: $header-break-point) {
      grid-template-columns: calc(100% - #{$results-width-mobile}) $results-width-mobile;
    }
  }
  .grid-data-found {
    color: $ittPortalGrey9B;
    @include fontsize(0.625rem, normal, 400);
    align-self: start;
    padding-top: 0.6rem;
  }
  .grid-filters-container .slide-over-container {
    max-width: 400px;
    min-width: 30%;
    .form {
      .form-fields-container {
        min-height: calc(100vh - 9rem);
      }
    }
    .slide-over-footer {
      position: sticky;
      bottom: 0;
      z-index: 3;
      padding-block: 0.5rem;
    }
  }
  .grid-buttons-applied-filters-container {
    margin: 0 1rem 1rem 1rem;
    .grid-buttons-container {
      .grid-button-container,
      button {
        + button,
        + .grid-button-container {
          margin-left: 1rem;
        }
      }
      .grid-button-container {
        display: inline-block;
        position: relative;
      }
      button {
        $button-font-size: 0.625rem;
        border: 1px solid $ittPortalGreyDF;
        padding: 0.75rem 0.5rem;
        background-color: transparent;
        color: $ittPortalGrey3;
        @include fontsize($button-font-size, normal, 700);
        @include roundedcornersmixin(0.25rem);

        &.grid-filters,
        &.grid-manage-columns {
          border: 1px solid $ittPortalGrey3 !important;
          color: $ittPortalGrey3 !important;
          @include fontsize($button-font-size !important, normal, 700);
          z-index: 3;
          svg {
            font-size: $button-font-size !important;
            color: $ittPortalGrey3 !important;
          }
        }
        &.grid-manage-columns {
          svg {
            color: initial;
          }
        }

        svg {
          margin-right: 0.3125rem;
        }
        .applied-filters-number {
          margin-left: 0.25rem;
          padding: 0.275rem 0.375rem;
          color: #fff;
          background-color: $ittPortalGrey3;
          @include roundedcornersmixin(0.25rem);
        }
      }
    }
    .grid-buttons-align-right {
      margin-left: auto;
      .btn {
        margin-left: 1rem;
      }
    }
    .grid-applied-filters-container {
      margin-top: 1rem;
      align-items: center;
      .grid-applied-filters {
        @include fontsize(0.625rem, normal, 700);
        margin-right: 0.25rem;
      }
      .grid-filter-remove {
        padding: 0.5rem;
        color: $ittPortalGrey3;
        background-color: $ittPortalGreyF7;
        @include fontsize(0.625rem, normal, 700);
        @include roundedcornersmixin(0.25rem);

        + .grid-filter-remove {
          margin-left: 0.5rem;
        }

        .grid-filter-remove-btn {
          margin: 0 0 0 0.25rem;
          padding: 0;
          border: 0;
          background-color: transparent;
          color: $ittPortalGrey3;
          cursor: pointer;
          width: auto;
          span {
            @include fontsize(0.83rem, normal, 400);
          }
        }
      }
    }
  }
  .grid-data {
    $column-padding: 0.75rem;
    grid-area: grid-data;
    width: 100%;
    overflow-x: auto;
    height: calc(
      100vh - #{$header-height + $grid-margin-top + $min-grid-buttons-height}
    );
    @media (max-width: $header-break-point) {
      height: calc(
        100vh - #{$header-height-mobile + $grid-margin-top +
          $min-grid-buttons-height + $extra-header-height-mobile}
      );
    }
    .grid-header-row,
    .grid-data-row {
      min-height: 3.6875rem;
      align-items: stretch;
      @include fontsize(0.625rem, normal, 400);
      .column {
        display: flex;
        overflow-wrap: anywhere;
        border-bottom: 1px solid $ittPortalGreyF7;
        align-items: center;

        &-icon {
          text-align: center;
          cursor: pointer;
          margin-left: #{-1 * $column-padding};
        }
        &.select {
          width: 5%;
          min-width: 3rem;
          max-width: 3.5rem;
          text-align: center;
          .column-content {
            padding-inline: 0;
          }
        }
        + .column {
          border-left: 1px solid $ittPortalGreyF7;
        }
        .column-content {
          align-self: center;
          width: 100%;
          padding-inline: $column-padding;
          position: relative;

          .column-full-width {
            width: 100%;
          }
        }
        svg {
          font-size: 1rem;
          color: $ittPortalGrey9B;
          &.sensor-status-see-more-icon {
            margin-right: 0.25rem;
            color: $ittBlueLight;
            font-size: 0.75rem;
          }
        }
        .element-tooltip {
          svg {
            &.fa-circle-info {
              color: $ittPortalGrey3;
              font-size: 0.825rem;
            }
          }
        }

        &.no-padding {
          .column-content {
            padding: 0;
            align-self: stretch;
          }
        }
      }
    }
    .grid-header-row {
      z-index: 3;
      min-height: 2.625rem;
      background-color: #fff;
      position: sticky;
      top: 0;
      font-weight: 700;
      .column {
        background-color: #fff;
        border-bottom: 1px solid $ittPortalGrey3;
        .grid-header-sort-buttons-container {
          position: relative;
          display: flex;
          flex-direction: column;
          margin: 0 0.5rem 0 auto;

          .grid-sort-button {
            padding: 0;
            border: 0;
            line-height: 0.4rem;
            margin-top: 0;
            background-color: transparent;

            svg {
              color: $ittPortalBlack;
              font-size: 0.625rem;
            }
          }
          .hidden {
            display: none;
          }

          .i-alert-popup {
            $popup-margin: -14rem;
            padding: 0.5rem;
            top: 2.1rem;
            right: $popup-margin;
            min-width: calc(#{-1 * $popup-margin +2});

            &:after {
              top: -0.4rem;
              left: $popup-margin + 1;
              border-width: 1px 1px 0 0;
              height: 0.8rem;
              width: 0.8rem;
            }

            .grid-sort-popup-direction {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            h3 {
              font-size: 0.875rem;
              margin-bottom: 0.625rem;
            }

            label {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1rem;
              padding-left: 1rem;
              color: $ittPortalGrey9B;
              margin-bottom: 0.3rem;

              input {
                margin-bottom: 0.188rem;
                appearance: none;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                border: 1px solid $ittPortalGreyDF;
                margin-right: 0.5rem;
                position: relative;
                cursor: pointer;
                @include transition(
                  "background-color 0.2s ease, border-color 0.2s ease"
                );

                &:checked {
                  &::before {
                    content: "";
                    position: absolute;
                    border-radius: 50%;
                    background-color: $ittPortalGreen;
                    width: 0.8325rem;
                    height: 0.8325rem;
                    top: 0.1rem;
                    left: 0.1rem;
                  }
                }

                &::before {
                  content: "";
                  position: absolute;
                  width: 0.8325rem;
                  height: 0.8325rem;
                  top: 0.1rem;
                  left: 0.1rem;
                  border-radius: 50%;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }

      .element-with-tooltip {
        display: flex;
        justify-content: start;
        align-items: center;
      }
    }
    .grid-data-row {
      .alert-level {
        $alert-level-margin: 0.25rem;
        $alert-level-image-width: 0.625rem;
        padding: $alert-level-margin;
        svg {
          font-size: $alert-level-image-width;
        }
        img {
          width: $alert-level-image-width;
          margin: 0 0.25rem;
        }
        .alert-level-status {
          cursor: pointer;

          &.relative {
            position: relative;
          }
        }
        & > div {
          @include fontsize($alert-level-image-width, normal, 400);
          margin-bottom: $alert-level-margin;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .status-container {
        $status-icons-width: 0.625rem;
        $status-icons-margin: 0.25rem;
        grid-area: sensor-status;

        .svg-inline--fa {
          margin-right: $status-icons-margin;
          @include fontsize($status-icons-width, normal, 900);
        }
        .selection-box,
        .selection-box:not(.no-edit) {
          padding: 0.25rem;
          width: 100%;
          display: flex;
          align-items: center;
        }

        .status-container-desc {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: calc(100% - 1rem);
        }
        &.route-status-container {
          margin-right: $column-padding;
        }
      }
      .action-btn {
        width: 100%;
        svg {
          color: #fff;
          margin-right: 0.375rem;
          @include fontsize(0.5rem, normal, 900);
        }
        &.assettrain-add-comment {
          width: 2.063rem;
          padding-bottom: 0.395rem;
          margin-left: 0.625rem;

          img {
            height: 0.881rem;
          }
        }
      }
      .selection-box {
        display: inline-block;
        min-width: 3rem;
        background-color: $ittPortalGreyF7;
        @include roundedcornersmixin(0.25rem);
        padding: 0.25rem;

        &.blue {
          background-color: $ittBlueLighter;
        }
        &.pink,
        &.alarm {
          background-color: $ittPortalPink;
        }
        &.warning {
          background-color: $ittPortalYellowWarning;
        }
        &:not(.no-edit) {
          padding: 0.25rem 1rem 0.25rem 0.25rem;
          cursor: pointer;
          .selection-box-value {
            &:after {
              content: "";
              @include arrow($ittPortalGrey9B, 0.125rem);
              @include vertical-align(absolute);
              @include transform(translateY(-50%) rotate(45deg));
              right: -0.75rem;
              height: 0.375rem;
              width: 0.375rem;
              z-index: 1;
              transition: all 0.2s;
            }
          }
        }

        .selection-box-value {
          position: relative;
          text-align: center;
        }
        &.notifications-preference-box {
          .svg-inline--fa {
            color: $ittPortalGrey3;
            @include fontsize(0.75rem, normal, 900);
            & + .svg-inline--fa {
              margin-left: 0.25rem;
            }
          }
        }
        &.watching-priority-box {
          &:not(.asset-train-priority) {
            &.medium {
              background-color: $ittBlueLighter;
            }

            &.high {
              background-color: $ittPortalPink;
            }
          }
          &.asset-train-priority {
            background-color: transparent;
            min-width: 5rem;
            text-align: center;
            position: relative;
            .priorityTitle {
              padding-left: 0.3rem;
            }
            .svg-inline--fa {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
            }
          }
          .svg-inline--fa {
            @include fontsize(0.625rem, normal, 900);
            & + .svg-inline--fa {
              margin-left: 0.25rem;
            }
          }
        }
        &.activation-box {
          .selection-box-value {
            padding-left: 0.625rem;
            &:before {
              $bullet-width: 0.375rem;
              content: "";
              @include vertical-align(absolute);
              left: 0;
              width: $bullet-width;
              height: $bullet-width;
              @include roundedcornersmixin($bullet-width);
            }
            &.active {
              &:before {
                background-color: $ittPortalGreenSuccess;
              }
            }
            &.inactive {
              &:before {
                background-color: $ittPortalYellow;
              }
            }
            &.removed {
              &:before {
                background-color: $ittPortalRedDark;
              }
            }
          }
        }
      }

      .sensor-gateway-rssi-bars-container {
        .rssi-icon {
          height: 2rem;
        }
      }
    }
  }
  .grid-data-empty {
    text-align: center;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $ittPortalGrey9B;
    @include fontsize(1.5rem, normal, 700);
  }

  .i-alert-popup {
    z-index: 2;

    &.top {
      z-index: 3;
    }

    &.opposite {
      margin-top: 0.7rem;
    }
  }

  #sensorActivationForm {
    .form-fields-container {
      min-height: fit-content;
    }
  }
}

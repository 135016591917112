#scheduled-report-page {
  .slide-over-container .export-reports,
  .export-reports {
    padding: 0 1rem 0 1rem !important;
    .file-type {
      display: inline-block;
      margin-left: 1rem;
      text-transform: uppercase;
      @include fontsize(0.75rem, normal, 400);
      .fa {
        font-size: 0.875rem;
        margin-right: 0.25rem;
      }
    }
    .form-group__export .form-button-container,
    .form-button-container {
      width: 100%;
      padding: 0 0 0.5rem 0;
      z-index: 3;
      max-width: 100%;
      position: sticky;

      .export-type-container {
        padding-top: 0.875rem;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;

        .export-type-labels {
          justify-self: start;
          @include fontsize(0.875rem, normal, 700);
        }
        .export-type-choices {
          justify-self: end;

          label {
            position: relative;
            .export-type-choice-text {
              display: inline-block;
              margin: 0 1rem 0 2.2rem;
              text-transform: uppercase;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
      .form-group {
        max-width: none;
        padding: 0;
      }
    }
    .form-fields-container {
      //padding: 2.5rem 1rem 0 1rem;
      min-height: calc(100vh - 10.8rem);

      .checkbox-list {
        max-height: calc(100vh - 18.75rem);
        overflow-y: auto;
        .checkbox-label {
          margin-bottom: 0 !important;
          cursor: pointer;
          > span {
            text-transform: capitalize;
          }
          .subtitle {
            color: $ittPortalGrey9B;
            display: block;
            margin-top: 0.25rem;
            margin-bottom: 0;
            text-align: left;
            @include fontsize(0.75rem, normal, 400);
          }

          input[type="checkbox"] + .checkmark {
            margin: auto !important;
            bottom: 0 !important;
          }
        }
      }
    }
  }
}

.form-tabs {
  $form-tabs-header-height: 4.8125rem;
  display: grid;
  grid-gap: 0;
  grid-template-areas:
    "form-tabs-header"
    "form-tabs-content";
  grid-template-rows: $form-tabs-header-height auto;
  grid-template-columns: 100%;

  &__header {
    grid-area: form-tabs-header;
    display: grid;
    grid-auto-columns: max-content;
    grid-template-columns: max-content auto;
    grid-column-gap: 3.875rem;
    grid-template-rows: 100%;
    align-items: center;
    padding-left: 1.5rem;
    overflow-x: auto;
    width: 100%;
    @include box-shadow(0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.08));

    h1 {
      margin: 0;
      @include fontsize(1.5rem, normal, 700);
    }
  }
  &__content {
    grid-area: form-tabs-content;
    position: relative;
    overflow-x: clip;

    .tab {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 100%;
      top: 0;
      padding: 0 0 0 1.5rem;
      max-height: calc(100vh - 10rem);
      overflow-y: auto;

      &.active {
        position: relative;
        left: 0;
        transition: left 0.5s;
      }

      .splash {
        height: 100%;
      }
    }
  }
}

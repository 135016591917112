.slick-dots {
  position: absolute;
  bottom: 45%;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.3125rem;
    padding: 0;
    cursor: pointer;

    .slick-active button:before {
      opacity: 0.75;
      color: black;
    }

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      padding: 0.3125rem;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:hover,
      :focus {
        outline: none;
      }

      &:hover:before,
      :focus:before {
        opacity: 1;
      }

      &:before {
        font-family: "slick";
        font-size: 3.75rem;
        line-height: 1.25rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
        content: "•";
        text-align: center;
        opacity: 0.1;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media (max-width: $tablet-break-point) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.slider-slick-container {
  background-color: white;
  text-align: center;
  margin: 1.8125rem;
  height: 65%;
}

.slick-slider {
  height: 100%;

  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;

      .slick-slide {
        > div {
          height: 100%;
        }
      }
    }
  }

  .slider-slick-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    height: 100%;

    @media (max-width: $tablet-break-point) {
      font-size: 0.625rem;
    }

    .image {
      height: 45%;
      margin-top: 2rem;

      @media (max-width: $tablet-break-point) {
        margin-top: 0;
      }
    }

    .details {
      height: 45%;
      padding: 2rem;
      @media (max-width: $tablet-break-point) {
        padding: 1rem;
      }

      h2 {
        margin: 0;
        @include fontsize(1.5rem, normal, 700);
      }

      p {
        font-size: 0.875rem;
        margin-top: 0.25rem;
      }
    }
  }
}

@keyframes backToTopActive {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
#back-to-top {
  @include transition(all 0.3s ease 0s);
  position: fixed;
  bottom: 55px;
  right: 20px;
  border: none;
  outline: none;
  z-index: 0;
  background-color: $btnPrimaryBg;
  color: white;
  cursor: pointer;
  padding: 15px 19px;
  border-radius: 30px;
  font-size: 18px;
  opacity: 0;
  .fa {
    @include transition(all 0.3s ease 0s);
  }

  &.active {
    display: block;
    z-index: $loader-z-index;
    @include animation(backToTopActive 0.5s forwards);
    .fa {
      @include transition(all 0.3s ease 0s);
      opacity: 0.5;
    }
    &:hover {
      opacity: 1 !important;
      .fa {
        opacity: 1 !important;
      }
    }
  }
}

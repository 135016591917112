#notifications-manage-columns-container {
  padding-left: 0;
  padding-right: 0;
}
#notifications-slideover-container {
  max-width: 650px;
}

#notificationsContent {
  $notifications-header-height-upper: 10.0625rem;
  $notifications-header-height-lower: 6rem;
  #notificationsGrid {
    $checkbox-width: 1rem;
    $checkbox-padding-top: 0.625rem;
    $checkbox-padding-left: 1.125rem;
    width: 100%;
    overflow: hidden;
    margin-top: 0.5rem;

    .grid-buttons-applied-filters-container {
      margin-left: 0;
      .grid-buttons-container button + button {
        margin-left: 0.5rem;
      }
    }

    #notificationsGrid-filters-container {
      @media (max-width: $tablet-break-point) {
        min-width: 100%;
      }
    }

    #notificationsGrid-header {
      min-height: #{2 * $checkbox-padding-top + $checkbox-width};
      .column {
        border-left: 1px solid $ittPortalGreyDF;
        border-bottom: 1px solid $ittPortalGreyDF;
        border-top: 1px solid $ittPortalGreyDF;
        &:last-of-type {
          border-right: 1px solid $ittPortalGreyDF;
        }
        &:nth-child(1) {
          width: #{2 * $checkbox-padding-left + $checkbox-width};
        }
        &:nth-child(2) {
          .column-content {
            @include fontsize(0.875rem, normal, normal);
            color: $ittPortalGrey3;
          }
        }
        &:nth-child(3) {
          width: #{2 * $checkbox-padding-left + $checkbox-width};
          border-left: 0;
        }
        .column-content {
          padding: $checkbox-padding-top $checkbox-padding-left;
        }
      }
    }
    .grid-above-table {
      @media (max-width: 380px) {
        grid-template-columns: calc(100% - 6.3rem) 6.3rem;
      }
    }
    .grid-data {
      height: calc(
        100vh - $notifications-header-height-upper -
          $notifications-header-height-lower
      );
      .grid-data-row {
        .column {
          border-bottom: 1px solid $ittPortalGreyDF;
          &:last-of-type {
            border-right: 1px solid $ittPortalGreyDF;
          }
          &:nth-child(1) {
            border-left: 1px solid $ittPortalGreyDF;
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: #{2 * $checkbox-padding-left + $checkbox-width};
          }
          &:nth-child(2) {
            border-left: 1px solid $ittPortalGreyDF;
            border-right: 1px solid $ittPortalGreyDF;
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-right: 1rem;
          }
          &:nth-child(3) {
            width: #{2 * $checkbox-padding-left + $checkbox-width};
          }
          .column-content {
            padding-left: $checkbox-padding-left;
            padding-right: $checkbox-padding-left;
          }
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      .svg-inline--fa {
        color: $ittPortalGrey3;
        width: 0.875rem;
        @include fontsize(0.75rem, normal, 900);
        cursor: pointer;
      }
      .unread-notification {
        color: $ittBlueLight;
      }
      .view-notifications-gap {
        //padding-bottom: 1.5rem; Removed comments to align it top when re-enabling delete btns on row level
      }
    }
  }

  .mainHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.125rem;
    .mainHeader {
      @include fontsize(1.5rem, normal, bold);
    }
    .settings {
      @include fontsize(0.875rem, normal, normal);
      a {
        text-decoration: none;
        color: $ittBlueLight;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .count {
    @include fontsize(0.875rem, normal, normal);
    color: $ittPortalGrey3;
  }

  .title {
    @include fontsize(0.875rem, normal, bold);
    color: $ittPortalGrey3;
    &:before {
      content: "";
      display: inline-block;
      @include roundedcornersmixin(0.3125rem);
    }
    a {
      color: $ittPortalGrey3;
    }
  }

  .status {
    &:before {
      margin-left: 0;
      margin-right: 0.3125rem;
      width: 0.625rem;
      height: 0.625rem;
    }
    &.statusAlarm {
      &:before {
        background-color: $ittPortalRedDark;
      }
    }
    &.statusWarning {
      &:before {
        background-color: $ittPortalYellow;
      }
    }
    &.statusNormal {
      &:before {
        background-color: $ittPortalGreenSuccess;
      }
    }
  }

  .message {
    @include fontsize(0.75rem, normal, normal);
    color: $ittPortalGrey3;
  }
  .date {
    @include fontsize(0.625rem, normal, normal);
    color: $ittPortalGrey3;
  }
  .buttonContainer {
    position: absolute;
    padding: 0 2rem;
    width: 100%;
    bottom: 0.8125rem;
    .closeButton {
      width: 100%;
    }
  }
}

.trends-chart-container-section {
  .trend-section-buttons {
    display: inline-block;
    width: fit-content;
    margin-top: 1.5rem;
    margin-right: 1em;
    margin-bottom: 0.5rem;

    .form-group {
      margin-bottom: 0;
    }

    .form-field {
      min-width: 14.5rem;
      @media (max-width: $mobile-break-point) {
        min-width: auto;
      }

      select:disabled {
        color: $ittPortalGrey3;
      }

      .arrow-down {
        @include transform(rotate(-45deg));
      }
    }

    .slide-over-container:not(#asset-train-page-fft-section-vibration--selector-slide-over-container) {
      $slide-over-width: 95%;
      $slide-over-header-height: 6rem;
      $slide-over-footer-height: 5.125rem;
      min-width: $slide-over-width;
      background-color: $ittPortalGreyF7;
      padding: 0;

      display: grid;
      column-gap: 0;
      row-gap: 0;
      grid-template-columns: 1fr;
      grid-template-rows:
        $slide-over-header-height calc(
          100% - #{$slide-over-header-height + $slide-over-footer-height}
        )
        $slide-over-footer-height;
      grid-template-areas:
        "slide-over-header"
        "slide-over-content"
        "slide-over-footer";

      .slide-over-content {
        grid-area: slide-over-content;
        overflow-y: auto;
        padding: 1rem;

        .sensor-status-popup {
          background-color: #fff;

          .sensor-value-container {
            width: 100%;
          }
        }
      }

      .slide-over-footer-time-period {
        display: grid;
        grid-column-gap: 0.8125rem;
        align-items: center;
        grid-template-columns: 1fr 1fr;
      }

      .slide-over-footer {
        $slide-over-footer-gap: 1.25rem;

        button {
          $slide-over-footer-btn-width: 18.625rem;
          width: $slide-over-footer-btn-width;
          @media (max-width: #{2*$slide-over-footer-btn-width + $slide-over-footer-gap}) {
            width: auto;
            padding: 1rem 2rem;
          }
        }
      }

      .slide-over-header {
        grid-area: slide-over-header;
        top: 0;
        padding-left: 2rem;
        padding-right: 2rem;
        background-color: #fff;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        button {
          margin: 0;
          padding: 0;
          background-color: transparent;
          color: $ittBlueLight;
          @include fontsize(0.875rem, normal, 400);
          text-align: right;
        }

        h2 {
          margin: 0;
          @include fontsize(1.5rem, normal, 700);
        }
      }
    }
  }

  .highcharts-axis.highcharts-yaxis {
    .highcharts-axis-title {
      min-width: 4.625rem;
      text-align: center;
    }
    .highcharts-axis-title.fade-out {
      opacity: 0.1;
    }
  }

  #trend-notes-bar-label {
    background-image: url("../../../assets/images/icon-edit.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    padding-left: 1rem;
  }

  .trends-chart-container,
  .fft-chart-container,
  .twf-chart-container {
    height: 35rem;
    width: 100%;
  }

  .trends-chart-container {
    .highcharts-legend-item {
      tspan {
        font-size: 0.6em;
      }
    }
    .highcharts-markers {
      image.highcharts-point:hover {
        filter: invert(100%);
        width: 2.438rem;
        height: 1.813rem;
      }
    }
  }

  #asset-train-page-trends-section-chart-buttons-container {
    margin: 0.5rem 0 0 0;
  }

  .asset-train-section-content {
    padding: 1rem 2rem;

    @media (max-width: $tablet-break-point) {
      padding: 1rem 1rem;
    }

    @media (max-width: $mobile-break-point) {
      padding: 1rem 0.5rem;
    }

    h2 {
      margin-left: 0;
    }
  }

  #asset-train-page-trend-selected-period {
    #asset-train-period-selector-slide-over {
      .slide-over-container {
        background-color: #ffffff;
        min-width: 15%;
        width: 24.938rem;
        padding-inline: 0.563rem;
        grid-template-rows: unset;

        h2 {
          margin-bottom: 1.5rem;
        }

        #asset-train-trend-period-form-title {
          margin-top: 2.5rem;
        }

        .radio-label {
          display: none;
        }

        .form-fields-container {
          min-height: calc(100vh - 10.65rem);
        }

        .form-button-container {
          padding: 0 0.875rem 0.5rem 0;
        }
      }
    }
  }

  .trend-tooltip-date {
    font-size: 0.625rem;
    border-bottom: 0.015rem solid #f7f7f7;
    padding-bottom: 0.25rem;
  }

  .trend-tooltip-list {
    margin-top: 0.5rem;
    border-bottom: 0.015rem solid #f7f7f7;
    padding-bottom: 0.25rem;
    font-size: 0.75rem;

    .trend-tooltip {
      margin-top: 0.25rem;
      font-size: 0.625rem;

      .orientation-color {
        $orientation-color-size: 0.625rem;
        width: $orientation-color-size;
        height: $orientation-color-size;
        @include roundedcornersmixin($orientation-color-size);
        margin-right: 0.25rem;
        display: inline-block;
      }
    }
  }
}

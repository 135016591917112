$flags-list-width: $header-height-upper-part;
$flags-list-arrow: 0.625rem;
$flags-icons-width: 1.875rem;

.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  @extend .flag-icon-background;
  position: relative;
  display: inline-block;
  width: auto;
  padding-right: 0.8rem;
  font-size: 0.875rem;
  color: $ittPortalGrey3;
  font-weight: 700;
  &.active {
    &:after {
      @include transform(rotate(-135deg));
    }
  }
  &:after {
    content: "";
    @include arrow($ittPortalGrey3, 2px);
    @include transform(rotate(45deg));
    position: absolute;
    right: 0;
    top: 0.2rem;
    height: 0.5rem;
    width: 0.5rem;
    z-index: 1;
    transition: all 0.2s;
  }

  &.flag-icon-squared {
    width: 1em;
  }
}
#unasigned-header-right .flags #flags-list-container {
  left: 0 !important;
}
.flags {
  padding: 1rem 0 0 0;
  cursor: pointer;
  color: $ittPortalGrey;
  position: relative;
  text-transform: uppercase;
  font-size: 0.85rem;
  #unasigned-header-right & {
    color: $ittPortalGrey6;
    margin-right: 0.5em;
  }
  #flags-list-container {
    position: absolute;
    top: calc($flags-list-width / 2);
    width: $flags-list-width;
    left: calc(-1 * $flags-list-width / 2 + 20px);
    overflow: hidden;
    z-index: 2;
    padding-top: $flags-list-arrow;
    @include transition(height 0.25s ease-in);

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: $flags-list-arrow;
      width: 0;
      height: 0;
      border-left: none;
      border-right: none;
      border-bottom: none;
      clear: both;
      z-index: 2;
      @include transition(all 0.25s ease-in);
    }
    &.active {
      &:after {
        border-left: $flags-list-arrow solid transparent;
        border-right: $flags-list-arrow solid transparent;
        border-bottom: $flags-list-arrow solid $ittPortalBlueDark;
        top: 1px !important;
      }
      /*ul#flags-list{
				max-height: 130px !important;
			}
			.flags-ul-container{
				border: 1px solid rgba(170, 170, 170, 0.4)!important;
			}*/
    }
  }
  .flags-ul-container {
    border: none;
  }
  .header .title {
    text-transform: capitalize;
  }
  ul#flags-list {
    /*max-height: 0;*/
    position: relative;
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: $ittPortalGrey3;
    width: 100%;
    list-style: none;
    z-index: 3;
    overflow-y: hidden;
    @include transition(all 0.5s ease-in);

    li {
      border-bottom: 1px solid rgba(170, 170, 170, 0.4);
      /*font-size: 0.70em;
			padding: 0.3125rem;*/
      font-size: 1.2em;
      padding: 0.5rem 0;
      width: 100%;
      flex-direction: row;
      text-transform: uppercase;
      display: block !important;
      text-align: left;
      margin-bottom: 1px;
      /*&:last-of-type{
				border-bottom: none;
			}*/
      &:hover,
      &.active {
        /*color: #FFF;
				background-color: $ittPortalGrey3;*/
        font-weight: 700;
        border-bottom: 2px solid rgba(0, 0, 0, 1);
        margin-bottom: 0;
      }
      span {
        display: inline-block;
        width: $flags-icons-width;
        margin-right: 0.3125rem;
      }
    }
  }
}
#unasigned-header-right {
  .flags {
    #flags-list-container {
      &:after {
        top: -10px !important;
      }
      &.active {
        &:after {
          top: 0 !important;
        }
        .flags-ul-container {
          border: none !important;
        }
        ul#flags-list {
          border: 1px solid $ittPortalGrey3 !important;
        }
      }
      ul#flags-list {
        background-color: #e6e6e6;
      }
    }
  }
}

@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg);
  }
}

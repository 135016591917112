.chart-selected-sensors {
  $gap-between-border: 0.5rem;
  display: flex;
  max-width: 100%;
  gap: 0.8rem;
  flex-wrap: wrap;

  .chart-selected-sensor {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    padding: 0.25rem 1rem 0.25rem 1.5rem;
    border: 1px solid $ittPortalGreyDF;
    @include roundedcornersmixin(0.5rem);
    @include fontsize(0.625rem, normal, 700);
    align-items: center;

    &-name {
      padding-right: 0.825rem;
    }

    &-remove {
      $padding-width: 0.25rem;
      $padding-height: 0.625rem;

      background-color: $ittPortalGreyF7;
      @include roundedcornersmixin(0.25rem);
      margin-right: #{3 * $padding-width};
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      margin-top: $gap-between-border;
      margin-bottom: $gap-between-border;

      &:last-of-type {
        margin-right: 0;
      }

      .orientation-text {
        padding: $padding-height $padding-width;
        display: inline-block;
      }

      .form-group__checkbox {
        $checkbox-height: 1rem;
        $checkbox-container-width: $checkbox-height + 3 * $padding-width;
        margin: 0;
        padding: 0 #{2 * $padding-width} 0 $padding-width;
        height: $checkbox-height;
        width: $checkbox-container-width;

        .form-field {
          min-height: $checkbox-height;

          label.checkbox-label {
            padding-left: 0;
          }

          .checkmark {
            height: $checkbox-height;
            width: $checkbox-height;
          }
        }
      }

      button {
        border: 0;
        margin: 0;
        padding: $padding-width;
        height: 100%;
        border-right: 1px solid #fff;
        background-color: transparent;
        cursor: pointer;

        svg {
          font-size: 0.75rem;
        }
      }
    }
  }

  .add-sensor {
    cursor: pointer;
    @include fontsize(0.625rem, 0.625rem, 700);
    padding: $gap-between-border 1.5rem $gap-between-border $gap-between-border;
    width: auto;
    background-color: $ittBlueLight;
    color: #fff;
    position: relative;
    margin: 1rem 0;
    height: auto;

    svg {
      position: absolute;
      right: 0.5rem;
    }
  }
}

#account-page {
  #account-page-container {
    background-color: $ittPortalGreyF7;
    padding: 0;
    height: 100%;

    #accountContent {
      padding: 0;
      height: 100%;
      position: relative;

      form {
        overflow-y: auto;
        height: 100%;
      }

      .form-sections-container {
        background-color: #fff;
        margin: 7.625rem 2rem 2.625rem 2rem;
        position: relative;

        #accountImage-field-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-right: 0;
        }
        .form__error {
          width: max-content;
        }
      }

      .form-group .form-field {
        input[type="text"],
        textarea {
          &:disabled {
            background-color: rgba(255, 255, 255, 0.3);
            color: $ittPortalGrey9B;
          }
        }
      }

      section {
        .form-section-title {
          margin-bottom: 1rem;
          font-size: 1.125rem;
          line-height: 1.125rem;
        }
        .form-section-content {
          padding-top: 0;
        }
        .img-container {
          @media (max-width: $tablet-break-point) {
            margin-top: 1rem;
          }
          @media (max-width: $mobile-break-point) {
            margin-top: 0;
          }
        }
        &#form-section-activeSensorsSection {
          text-align: center;
          padding-bottom: 1.375rem;
          @include fontsize(1.125rem, normal, 400);
          .form-group {
            display: inline-block;
            width: auto;
            margin: 0;
            @media (min-width: #{$tablet-break-point + 1px}) {
              &:nth-of-type(3n + 1) {
                margin: 0;
              }
              &:nth-of-type(3n) {
                margin: 0;
              }
            }
            @media (max-width: $tablet-break-point) {
              width: auto;
              &:nth-of-type(2n + 1) {
                margin: 0;
              }
              &:nth-of-type(2n) {
                margin: 0;
              }
            }
            @media (max-width: $mobile-break-point) {
              width: 100%;
            }
          }
        }
        &#form-section-accountImageSection {
          @media (max-width: $mobile-break-point) {
            padding-bottom: 0;
          }
          .form-section-title {
            @include fontsize(1.5rem, 1.5rem, 700);
            margin: 0;
            padding-top: 2rem;
          }
        }
      }
      .btn-req-account {
        border: 0;
        background-color: $ittPortalGreenSuccess;
        color: $ittPortalGrey3;
        padding: 1rem;
        display: inline-block;
        margin: 0.25rem;
        cursor: pointer;
        text-decoration: none;
        @include roundedcornersmixin(0.25rem);
        @include fontsize(0.75rem, normal, 700);
      }
      #accountButtons {
        z-index: 6;
        position: relative;
        float: right;
        margin-top: 1rem;
        margin-right: 2rem;
      }
    }
  }
  .account-field-container {
    @include slideOverOneThirdFormColumn();
  }
  #form-section-accountPaymentSection {
    .form-section-fields {
      display: grid;
      column-gap: calc($slide-over-field-container);
      row-gap: calc($slide-over-field-container / 2);
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "paymentTo ittContactName ..."
        "paymentTo ittContactEmail ..."
        "paymentTo ittContactPhone ...";
      @media (max-width: $tablet-break-point) {
        grid-template-columns: 1fr 1fr 0;
        grid-template-areas:
          "paymentTo ittContactName ..."
          "paymentTo ittContactEmail ..."
          "paymentTo ittContactPhone ...";
      }
      @media (max-width: $mobile-break-point) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "paymentTo"
          "ittContactName"
          "ittContactEmail"
          "ittContactPhone";
      }

      .account-field-container {
        width: 100%;
        margin: 0;
      }
      #paymentTo-field-container {
        grid-area: paymentTo;
        margin-right: $slide-over-field-container;
        textarea {
          height: 100%;
        }
      }
      #ittContactName-field-container {
        grid-area: ittContactName;
      }

      #ittContactEmail-field-container {
        grid-area: ittContactEmail;
      }

      #ittContactPhone-field-container {
        grid-area: ittContactPhone;
      }
    }
  }
}

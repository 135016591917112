#header-search {
  padding-right: 1rem;
}
#header-search .form .form-group .form-field,
.form .form-group .search-field,
.form .form-group.search-field {
  $icons-width: 1rem;
  &:before {
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    font-weight: 900;
    font-size: 1rem;
    position: absolute;
    top: $icons-width;
    left: $icons-width;
    width: $icons-width;
    height: $icons-width;
    z-index: 4;
  }

  label {
    &:before {
      opacity: 0;
    }
  }

  &.multiple-reasons {
    &:before {
      content: "";
      width: 0;
      height: 0;
    }
  }

  input[type="text"] {
    padding-left: #{3 * $icons-width};
    &:-webkit-autofill + label,
    &:placeholder-shown + label {
      transform: translate(#{2 * $icons-width}, 1.2rem) scale(1);
      max-width: none;
      width: calc(100% - #{2 * $icons-width});
      font-size: 1rem;
    }
    &:not(:placeholder-shown) + label,
    &:focus + label {
      transform: translate(#{2 * $icons-width}, 0.2rem) scale(1);
    }
  }
}
.form .form-group .search-field {
  margin: 0.5rem 0;
  position: relative;
  input[type="text"] {
    background-color: $ittPortalGreyF7;
  }
}
.form .form-group label.search-icon-multiple-reasons {
  font-weight: 900;
  font-size: 1rem;
  position: absolute;
  z-index: 4;
  color: $ittPortalGrey3;
  top: 1rem;
  cursor: pointer;
}

.form-group__radio {
  .search-field > label {
    line-height: 1rem;
    padding-left: 1rem !important;
    color: $ittPortalGrey9B !important;
    z-index: 3;
  }
}

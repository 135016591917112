#scheduled-report-page {
  &.notEditableReport {
    #report_save {
      display: none;
    }
  }

  #scheduled-report-page-container {
    padding: 0;
  }

  #singleScheduledReportForm {
    #sensorsGrid {
      max-height: calc(100vh - 20.3rem);
      #sensorsGrid-grid-data {
        height: auto;
      }
    }

    > .form-button-container {
      $two-buttons-gap: 0.40625rem;
      width: 69.3%;
      z-index: 3;
      padding: 0 0 1.5rem 0;
      text-align: center;
      box-shadow: none;
      background-color: transparent;

      .form-group {
        margin: 0;
        padding: 0;
        max-width: none;
        width: 100%;
        display: block;

        button {
          width: 100%;
          margin: 0;

          &#report_cancel {
            margin-right: $two-buttons-gap;
            width: calc(50% - #{$two-buttons-gap});
          }
          &#report_save {
            width: calc(50% - #{$two-buttons-gap});
            margin-left: $two-buttons-gap;
          }
        }
      }
    }

    .form-group__radio + .form-button-container {
      width: 100%;
      padding: 0 0 0.5rem 0;
      z-index: 3;
      .form-group {
        max-width: none;
      }
    }
  }
  .slide-over-form-with-sidebar {
    .content {
      &.bg-grey {
        background-color: $ittPortalGreyF7;
      }
      section {
        background-color: #fff;
        padding: 0;
      }
    }
    #sensorsGrid-gridFiltersForm {
      .form-button-container {
        width: 100%;
        padding: 0 10% 0.5rem 10%;
        -webkit-box-shadow: none;
        box-shadow: none;
        button {
          white-space: nowrap;
        }
      }
    }
  }
  .form-fields-container {
    max-width: none;
    min-height: calc(100vh - 7.5rem);
    padding: 0;

    .title {
      margin-top: 3.5rem;
      text-align: left;
    }
    .form__step {
      padding: 0 2rem;

      &#basicInfoTab,
      &#sensorsTab {
        padding: 0 0 0 2rem;
      }
    }
  }
  .filtered-scheduled-report-disclaimer-container-sensors-tab {
    display: flex;
    background: $ittPortalGreyF7;
    border-radius: 0.5rem;

    &.hide-sensors-disclaimer {
      display: none;
    }

    .filtered-scheduled-report-disclaimer-sign-sensors-tab {
      padding: 1.188rem 1.5rem 1.313rem 1.188rem;
    }
    .filtered-scheduled-report-disclaimer-sensors-tab {
      flex: 1;
      @include fontsize(0.75rem, normal, 400);
      letter-spacing: normal;
      color: $ittPortalGrey3;

      ol {
        padding-left: 0.8rem;
      }
    }
  }
  #basicInfoTab-form-sections-id {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 100%;

    @media (max-width: $tablet-break-point) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }

    #form-section-basicInfoSection {
      .form-section-content {
        max-width: 500px;
        padding-right: 2rem;
        width: 100%;
        @media (max-width: $tablet-break-point) {
          max-width: none;
          padding-right: 1rem;
        }
      }
    }

    #form-section-reportRecipientsSection {
      height: fit-content;
      padding: 0 2.125rem 0 3.125rem;
      @include box-shadow(0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.08));
      @media (max-width: $tablet-break-point) {
        @include box-shadow(none);
        padding: 0 1rem 0 0;
      }

      .form-section-content {
        display: flex;
        padding-top: 0;

        .form-section-fields {
          @media (max-width: $tablet-break-point) {
            width: 100%;
          }
          @media (max-width: $mobile-break-point) {
            display: grid;
            grid-template-areas:
              "recipients-textarea"
              "recipients-list";
          }
          > div {
            padding-top: 1.5rem !important;
            @media (max-width: $mobile-break-point) {
              padding-top: 0 !important;
            }
          }
          .form-group__multiple-checkbox-and-text {
            @media (max-width: $mobile-break-point) {
              grid-area: recipients-list;
              width: 100%;
              max-height: 20rem;
              margin: 0;
              padding: 0;
              border: none;
            }
            + .form-group__multiple-checkbox-and-text {
              border: none;
              @media (max-width: $mobile-break-point) {
                grid-area: recipients-textarea;
                margin: 0 0 0.5rem 0;
                padding: 0;
                width: 100%;
              }
            }
            .checkbox-list {
              @media (max-width: $mobile-break-point) {
                overflow-y: auto;
              }
            }
          }
          .form-group {
            padding-top: 0;
          }
        }
      }
    }
  }

  #sensorsTab {
    overflow: hidden;
    .grid .grid-data {
      height: calc(100vh - 15.5rem);
    }
  }

  #rulesTab {
    .form-sections-container {
      height: auto;
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-areas:
        "report-type rules-info"
        "report-rules rules-info";
      grid-gap: 0.875rem;
      padding: 0.875rem 0.875rem 0.875rem 0;

      @media (max-width: 1200px) {
        column-gap: 0;
        grid-template-columns: 100%;
        grid-template-areas:
          "report-type"
          "report-rules"
          "rules-info";
      }

      section {
        height: max-content;

        .form-section-content {
          padding: 1.5rem 3.375rem;
        }
      }

      #form-section-rulesDisclaimerSection {
        grid-area: rules-info;

        .form-section-content {
          padding: 0;
        }
      }

      #form-section-rulesSection {
        grid-area: report-rules;
      }

      #form-section-reportTypeSection {
        grid-area: report-type;
      }

      .filtered-scheduled-report-disclaimer-container {
        display: flex;
        flex-direction: column;
        border-radius: 1rem;

        .filtered-scheduled-report-disclaimer-sign {
          margin: 2rem auto 1.813rem auto;
        }

        .filtered-scheduled-report-disclaimer {
          margin: 0 2rem auto 1.188rem;
          font-size: 0.875rem;

          #disclaimer-title {
            text-align: center;
          }

          #disclaimer-footer {
            margin-top: 1.8125rem;
          }
        }
      }

      #form-section-rulesSection {
        .form-section-title {
          @include fontsize(1.5rem, normal, 700);
          color: $ittPortalGrey3;
          margin-bottom: 1.688rem;
        }

        .form-section-content {
          .form-section-fields {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 1rem;

            @media (max-width: $tablet-break-point) {
              grid-template-columns: 100%;
            }

            .form-field {
              input {
                height: 3.438rem;
              }
            }
          }
        }
      }
    }
  }
}

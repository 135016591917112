#mapViewContent {
  display: grid;
  grid-gap: 0;
  overflow-y: auto;
  height: 100%;
  grid-template-columns: 59.45% 40.55%;
  grid-template-rows: auto;
  grid-template-areas: "map sensors-grid";
  @media (max-width: $tablet-break-point) {
    grid-template-areas:
      "map"
      "sensors-grid";
    grid-template-columns: 100%;
    grid-template-rows: 100vh;
  }

  .google-map-container {
    grid-area: map;
    position: relative;
    margin-top: 0;

    #sensors-map-view-google-map {
      height: 100%;
      width: 100%;
    }
  }
  .map-sensors-grid {
    grid-area: sensors-grid;
    position: relative;

    #back-to-sensors {
      position: relative;
      left: 0;
      top: 0;
      margin: 1.5rem 1.5rem 1rem 1.5rem;
      padding-top: 0;
      padding-bottom: 0;
      @include fontsize(1.5rem, normal, 700);

      a {
        margin-left: 0.75rem;
        cursor: pointer;
        &:after {
          @include arrow($ittPortalGrey3, 0.36rem);
          cursor: pointer;
        }
      }
    }
    .grid-data {
      $extra-height: 3.3rem;
      height: calc(
        100vh - #{$header-height + $grid-margin-top + $min-grid-buttons-height +
          $extra-height}
      );
      @media (max-width: $header-break-point) {
        height: calc(
          100vh - #{$header-height-mobile + $grid-margin-top +
            $min-grid-buttons-height + $extra-height}
        );
      }
    }

    .hasPinLocation,
    .needsPinLocation {
      padding: 0.375rem 1rem;
      svg {
        font-size: 0.5rem;
      }
    }
    .needsPinLocation {
      background-color: #000;
      color: #fff;
      font-size: 0.75rem;
      svg {
        background-color: #000;
        color: #fff;
      }
    }
  }
}

#portal-header {
  $header-padding: 1rem;
  $header-lang-switcher-width: 3.45rem;
  $header-notifications-width: 3.25rem;
  $header-profile-container-width: 4.375rem;
  $hamburger-width: 2.1875rem;
  $header-small-parts-width-mobile: 2.5rem;
  $header-total-right-part-width: 4 * $header-small-parts-width-mobile;
  $z-index-search: 5;

  grid-area: header;
  background-color: $headerColor;
  display: grid;
  position: fixed;
  z-index: $header-z-index;
  width: 100%;
  top: 0;
  grid-gap: 0;
  grid-template-rows: calc(#{$header-height-upper-part} - #{$header-padding}) #{$header-height-lower-part};
  grid-template-columns: 1fr 7fr 2fr $header-lang-switcher-width $header-notifications-width $header-profile-container-width;
  grid-template-areas:
    "logo header-hierarchy header-search lang-switcher header-notifications profile-container"
    "main-menu main-menu main-menu main-menu main-menu main-menu";
  @media (max-width: $header-break-point) {
    grid-template-areas:
      "logo header-hierarchy header-search lang-switcher header-notifications profile-container"
      "main-menu main-menu main-menu main-menu main-menu main-menu";
    grid-template-rows: calc(#{$header-height-lower-part} - #{$header-padding}) calc(
        #{$header-height-lower-part} - #{$header-padding}
      );
    grid-template-columns:
      calc(0.25 * (100% - #{$header-total-right-part-width}))
      calc(0.75 * (100% - #{$header-total-right-part-width}))
      $header-small-parts-width-mobile $header-small-parts-width-mobile $header-small-parts-width-mobile $header-small-parts-width-mobile;
  }
  @media (max-width: $header-break-point-2) {
    grid-template-areas:
      "logo header-hierarchy header-search lang-switcher header-notifications profile-container"
      "main-menu main-menu main-menu main-menu main-menu main-menu";
    grid-template-columns:
      calc(0.15 * (100% - #{$header-total-right-part-width}))
      calc(0.85 * (100% - #{$header-total-right-part-width}))
      $header-small-parts-width-mobile $header-small-parts-width-mobile $header-small-parts-width-mobile $header-small-parts-width-mobile;
  }
  &:not(.authentication-header) {
    padding: $header-padding $header-padding 0 $header-padding;
    background-color: #fff;
    @include box-shadow(0 2px 14px 0 rgba(0, 0, 0, 0.08));
    @media (max-width: $header-break-point) {
      padding: calc($header-padding / 2) $header-padding
        calc($header-padding / 2) $header-padding;
    }

    #portal-logo {
      img {
        padding-right: 0.5rem;
      }
    }
  }
  &.authentication-header {
    position: absolute;
    grid-template-rows: $header-height-not-authenticated;
    background: transparent;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "logo main-menu profile-container";
    @media (max-width: $header-break-point) {
      grid-template-areas: "logo main-menu profile-container";
      grid-template-columns: 1fr 1fr;
    }

    #profile-container {
      padding-right: 2.5rem;
    }

    a {
      color: $ittBlue;
      font-weight: 700;
      margin: 0 1rem;
    }

    #selected-lang-container {
      color: $ittPortalGrey6;
    }

    #portal-logo {
      border: 0;
      img {
        max-height: $header-height-not-authenticated;
        margin-left: 0;
      }
    }
  }
  ::-webkit-scrollbar {
    height: 0;
  }
  .add-new-asset {
    grid-area: add-new-asset;
    justify-self: right;
    margin-top: 0.5rem;
    position: relative;
    @media (max-width: $header-break-point) {
      &:before {
        $shadow-width: 2rem;
        content: "";
        background: #fff;
        position: absolute;
        width: $shadow-width;
        top: 0;
        bottom: 0;
        left: #{-1 * $shadow-width};
        z-index: 2;
        opacity: 0.5;
      }
    }
    .btn-add-new {
      @media (max-width: $header-break-point) {
        font-size: 0.7rem;
      }
      @media (max-width: $header-break-point-2) {
        width: 0;
        overflow: hidden;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        color: transparent;
      }
      &:before {
        @media (max-width: $header-break-point-2) {
          left: 0.6rem;
          top: 0.5rem;
          color: $ittPortalGrey3;
        }
      }
    }
  }
  #header-hierarchy {
    grid-area: header-hierarchy;
    display: grid;
    grid-auto-columns: auto;
    grid-template-areas: "account-level plant-level area-level zone-level";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    border-bottom: 1px solid $ittPortalGreyF7;
    @include fontsize(0.875rem, normal, 700);
    position: relative;
    z-index: #{$z-index-search - 1};

    @media (max-width: $header-break-point-2) {
      .area-level,
      .zone-level {
        display: none !important;
      }
    }

    @media (max-width: 440px) {
      margin-left: 1rem;
    }

    $picture-width: 2rem;
    $picture-padding: calc($picture-width / 4);

    .expired {
      color: $ittPortalGrey9B;
      user-select: none;
    }

    .account-level,
    .plant-level,
    .area-level,
    .zone-level {
      position: relative;
      padding-right: 1.5rem;
      margin-right: 0.5rem;
      border-right: 1px solid $ittPortalGreyF7;
      cursor: pointer;
      height: 60%;
      vertical-align: middle;
      display: grid;
      max-width: 100%;
      overflow: hidden;
      @media (max-width: $header-break-point) {
        justify-content: center;
      }

      > div {
        align-self: center;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        @media (max-width: $header-break-point) {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          display: inline;
        }
        &.account-level-name {
          padding-left: 2 * $picture-padding + $picture-width;
          @media (max-width: $header-break-point) {
            width: 100%;
          }
          @media (max-width: $header-break-point-2) {
            padding-left: #{$picture-width + 0.5};
          }
        }
        .dropdown-text-tag {
          position: relative;
          max-width: 100%;
          @media (max-width: $header-break-point) {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
          }
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            max-width: 100%;
          }
          &:after {
            content: "";
            @include arrow($ittPortalGrey9B, 0.125rem);
            @include vertical-align(absolute);
            @include transform(translateY(-50%) rotate(45deg));
            right: -1.3rem;
            height: 0.5rem;
            width: 0.5rem;
            z-index: 1;
            transition: all 0.2s;
          }
        }
      }
      svg {
        margin-right: 0.5rem;
      }
    }
    .account-level {
      grid-area: account-level;
      .header-account-img {
        @include roundImage(
          $picture-width,
          $picture-padding,
          $ittPortalGreyDF,
          $ittPortalBlue
        );
      }
    }
  }
  #header-search {
    grid-area: header-search;
    border-bottom: 1px solid $ittPortalGreyF7;
    position: relative;
    @media (max-width: $header-break-point) {
      margin-right: 0;
    }

    #globalSearchInput-field-container {
      @media (max-width: $header-break-point) {
        .form-field {
          justify-content: end;
          flex-flow: unset;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 15rem;

          &:before {
            left: auto;
            right: 1rem;
            z-index: #{1 + $z-index-search};
          }
          input {
            width: 2rem;
            overflow: hidden;
            border: 0;
            position: relative;
            z-index: #{2 + $z-index-search};
            @include transform(all 0.2s ease);

            &:focus {
              width: 100%;
              border-bottom: 1px solid $ittPortalGreyF7;
              @include roundedcornersmixin(0);
              background-color: #fff;
              z-index: $z-index-search;

              & + label {
                opacity: 1;
                left: 0;
                position: absolute;
                z-index: #{3 + $z-index-search};
              }
            }

            & + label {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  #lang-switcher {
    grid-area: lang-switcher;
    border-bottom: 1px solid $ittPortalGreyF7;
    @media (max-width: $header-break-point) {
      display: grid;
      justify-content: center;
    }

    .flags {
      display: inline-block;
    }
  }
  #header-notifications {
    .overlay {
      position: fixed;
      width: 2rem;
      height: 4rem;
      background-color: rgba(255, 255, 255, 0.65);
      z-index: $overlay-z-index;
      top: 0;
    }

    grid-area: header-notifications;
    border-bottom: 1px solid $ittPortalGreyF7;
    @media (max-width: $header-break-point) {
      display: grid;
      justify-content: center;
      align-content: center;
    }

    .notifications {
      $notifications-list-width: 270px;
      $notifications-list-arrow: 10px;
      display: inline-block;
      padding: 0;
      cursor: pointer;
      color: $ittPortalGrey;
      position: relative;

      .fa-bell {
        margin-top: 0.5rem;
        font-size: 2rem;
        color: $ittPortalGrey3;
        &:not(.active) {
          color: $ittPortalBlue;
        }
        @media (max-width: $header-break-point) {
          font-size: 1.5rem;
        }

        &.ringing {
          @include animation(bell-ringing 1.5s ease);
        }
      }

      .counter {
        $counter-padding: 0.2rem;
        $counter-font-size: 0.75rem;
        position: absolute;
        background-color: $ittPortalRedDark;
        padding: 0;
        width: 0;
        min-height: 0;
        color: #fff;
        @include fontsize($counter-font-size, normal, 700);
        top: $counter-padding;
        right: -1 * $counter-font-size;
        display: inline-block;
        border-radius: 0.25rem;
        @include transition(all 0.5s);

        &.active {
          width: auto;
          min-height: 2 * $counter-padding + $counter-font-size;
          padding: $counter-padding 2 * $counter-padding;
          @media (max-width: $header-break-point) {
            min-height: $counter-padding + $counter-font-size;
            padding: $counter-padding $counter-padding;
          }
        }
      }

      #notifications-list-container {
        position: absolute;
        width: $notifications-list-width;
        top: 3rem;
        left: calc(#{-1 * calc($notifications-list-width / 2)} + 0.85rem);
        overflow: hidden;
        z-index: 3;
        padding-top: $notifications-list-arrow;
        @include transition(height 0.5s ease-in);
        @include roundedcornersmixin(5px);
        @media (max-width: $header-break-point) {
          left: -2 * calc($notifications-list-width / 3);
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: $notifications-list-arrow;
          width: 0;
          height: 0;
          border-left: $notifications-list-arrow solid transparent;
          border-right: $notifications-list-arrow solid transparent;
          border-bottom: $notifications-list-arrow solid $ittPortalBlueDark;
          clear: both;
          z-index: 2;
          @include transition(top 0.25s ease-in);

          @media (max-width: $header-break-point) {
            right: 62px;
            left: auto;
          }
        }

        &.active {
          //height: $notifications-list-width + 5 * $notifications-list-arrow !important
          &:after {
            top: 0 !important;
          }

          ul#notifications-list {
            max-height: $notifications-list-width !important;
          }

          #all-notifications {
            display: block;
            padding: 10px;
            border: 1px solid rgba(170, 170, 170, 0.4);
          }

          .notifications-ul-container {
            border: 1px solid rgba(170, 170, 170, 0.4) !important;
          }
        }

        .notifications-ul-container {
          @include roundedcornersmixin(5px 5px 0 0);
          border: none;
        }

        #all-notifications {
          font-size: 0.9em;
          background-color: $ittPortalBlueDark;
          position: relative;
          bottom: 0;
          width: 100%;
          z-index: 4;
          display: none;
          text-align: center;

          a {
            color: $ittPortalLeftNav;
            font-size: 0.95em;
          }
        }

        ul#notifications-list {
          max-height: 0;
          position: relative;
          margin: 0;
          padding: 0;
          background-color: $ittPortalLeftNav;
          color: $ittPortalBlueDark;
          width: 100%;
          list-style: none;
          z-index: 3;
          overflow-y: auto;
          text-align: left;
          @include transition(all 0.5s ease-in);

          li {
            border-bottom: 1px solid rgba(170, 170, 170, 0.4);
            font-size: 0.73em;
            padding: 5px;
            width: 100%;
            display: flex !important;
            flex-direction: row;

            &:last-of-type {
              border-bottom: none;
            }

            $icons-width: 30px;

            .message-image {
              padding: 0;
              text-align: center;
              width: $icons-width;
              font-size: 1.5em;
            }

            i {
              font-size: 24px;
            }

            img {
              @extend .img-responsive;
            }

            .message-content {
              width: calc(100% - #{$icons-width});
              padding-left: 5px;
            }

            .message-text {
              @include showLines(2, 1.65em);
            }

            .message-title {
              font-weight: 700;
              font-size: 1.3em;
            }

            .message-date {
              font-size: 0.9em;
            }
          }
        }
      }
    }
  }
  #portal-logo {
    grid-area: logo;
    max-width: 100%;
    border-bottom: 1px solid $ittPortalGreyF7;
    height: 100%;
    display: grid;
    align-items: center;

    img {
      max-height: $header-height-upper-part;
      width: auto;
      max-width: 100%;
      height: auto;
      align-self: center;
      @media (max-width: $header-break-point) {
        max-width: 60%;
      }

      @media (max-width: $header-break-point-2) {
        max-width: 100%;
        display: none;
      }

      &#portal-logo-img-mobile {
        display: none;
        @media (max-width: $header-break-point-2) {
          display: block;
        }
        @media (max-width: 620px) {
          width: 2.8rem;
          max-width: fit-content;
        }
      }
    }
  }
  #main-menu {
    grid-area: main-menu;
    align-self: center;
    display: grid;
    grid-auto-rows: 100%;
    height: 100%;
    align-items: center;
    @media (max-width: $header-break-point) {
      padding-right: 0;
      display: grid;
      width: 100%;
      overflow-x: auto;
      grid-auto-rows: $header-height-lower-part-mobile;
      grid-auto-flow: column;
      align-items: center;
      grid-auto-columns: max-content;
      position: relative;
    }
  }
  #profile-container {
    grid-area: profile-container;
    text-align: right;
    border-left: 1px solid #ffffff;
    border-bottom: 1px solid $ittPortalGreyF7;
    @media (max-width: $header-break-point) {
      border-left: none;
      display: grid;
      align-items: center;
    }

    .header-profile {
      position: relative;
      cursor: pointer;
      border-left: none;
      background: transparent;
      padding: 0 0;
      margin: 0.4rem 0.625rem 0 0;
      width: auto;
      //height: $header-height;
      font-weight: 700;
      font-size: 0.6875rem;
      line-height: 0.6875rem;
      color: $ittPortalGrey;
      @media (max-width: $header-break-point) {
        display: none;
      }
      &.active {
        &:after {
          @include transform(rotate(-135deg));
        }
      }
      &:after {
        content: "";
        @include arrow($ittPortalGrey3, 0.125rem);
        @include transform(rotate(45deg));
        position: absolute;
        right: 0;
        top: 0.9rem;
        height: 0.5rem;
        width: 0.5rem;
        z-index: 1;
        transition: all 0.2s;
        /*@media(max-width: $header-break-point) {
					display: none;
				}*/
      }

      &#header-profile-mobile {
        display: none;
        margin-right: 0;
        @media (max-width: $header-break-point) {
          display: grid;
          justify-content: center;
        }
        > span {
          margin-right: 0;
          max-width: 100px;
        }

        > i.fa {
          visibility: hidden;
        }
      }

      $picture-width: 2rem;
      $picture-width-mobile-ratio: 0.75;
      .header-profile-img {
        @include circle(
          $ittPortalGrey,
          $ittPortalBlue,
          $ittPortalBlue,
          $picture-width
        );
        border: 0;
        display: inline;
        width: $picture-width;
        height: $picture-width;
        margin: 0 calc($picture-width/2);
        float: left;
        cursor: pointer;
        @media (max-width: $header-break-point) {
          margin: 0 auto;
          width: #{$picture-width * $picture-width-mobile-ratio};
          height: #{$picture-width * $picture-width-mobile-ratio};
        }
      }
      .initial-letters {
        position: absolute;
        top: 0.65 * $picture-width;
        left: 0.2rem;
        padding: 0.125rem 0.1875rem;
        background-color: $ittPortalGrey3;
        color: #fff;
        @include roundedcornersmixin(0.25rem);
        @include fontsize(0.75rem, normal, 700);
        @media (max-width: $header-break-point) {
          display: none;
        }
      }

      .header-profile-title {
        font-weight: normal;
        font-size: 0.625rem;
        margin-top: 0.25rem;
        display: block;
      }
    }
    #header-profile-menu-popover {
      z-index: $header-profile-menu-popover-z-index;
      ul {
        position: relative;
        margin: 0;
        padding: 0;
        background-color: #fff;
        color: $ittPortalGrey3;
        width: 100%;
        list-style: none;
        z-index: 3;
        overflow-y: hidden;
        @include transition(all 0.5s ease-in);

        li {
          border-bottom: 1px solid rgba(170, 170, 170, 0.4);
          font-size: 1.2em;
          padding: 0.5rem 0;
          width: 100%;
          flex-direction: row;
          text-transform: uppercase;
          display: block !important;
          text-align: left;
          margin-bottom: 1px;
          &:hover,
          &.active {
            font-weight: 700;
            border-bottom: 2px solid rgba(0, 0, 0, 1);
            margin-bottom: 0;
          }
          a {
            color: $ittPortalGrey3;
            text-decoration: none;
            width: 100%;
            display: block;
          }
        }
      }
    }

    #header-profile-menu {
      position: absolute;
      top: $header-height-upper-part;
      overflow: hidden;
      right: 0;
      font-size: 0.75rem;
      padding: 0;
      min-width: 0;
      width: 125px;
      z-index: 2;
      @include transition(height 0.25s ease-in);

      &.active {
        ul {
          max-height: 200px !important;
          border-bottom: 1px solid $ittPortalGreyLight2;
        }
      }

      ul {
        max-height: 0;
        position: relative;
        padding: 0;
        margin: 0;
        border-left: 1px solid $ittPortalGreyLight2;
        @include transition(all 0.5s ease-in);

        li {
          list-style: none;
          width: 100%;

          a {
            background: #fff;
            color: $ittPortalGrey3;
            padding: 0.3125rem 0.625rem;
            border-top: 1px solid $ittPortalGreyLight2;
            border-right: 1px solid $ittPortalGreyLight2;
            width: 100%;
            margin: 0;
            display: block;
            text-align: left;

            @include hover_state {
              background: $ittPortalGreyC;
              text-decoration: none;
            }
          }
        }
      }
    }
    #header-language-popover-container {
      .header {
        .title {
          text-align: left;
        }
      }
    }
  }

  #mobileMenu {
    grid-area: mobileMenu;
    display: none;
    top: -100%;
    @include transition(top 0.25s ease-in);
    @media (max-width: $header-break-point) {
      display: none;
    }

    &.active {
      top: 0;

      ul {
        max-height: calc(100vh - #{$header-height-lower-part});
        overflow-y: auto;
        border-bottom: 1px solid $ittPortalGreyLight2;
      }
    }

    ul {
      overflow: hidden;
      max-height: 0;
      position: relative;
      padding: 0;
      margin: 0;
      border-left: 1px solid $ittPortalGreyLight2;
      @include transition(all 0.5s ease-in);

      li {
        list-style: none;
        width: 100%;

        a {
          background: #fff;
          color: $ittPortalGrey3;
          padding: 0.5rem 0.625rem;
          border-top: 1px solid $ittPortalGreyLight2;
          border-right: 1px solid $ittPortalGreyLight2;
          width: 100%;
          margin: 0;
          display: block;
          text-align: left;
          font-weight: 400;
          font-size: 0.9rem;
          text-decoration: none;

          @include hover_state {
            background: $ittPortalGreyC;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .hamburger {
    $hamburger-right: 62px;

    grid-area: hamburger;
    justify-self: center;
    background-color: transparent;
    border: 0 none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    margin: 0;
    overflow: visible;
    padding: 0.5em 0;
    text-transform: none;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    transition-timing-function: linear;
    z-index: $header-z-index;
    right: 0;
    display: none;
    //@media(max-width: $header-break-point) {
    //	display: block;
    //}
    @media (max-width: 400px) {
      right: 5px;
    }

    &:after {
      content: "MENU";
      position: absolute;
      right: #{-1 * $hamburger-right};
      top: 17px;
      color: #ffffff;
      font-weight: 700;

      @media (max-width: $header-break-point) {
        display: none;
      }
    }

    &.is-active {
      &:after {
        content: "";
      }
    }

    &.hamburger--slider {
      .hamburger-inner {
        top: 2px;

        &:before {
          top: 10px;
          transition-duration: 0.15s;
          transition-property: transform, opacity;
          transition-timing-function: ease;
        }

        &:after {
          top: 20px;
        }
      }

      &.is-active .hamburger-inner {
        transform: translate3d(0, 10px, 0) rotate(45deg);

        &:before {
          opacity: 0;
          transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
        }

        &:after {
          transform: translate3d(0px, -20px, 0) rotate(-90deg);
        }
      }
    }

    .hamburger-box {
      display: inline-block;
      height: 24px;
      position: relative;
      width: 35px;
    }

    .hamburger-inner {
      display: block;
      margin-top: -2px;
      top: 50%;
    }

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $ittPortalGrey;
      border-radius: 0.25rem;
      height: 0.25rem;
      position: absolute;
      @include transition(transform 0.15s ease);
      width: 100%;
    }

    .hamburger-inner::after,
    .hamburger-inner::before {
      content: "";
      display: block;
    }

    .hamburger-inner::before {
      top: -10px;
    }

    .hamburger-inner::after {
      bottom: -10px;
    }
  }
}

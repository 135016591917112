.basic-account-container {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: 45% 1fr;
  grid-template-areas: "slider pro";
  grid-template-rows: auto;

  @media (max-width: $tablet-break-point) {
    grid-template-columns: 100%;
    grid-template-areas:
      "pro"
      "slider";
  }

  .portal-slider-section {
    grid-area: slider;
    background-color: $ittPortalGreyF7;
  }

  .upgrade-to-pro-section {
    grid-area: pro;
    @media (max-width: $tablet-break-point) {
      font-size: 0.625rem;
    }

    .separator {
      width: 100%;
      height: 1px;
      background-color: $ittPortalGreyDF;
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      h3 {
        @include fontsize(1.125rem, normal, 700);
        margin-top: 1.8125rem;
        margin-bottom: 1.25rem;
      }

      @media (max-width: $tablet-break-point) {
        height: auto;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media (max-width: $tablet-break-point) {
        height: auto;
        padding-top: 0.75rem;
      }

      .grid-table-container {
        text-align: center;
        width: 83%;
        height: 80%;
        display: grid;
        margin-top: 1rem;

        .cols {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          padding: 0.75rem 0;
          border-bottom: 1px solid $ittPortalGreyF7;
        }

        .labels {
          font-size: 1rem;
          font-weight: bold;
          @media (max-width: $tablet-break-point) {
            font-size: 0.625rem;
          }
        }

        .row {
          font-size: 0.625rem;
        }
      }

      button {
        background-color: $ittPortalGreenSuccess;
        border: solid 2px $ittPortalGreyF7;
        border-radius: 4px;
        padding: 1rem;
        width: 30%;
        margin-top: 1.5rem;
        font-size: 0.75rem;
        font-weight: bold;
        cursor: pointer;

        @media (max-width: $tablet-break-point) {
          font-size: 0.625rem;
          font-weight: bold;
        }
      }
    }

    footer {
      display: flex;
      justify-content: center;
      margin-top: 3.625rem;
      font-size: 0.875rem;

      div {
        width: 90%;
      }

      @media (max-width: $tablet-break-point) {
        margin: 1rem 0;
      }
    }
  }

  #upgrade-to-pro-popup {
    z-index: 10;
    overflow-y: auto;

    #upgrade-to-pro-popup-container {
      min-width: 58%;
      padding: 2.3125rem 1rem 0 1rem;

      .form-fields-container {
        min-height: calc(100vh - 6rem);
      }

      .form-button-container {
        max-width: 100%;
        padding: 0 0 0.5rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .form-group {
          width: 97%;
        }
      }

      .title {
        text-align: initial;
        width: 43%;
        margin-bottom: 1.4995rem;

        @media (max-width: $tablet-break-point) {
          text-align: center;
          width: 100%;
        }
      }

      [data-step="1"] {
        padding: 0.5rem;

        .form-sections-container {
          height: 100%;
          display: grid;
          grid-template-rows: auto;
          grid-template-areas:
            "firstSection secondSection"
            "lastSection lastSection";
          grid-gap: 1rem;

          #form-section-contactInformation {
            grid-area: firstSection;
          }

          #form-section-subscriptionInformation {
            grid-area: secondSection;

            #comments {
              min-height: 15.3125rem;
            }
          }

          #form-section-footerSection {
            grid-area: lastSection;

            .form-section-content {
              padding-top: 0;
            }

            #totalCost-field-container {
              margin-top: 0.5rem;

              label,
              #totalCost {
                font-size: 1.5rem;
                font-weight: bold;
                color: #000000;
                padding: 0;
              }
            }

            #disclaimer {
              margin-top: 1.375rem;
              font-size: 0.75rem;
            }
          }

          #form-section-contactInformation,
          #form-section-subscriptionInformation {
            .form-section-title {
              font-size: 1.125rem;
            }
          }

          .form-field input[type="text"],
          select {
            height: 55px;
          }

          @media (max-width: $tablet-break-point) {
            grid-template-columns: 100%;
            grid-template-areas:
              "firstSection"
              "secondSection"
              "lastSection";
          }
        }
      }

      [data-step="2"] {
        padding: 0.5rem;

        .form-sections-container {
          height: 100%;
          display: grid;
          grid-template-rows: auto;
          grid-template-areas:
            "billLeft billRight"
            "shipLeft shipRight";
          grid-gap: 1rem;

          .form-field input[type="text"],
          select {
            height: 3.4375rem;
          }

          #form-section-billInformation-left {
            grid-area: billLeft;
          }

          #form-section-billInformation-right {
            grid-area: billRight;
            margin-top: 1.9375rem;
          }

          #form-section-shipInformation-left {
            grid-area: shipLeft;

            .form-section-content {
              padding-top: 0;

              #shipToSameAsBillTo-field-container {
                padding-top: 0.125rem;

                label {
                  color: #000000;
                }
              }
            }
          }

          #form-section-shipInformation-right {
            grid-area: shipRight;
            margin-top: 2.875rem;
          }

          #form-section-billInformation-left,
          #form-section-shipInformation-left {
            .form-section-title {
              font-size: 1.125rem;
            }
          }

          @media (max-width: $tablet-break-point) {
            grid-template-columns: 100%;
            grid-template-areas:
              "billLeft"
              "billRight"
              "shipLeft"
              "shipRight";

            #form-section-billInformation-right,
            #form-section-shipInformation-right {
              margin-top: 0;
            }
          }
        }
      }

      [data-step="3"] {
        padding: 0.5rem;

        .form-sections-container {
          height: 100%;
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0 1.8rem;
          width: 80%;

          .form-group {
            margin: 0;

            input[type="text"] {
              height: 3.625rem;

              &:not(:placeholder-shown) + label {
                transform: translate(0rem, 0.25rem) scale(1);
              }
            }
          }
          #form-section-billInformation-right {
            margin-top: 1.9375rem;
          }

          #form-section-shipInformation-right {
            margin-top: 1.875rem;
          }

          #form-section-footerSection {
            display: none;
          }

          #form-section-contactInformation,
          #form-section-subscriptionInformation,
          #form-section-billInformation-left,
          #form-section-shipInformation-left {
            .form-section-content {
              padding-top: 0.5rem;
            }
            .form-section-title {
              font-size: 1.125rem;
              margin-bottom: 0.75rem;
            }
          }
          #form-section-billInformation-left,
          #form-section-shipInformation-left {
            .form-section-content {
              padding-top: 2.125rem;
            }
          }

          @media (max-width: $tablet-break-point) {
            grid-template-columns: 1fr;

            #form-section-billInformation-right,
            #form-section-shipInformation-right {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

.fft-twf-chart-container-section {
  .trend-section-buttons .form-field {
    min-width: 8rem;
  }
  .chart-selected-sensors {
    margin-left: 1rem;
    @media (max-width: $tablet-break-point) {
      margin-left: 0;
    }

    .chart-selected-sensor {
      padding: 0 0.825rem;
      &-name {
        padding-right: 0.25rem;
      }
    }

    .add-sensor {
      margin-left: 0.5rem;
    }
    .fft-orientation-list-normal-operation {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      margin-left: 1rem;
      font-size: 0.825rem;

      .asset-train-page-fft-item-mark-as-baseline {
        margin-left: 0.25rem;
      }
    }
  }

  .fft-twf-acceleration-view-switch {
    .switch-container {
      height: auto;

      .btn-color-mode-switch-inner {
        $font-size: 0.875rem;
        padding: 0;
        border: 0;
        height: 3.4375rem;
        @include fontsize($font-size, normal, 400);

        &:before,
        &:after {
          width: 50%;
          height: $font-size;
          background-color: transparent;
          color: $ittPortalGrey3;
          top: #{calc(50% - $font-size/2)};
          text-underline-offset: 0.5rem;
        }

        &:before {
          content: attr(data-off);
          font-weight: 400;
          text-decoration: none;
          left: 0;
        }

        &:after {
          content: attr(data-on);
          font-weight: 700;
          text-decoration: underline;
          left: 50%;
        }
      }

      input[type="checkbox"] {
        &:checked {
          + label.btn-color-mode-switch-inner {
            &:after {
              content: attr(data-off);
              font-weight: 700;
              text-decoration: underline;
              left: 0;
            }

            &:before {
              content: attr(data-on);
              font-weight: 400;
              text-decoration: none;
              left: 50%;
            }
          }
        }
      }
    }
  }
  #twf-acceleration-switch {
    .btn-color-mode-switch-inner {
      height: 1.4375rem;
    }
  }

  .fft-twf-chart-container-section-buttons {
    $buttons-height: 3.4375rem;
    display: grid;
    grid-gap: 0 1rem;
    grid-template-columns: 0.1fr 0.6fr 0.4fr 0.4fr 1fr 1fr;
    grid-template-areas: "fft-3d-switch fft-harmonics fft-fft-unit fft-vibration-unit fft-filter-orientations fft-acceleration-switch";
    margin: 0 1rem;
    @media (max-width: 1300px) {
      grid-template-columns: 1fr 2fr 1fr 1fr;
      grid-template-areas:
        "fft-3d-switch fft-harmonics fft-fft-unit fft-vibration-unit fft-filter-orientations"
        "... ... ... ... fft-acceleration-switch";
    }
    @media (max-width: 1110px) {
      grid-template-columns: 0.5fr 1fr 1fr;
      grid-template-areas:
        "fft-3d-switch fft-harmonics fft-fft-unit"
        "fft-vibration-unit fft-filter-orientations fft-acceleration-switch";
    }
    @media (max-width: 820px) {
      grid-template-columns: 0.5fr 1fr;
      grid-template-areas:
        "fft-3d-switch fft-harmonics"
        "fft-fft-unit fft-vibration-unit"
        "fft-filter-orientations fft-acceleration-switch";
    }
    @media (max-width: $tablet-break-point) {
      grid-template-columns: 0.5fr 1fr;
      margin: 0;
    }
    @media (max-width: 520px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "fft-3d-switch"
        "fft-harmonics"
        "fft-fft-unit"
        "fft-vibration-unit"
        "fft-filter-orientations"
        "fft-acceleration-switch";
    }

    .trend-section-buttons {
      margin: 0;
    }

    .form-field .arrow-down {
      top: 1.5rem;
      @include transform(rotate(-45deg));
    }
    .sensor-status-popup.ia3 .sensor-status-popup-measurements-row {
      grid-template-columns: 4fr 3fr 3fr 3fr 3fr !important;
    }

    select {
      height: $buttons-height;
      color: $ittPortalGrey3;
    }

    #fft-harmonics {
      height: $buttons-height;
      border: 1px solid $ittPortalGreyDF;
      padding: 0 0.5rem;
      margin: 0;
      @include roundedcornersmixin(0.25rem);
      @include fontsize(0.875rem, 0.875rem, 400);
      position: relative;
    }

    #fft-3d-switch {
      grid-area: fft-3d-switch;

      .switch-container {
        height: auto;

        .btn-color-mode-switch-inner {
          padding: 1rem;
          border: 1px solid $ittPortalGreyDF;
          @include fontsize(1.125rem, normal, 700);
          width: 7.875rem;
          height: $buttons-height;

          &:after {
            width: 50%;
            height: 100%;
            top: 0;
            left: 0;
          }

          &:before {
            color: $ittPortalGrey9B;
          }
        }

        input[type="checkbox"] {
          &:checked {
            + label.btn-color-mode-switch-inner {
              &:after {
                left: 50%;
              }
            }
          }
        }
      }
    }

    #fft-harmonics {
      grid-area: fft-harmonics;
    }

    #edit-harmonics-page,
    #fft-fft-unit,
    #fFTVibrationUnitForm {
      .form-fields-container {
        min-height: calc(100vh - 7.5rem);
      }
    }
    #fft-fft-unit {
      grid-area: fft-fft-unit;

      #asset-train-page-fft-fft-unit-selector-slide-over-container {
        min-width: 40%;

        .form-button-container {
          padding: 0 2.5% 0.5rem 2.5%;
        }
      }
      select {
        color: $ittPortalGrey3;
      }
    }

    #fft-vibration-unit {
      grid-area: fft-vibration-unit;

      #asset-train-page-fft-section-vibration--selector-slide-over-container {
        min-width: 40%;

        .form-fields-container {
          /*background: white;
          padding-left: 0.563rem;
          padding-top: 2.5rem;
          padding-right: 0.875rem;*/

          .radio-label {
            margin-bottom: 2.5rem;
          }
        }

        .form-button-container {
          padding: 0 2.5% 0 2.5%;
        }
      }
    }

    #fft-filter-orientations {
      grid-area: fft-filter-orientations;
    }

    #fft-acceleration-switch.fft-twf-acceleration-view-switch {
      grid-area: fft-acceleration-switch;
      display: grid;
      justify-content: right;
    }
  }

  #asset-train-page-fft-list:not(:empty) {
    margin: 0.875rem 0 2.4375rem 1rem;
    border: 1px solid $ittPortalGreyDF;
    @include roundedcornersmixin(0.5rem);
    @media (max-width: $tablet-break-point) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

#route-page {
  #route-page-container {
    padding: 0;
  }
  #sensorsTab-form-sections-id {
    max-height: calc(100vh - 10rem);
    overflow-y: hidden;
    .grid .grid-data {
      height: calc(100vh - 19.5rem);
    }
  }
  #basicInfoTab-form-sections-id {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 100%;

    @media (max-width: $tablet-break-point) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }

    #form-section-basicInfoSection {
      .form-section-content {
        max-width: 380px;
        padding-right: 2rem;
        width: 100%;
        @media (max-width: $tablet-break-point) {
          max-width: none;
          padding-right: 1rem;
        }
      }
    }

    #form-section-reportRecipientsSection {
      height: fit-content;
      padding: 0 2.125rem 0 3.125rem;
      @include box-shadow(0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.08));
      @media (max-width: $tablet-break-point) {
        @include box-shadow(none);
        padding: 0 1rem 0 0;
      }

      .form-section-content {
        display: flex;
        padding-top: 0;

        .form-section-fields {
          @media (max-width: $tablet-break-point) {
            width: 100%;
          }
          @media (max-width: $mobile-break-point) {
            display: grid;
            grid-template-areas:
              "recipients-textarea"
              "recipients-list";
          }
          > div {
            padding-top: 1.5rem !important;
            @media (max-width: $mobile-break-point) {
              padding-top: 0 !important;
            }
          }
          .form-group__multiple-checkbox-and-text {
            @media (max-width: $mobile-break-point) {
              grid-area: recipients-list;
              width: 100%;
              max-height: 20rem;
              margin: 0;
              padding: 0;
              border: none;
            }
            + .form-group__multiple-checkbox-and-text {
              border: none;
              @media (max-width: $mobile-break-point) {
                grid-area: recipients-textarea;
                margin: 0 0 0.5rem 0;
                padding: 0;
                width: 100%;
              }
            }
            .checkbox-list {
              @media (max-width: $mobile-break-point) {
                overflow-y: auto;
              }
            }
          }
          .form-group {
            padding-top: 0;
          }
        }
      }
    }
  }
  #sensorsGrid-gridFiltersForm {
    .form-button-container {
      width: 100%;
      padding: 0 10% 0.5rem 10%;
      -webkit-box-shadow: none;
      box-shadow: none;
      button {
        white-space: nowrap;
      }
    }
  }
}

#confirm-dialog {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: $confirm-dialog-z-index;
  background-color: rgba(0, 0, 0, 0.32);
  color: $ittPortalGrey3;
  @include transition(left 0.2s ease-in 0s);

  &.active {
    left: 0;
  }

  .confirm-dialog-container {
    bottom: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    height: auto;
    width: 100%;
    overflow-y: auto;
    padding: 2.6875rem 1rem 1.5rem 1rem;
    text-align: center;
    @include transition(left 0.8s ease-in);

    .title {
      margin-bottom: 0.5rem;
      @include fontsize(1.5rem, normal, 700);
    }
    .message {
      margin-bottom: 2rem;
      @include fontsize(1.125rem, normal, 400);
      max-height: 35vh;
      overflow-y: auto;
    }
    .btn-container {
      display: grid;
      width: 100%;
      max-width: 360px;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.75rem;
      margin: 0 auto;

      .btn {
        width: 100%;
      }
    }
  }
}

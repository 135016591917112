#account-selector-container {
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: $account-selector-z-index;
  background-color: rgba(0, 0, 0, 0.4);
  max-height: 0;
  min-height: 0;
  top: $header-height-upper-part;
  @include transition(min-height 0.2s ease-in 0s);
  @media (max-width: $header-break-point) {
    top: $header-height-upper-part-mobile;
  }
  &.in-account-page {
    $in-account-margin: 2rem;
    $in-account-top: 16rem;
    $in-account-height: $in-account-top + 1rem;
    top: $in-account-top;
    height: calc(100vh - $in-account-height) !important;
    margin: 0 $in-account-margin;
    width: calc(100% - #{2 * $in-account-margin});
    &.active {
      min-height: calc(100vh - $in-account-height) !important;
    }
  }
  &.active {
    overflow-y: auto;
    max-height: none;
    /*min-height: calc(100vh - #{$header-height-upper-part});*/
    height: calc(100vh - #{$header-height-upper-part});
    @media (max-width: $header-break-point) {
      min-height: calc(100vh - #{$header-height-upper-part-mobile});
    }
    .account-selector-inner {
      padding: 2rem 2.5rem 2rem 2.5rem;
      overflow-y: auto;
    }
  }
  .account-selector-inner {
    padding: 0 2.5rem;
    background-color: #fff;
    overflow-y: hidden;
    @include transition(all 0.2s ease-in 0s);

    #account-selector-search {
      //grid-column: 1 / -1;
    }
    .account-selector-item-container {
      display: grid;
      grid-gap: 1.5rem;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
      .account-selector-item {
        cursor: pointer;
        border: 1px solid $ittPortalGreyDF;
        @include roundedcornersmixin(0.5rem);
        padding: 1rem;
        display: grid;
        grid-gap: 0.625rem;
        grid-auto-flow: column;
        align-items: center;
        justify-content: left;
        align-content: center;

        &.selected {
          background-color: $ittPortalGreenLight;
        }

        .account-selector-item-img-container {
          $picture-container-width: 3.375rem;
          $picture-width: 2.275rem;
          width: $picture-container-width;
          height: $picture-container-width;
          @include roundImage(
            $picture-container-width,
            calc($picture-container-width / 4),
            $ittPortalGreyDF,
            $ittPortalBlue
          );
          display: inline;
          position: relative;
          padding: #{calc($picture-container-width/2) - calc($picture-width/2)};
          margin: 0;

          img {
            @extend .img-responsive;
          }
        }

        .account-selector-item-title {
          font-weight: 700;
          font-size: 1.125rem;
          word-break: break-all;
        }

        .account-selector-item-email {
          font-size: 0.75rem;
        }

        .account-selector-item-fullname {
          font-size: 0.75rem;
        }
      }
    }
  }
  form {
    display: inline-block;
    width: 10rem;

    #searchAccountForm-first-step {
      .step-content {
        position: relative;
      }
    }
  }
  #account-selector-title {
    display: inline-block;
    font-size: 1.375rem;
    font-weight: 700;
    margin-right: 1rem;
  }
}

#inviteContent {
  padding-left: 2rem;

  &.editUserForm {
    #email-field-container {
      .form__message {
        display: none;
      }
    }
  }

  h1 {
    margin: 0 0 1.5rem 0;
    @include fontsize(1.5rem, normal, 700);
  }

  .invite-content {
    $breakpoint: 650px;
    display: grid;
    grid-gap: 0;
    height: calc(100vh - 12rem);
    overflow-y: auto;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: "left right";
    @media (max-width: $breakpoint) {
      grid-template-columns: 100%;
      grid-template-areas:
        "left"
        "right";
    }

    .invite-content-left {
      $form-gap: 1rem;
      grid-area: left;
      padding-right: 2rem;

      .form-fields-container,
      .form-group__radio {
        min-height: unset;
      }

      .form-button-container {
        position: absolute !important;
      }
      .form-group {
        margin-bottom: $form-gap;
      }
      .form-group__radio {
        p {
          margin-bottom: $form-gap;
          font-weight: 700;
        }
        label {
          padding-bottom: $form-gap;
          border-bottom: 1px solid $ittPortalGreyF7;
          + label {
            margin-top: $form-gap;
          }

          span {
            color: $ittPortalGrey3;
            font-size: 0.875rem;
          }
        }
      }
    }
    .invite-content-right {
      grid-area: right;
      border-left: 2px solid $ittPortalGreyF7;
      padding-left: 2rem;
      p {
        margin-bottom: 1.5rem;
        @include fontsize(1rem, normal, 700);
        span {
          display: block;
          @include fontsize(0.75rem, normal, 400);
        }
      }
    }
  }
}

.chart-buttons-container {
  margin: 2.5rem 1rem 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  grid-template-areas: "chart-left-right-arrows chart-zoom";
  @media (max-width: $tablet-break-point) {
    margin-left: 0;
    margin-right: 0;
  }

  .chart-bar-left-right-arrows,
  .chart-zoom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
  }

  .chart-bar-left-right-arrows {
    grid-area: chart-left-right-arrows;
    justify-self: left;

    button {
      padding: 0.625rem 0.825rem;

      .svg-inline--fa {
        padding: 0;
      }
    }
  }

  .chart-zoom {
    grid-area: chart-zoom;
    justify-self: right;
  }

  button {
    border: 1px solid $ittPortalGreyDF;
    @include roundedcornersmixin(0.25rem);
    background-color: transparent;
    padding: 0.625rem 0.5rem;
    font-size: 0.625rem;

    .svg-inline--fa {
      font-size: 0.875rem;
      padding-right: 0.25rem;
    }
  }
}

#asset-train-page {
  #asset-train-page-container {
    background-color: $ittPortalGreyF7;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    scroll-behavior: smooth;

    #asset-train-clone-form-title {
      margin-top: 0;
    }

    #analysis-card-add-container {
      #analysisCardAddIncidentDateFrom-field-container {
        label {
          font-size: 0.8rem;
          min-width: 90%;
        }

        #analysisCardAddIncidentDateFrom {
          line-height: 1.38rem;
          font-size: 0.8rem;
        }
      }
    }

    #numberOfCopies-field-container {
      min-height: calc(100vh - 10rem);

      label {
        margin-bottom: 0.8rem;
      }

      .radio-label {
        margin-bottom: 1rem;
      }
    }

    #asset-train-clone-slide-over-container {
      padding-top: 1rem;

      #assetTrainCloneForm {
        .form-fields-container {
          min-height: 0;
        }
        .form-button-container {
          padding: 0 0.5rem 0.4rem 0.5rem;
        }
      }
    }

    #asset-train-page-active-analysis-cards-slideover {
      &-container {
        padding: 0;
        #asset-train-page-active-analysis-cards-header {
          display: grid;
          grid-template-areas: "back-and-asset-name add-asset-card";
          align-items: center;
          padding: 2rem 1rem;
          justify-content: space-between;
          @include box-shadow(0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.08));

          @media (max-width: $tablet-break-point) {
            grid-template-areas:
              "back-and-asset-name"
              "add-asset-card";
          }

          .header-left {
            grid-area: back-and-asset-name;
            display: grid;
            grid-template-areas: "back-to-asset-train asset-name-title";
            align-items: center;
            gap: 6.75rem;

            @media (max-width: $mobile-break-point) {
              gap: 1rem;
            }

            #back-to-asset-train {
              grid-area: back-to-asset-train;
              top: auto;
              #back-to-asset-train-button {
                font-size: 0.875rem;
                position: relative;
                background-color: transparent;

                span {
                  margin-left: 0.35rem;
                }

                &:after {
                  content: "";
                  left: #{-1 * 0.35rem};
                  @include arrow($ittPortalGrey3, 0.25rem);
                  @include vertical-align(absolute);
                  @include transform(translateY(-50%) rotate(135deg));
                  padding: 0.38rem;
                }
              }
            }

            &-title {
              grid-area: asset-name-title;
              &-label {
                font-weight: normal;
                font-size: 0.875rem;
                margin-block: 0;
              }
              &-name {
                margin-block: 0;
              }
            }
          }

          &-add-analysis-card-btn {
            grid-area: add-asset-card;
            display: flex;
            padding: 0.75rem 2.25rem 0.813rem 1rem;
            align-items: center;
            gap: 1.25rem;
            background-color: $ittPortalGreenSuccess;
            font-size: 0.75rem;
            font-family: $font-family;
            margin-left: auto;
            margin-right: 0;

            svg {
              font-size: 1rem;
            }
          }

          @media (max-width: $tablet-break-point) {
            flex-direction: column;
            padding-block: 1rem;
            height: fit-content;
            gap: 1rem;
            margin-left: auto;
          }
        }
      }
    }

    .form .form-group .form-field select:disabled {
      background-color: transparent;
      cursor: pointer;
    }

    .asset-train-page-fft-item {
      display: grid;
      grid-template-areas:
        "fft-generic-info"
        "fft-diagnostics";
      grid-template-columns: 1fr;
      grid-gap: 0;
      border-bottom: 1px solid $ittPortalGreyDF;

      &:last-of-type {
        border-bottom: 0;
      }

      &.fft-item-header {
        border-bottom: 1px solid $ittPortalGrey3;
        @include fontsize(0.625rem, normal, 700);

        .asset-train-page-fft-item-generic-info > div {
          padding-top: 1.375rem;
          padding-bottom: 0.5rem;
        }

        .asset-train-page-fft-item-mark-as-baseline {
          font-weight: 400;
        }
      }

      .asset-train-page-fft-item-generic-info {
        grid-area: fft-generic-info;
        padding: 0;
        @include roundedcornersmixin(0.5rem);
        display: grid;
        grid-template-columns: 3.82% 9.54% 28.62% 16.31% 30.73% 10.98%;
        align-items: center;

        @media (max-width: $tablet-break-point) {
          grid-template-columns: 3.82% 9.54% 28.62% 21.31% 25.73% 10.98%;
        }

        & > div {
          height: 100%;
          align-content: center;
          border-left: 1px solid $ittPortalGreyDF;
          padding-left: 1rem;
          word-break: break-word;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;

          @media (max-width: 950px) {
            padding-left: 0.5rem;
          }
          @media (max-width: $mobile-break-point) {
            padding-left: 0.25rem;
          }

          &:first-of-type {
            border-left: 0;
            padding: 0;
            margin: auto;
          }
        }

        .fa-x {
          font-size: 1rem;
          cursor: pointer;
        }

        .fft-sensor-name {
          padding-right: 0.5rem;
          font-weight: 700;
        }

        .fft-sensor-details {
          padding-right: 0.5rem;
          font-size: 0.625rem;
          line-height: 1rem;
        }

        .fft-sensor-most-severe-recommendation {
          font-size: 0.75rem;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          padding: 0.8rem 0.6rem;
          white-space: nowrap;
          width: 100%;
          @include fontsize(0.75rem, 1rem, 700);
          @media (max-width: 1726px) {
            white-space: normal;
            font-size: 0.55rem;
          }

          span {
            word-break: break-all;
          }

          .svg-inline--fa:not(.fa-plus) {
            font-size: 1rem;
            margin-right: 0.5rem;
            cursor: pointer;
          }

          .btn-rerun-diagnostics {
            white-space: nowrap;

            @media (max-width: 1700px) {
              white-space: normal;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              max-height: fit-content;
            }
          }

          &-top-row {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            #btn-downloadDiagnostics-input-output-json {
              background-color: transparent;
            }

            @media (max-width: $tablet-break-point) {
              flex-direction: column;
            }
          }
        }
        .arrow-down-container {
          align-content: center;
          padding-right: 1rem;
          position: relative;
          overflow: hidden;

          .arrow-down {
            @include arrow(#000, 2px);
            @include transform(rotate(45deg));
            display: block;
            position: relative;
            margin: auto;
            height: 0.6rem;
            width: 0.6rem;
            z-index: 2;
            transition: all 0.2s;
            cursor: pointer;
          }

          &:after {
            content: "";
            position: absolute;
            width: 80%;
            top: 100%;
            height: 100%;
            background-color: $ittPortalGreyF7;
            @include transition(top 0.2s ease-in 0s);
            @include roundedcornersmixin(0.5rem);
          }

          &.open {
            &:after {
              top: 0.5rem;
            }
            .arrow-down {
              @include transform(rotate(-135deg));
            }
          }
        }
      }

      .asset-train-page-fft-item-diagnostics {
        grid-area: fft-diagnostics;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: start; //center;
        padding: 0 1rem;
        background-color: $ittPortalGreyF7;
        border-left: 1px solid $ittPortalGreyDF;
        max-height: 0;
        overflow-y: hidden;
        @include transition(all 0.5s ease-in 0s);

        @media (max-width: $mobile-break-point) {
          grid-template-columns: 1fr;
          grid-gap: 0.5rem 0;
        }

        &.open {
          padding: 1rem;
          max-height: 100rem;
        }

        .fft-sensor-diagnostics-recommendations,
        .fft-sensor-diagnostics-faults {
          font-size: 0.75rem;

          .fft-sensor-diagnostics-recommendations-total,
          .fft-sensor-diagnostics-faults-total {
            font-size: 0.8125rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
          }

          .fft-sensor-diagnostics-recommendations-list,
          .fft-sensor-diagnostics-faults-list {
            .fft-sensor-diagnostics-recommendation {
              margin-right: 0.375rem;
            }

            .fft-sensor-diagnostics-recommendation,
            .fft-sensor-diagnostics-fault {
              display: grid;
              grid-template-columns: 1fr 3fr;
              min-height: 4.625rem;
              align-items: center;
              align-content: center;
              margin-bottom: 0.75rem;
              background-color: #fff;
              @include roundedcornersmixin(0.5rem);

              &:last-of-type {
                margin-bottom: 0;
              }

              .fft-sensor-diagnostics-recommendation-status,
              .fft-sensor-diagnostics-fault-status {
                height: 100%;
                width: 100%;
                text-align: center;
                border-right: 1px solid $ittPortalGreyDF;

                .svg-inline--fa {
                  display: block;
                  font-size: 0.875rem;
                  margin: 0 auto 0.25rem auto;
                }

                & ~ div {
                  padding-left: 1.875rem;
                }
              }
            }
          }
        }
      }

      .asset-train-page-fft-item-mark-as-baseline {
        $checkbox-width: 1.5rem;
        padding-left: 1.125rem;
        padding-right: 0.875rem;
        grid-template-columns: calc(100% - #{$checkbox-width}) $checkbox-width;
        display: grid;
        grid-gap: 0.5rem;
        align-items: center;
        font-size: 0.625rem;

        .form-field {
          overflow: unset;
        }

        input[type="checkbox"] {
          + .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 1.25rem;
            width: 1.25rem;
            border: 2px solid $ittPortalGreyDF;
            @include roundedcornersmixin(3px);

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          &:hover ~ .checkmark {
            background-color: $ittPortalGreyC;
          }

          &:checked ~ .checkmark {
            background-color: $ittPortalGreen;
            border: 0;

            &:after {
              display: inline-block;
              left: 0.35rem;
              top: 0.05rem;
              width: 0.45rem;
              height: 0.9rem;
              border: solid white;
              border-width: 0 2px 2px 0;
              @include transform(rotate(45deg));
            }
          }
        }
      }
    }

    #asset-train-page-fft-section-selector-slide-over {
      .asset-train-page-fft-item {
        grid-template-areas: "fft-generic-info ...";
        grid-template-columns: 3fr 2fr;
        grid-gap: 0;
        align-items: start;
        border-bottom: 0;
        @media (max-width: $tablet-break-point) {
          grid-template-areas: "fft-generic-info";
          grid-template-columns: 1fr;
        }
        @media (max-width: $mobile-break-point) {
          grid-template-areas: "fft-generic-info";
          grid-template-columns: 1fr;
        }

        .asset-train-page-fft-item-generic-info {
          grid-template-columns: 19.48% 45.55% 38.86%;
          padding: 0.75rem 1rem;

          > div {
            padding: 0 0.5rem 0 0;
            border-left: 0;
          }
        }
      }
      .sensor-status-popup-measurements-row {
        grid-template-columns: 1fr 2fr 2fr 2fr 4fr !important;
        @media (max-width: $tablet-break-point) {
          grid-template-columns: 0fr 2fr 2fr 2fr 4fr !important;
        }
      }
    }

    .orientation-color {
      $orientation-color-size: 0.625rem;
      width: $orientation-color-size;
      height: $orientation-color-size;
      @include roundedcornersmixin($orientation-color-size);
      margin-right: 0.25rem;
      display: inline-block;
    }

    .asset-train-header-search {
      margin-top: 0.12rem;
      width: 26vw;
      max-height: 3rem;

      * {
        min-height: 0;
      }
    }

    #back-to-sensors {
      margin-left: 1.125rem;

      a {
        &::after {
          padding: 0.362rem;
          left: -0.5rem;
        }
      }
    }

    .asset-train-header-active-analysis-cards-button {
      right: 0;
      margin-right: 0.313rem;
      cursor: pointer;
      width: fit-content;
      height: 3rem;
      display: flex;
      gap: 0.313rem;
      font-size: 0.75rem;
      padding: 0.775rem 1.5rem 0.775rem 1rem;
      @include roundedcornersmixin(0.25rem);
      border: solid 0.063rem $ittPortalGreyDF;

      .element-with-tooltip {
        .element-tooltip.icon {
          position: relative;
          top: -1.4rem;
          left: 0;
          width: 1.35rem;
          height: 1.35rem;

          .i-alert-popup {
            white-space: pre-wrap;
            top: -0.9rem;

            @media (max-width: $mobile-break-point) {
              top: 1.4rem;
              left: -8rem;

              &:after {
                top: -0.3rem;
                left: 5.9rem;
                border-width: 1px 1px 0 0;
              }
            }
          }
        }
      }

      &-circle {
        margin-right: 0.313rem;
        font-weight: bold;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $ittPortalBlack;
        text-align: center;
        width: 1.438rem;
        height: 1.438rem;
        @include roundedcornersmixin(50%);

        &.expired {
          background-color: $ittPortalRedDark;
        }
        &.expiring_soon {
          background-color: $ittOrange;
        }
        &.expiring_later {
          background-color: $ittPortalGreen;
        }
        &.no_expiration {
          background-color: $ittPortalGrey9B;
        }
        &.no-asset-cards {
          border: 0.063rem solid $ittPortalGreyDF;
        }
      }

      &-text {
        display: flex;
        align-items: center;
        font-weight: bold;

        &:after {
          content: "";
          position: relative;
          height: 0.2rem;
          width: 0.2rem;
          @include arrow($ittPortalBlack, 0.1rem);
          transform: rotate(-45deg);
          margin-left: 0.3rem;
        }
      }

      @media (max-width: 1150px) {
        margin-left: auto;
      }
      @media (max-width: $tablet-break-point) {
        margin-top: 0.7rem;
      }
    }

    #asset-train-page-nav {
      display: flex;
      justify-content: space-between;
      padding: 0.45rem 0 0.64rem 0;
      align-items: center;
      position: relative;
      height: 4.813rem;
      white-space: nowrap;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      ul {
        display: flex;
        margin: 0 0 0 10.27rem;
        gap: 1rem;

        @media (max-width: 1150px) {
          flex-wrap: wrap;
          margin: 0 0 0 5rem;
        }

        @media (max-width: $tablet-break-point) {
          gap: 0.5rem;
        }
      }

      li {
        display: block;
        padding: 1rem 0.5rem 0.4rem 0.5rem;

        &.activeSection {
          font-weight: bold;
          border-bottom: 2px solid $ittPortalGrey3;
        }

        cursor: pointer;

        a {
          text-decoration: none;
          font-size: 0.875rem;
          color: $ittPortalGrey3;
        }
      }

      @media (max-width: 1150px) {
        display: block;
        height: fit-content;
      }
    }

    .asset-train-content {
      padding: 0.5rem 0 0 0;
      margin-top: 4rem;
      background-color: $ittPortalGreyF7;

      section {
        padding: 0;

        .slide-over-container {
          .form-group .form-field label {
            margin-bottom: 2rem;
          }
        }

        .asset-train-section-content {
          padding: 1rem;
          margin: 0 2.625rem 1.5rem 2.625rem;
          @include roundedcornersmixin(0.5rem);
          background-color: #fff;

          @media (max-width: $tablet-break-point) {
            margin: 0 1rem 1.5rem 1rem;
          }

          @media (max-width: $mobile-break-point) {
            margin: 0 0.5rem 1rem 0.5rem;
          }

          &:empty {
            margin: 0;
            padding: 0;
            background-color: transparent;
          }

          h2 {
            @include fontsize(1.5rem, 1.5rem, 700);
            margin: 1rem 0 0.5rem 1rem;

            @media (max-width: $tablet-break-point) {
              margin-left: 0;
            }
          }

          #fft-notes-btn,
          #twf-notes-btn {
            justify-self: end;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 6.5rem;
            background-color: black;
            color: #ffffff;
            padding: 0.625rem 0.5rem;
            font-size: 0.625rem;

            svg {
              font-size: 0.875rem;
            }
          }

          .fft-chart-container,
          .twf-chart-container {
            display: grid;
          }
        }
      }

      #asset-train-page-trend-section {
        .highcharts-axis.highcharts-yaxis {
          .highcharts-axis-title {
            min-width: 4.625rem;
            text-align: center;
          }
          .highcharts-axis-title.fade-out {
            opacity: 0.1;
          }
        }

        #trend-notes-bar-label {
          background-image: url("../../../assets/images/icon-edit.png");
          background-position: left center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          padding-left: 1rem;
        }

        .trends-chart-container,
        .fft-chart-container,
        .twf-chart-container {
          height: 35rem;
          width: 100%;
        }

        .trends-chart-container {
          .highcharts-legend-item {
            tspan {
              font-size: 0.6em;
            }
          }
          .highcharts-markers {
            image.highcharts-point:hover {
              filter: invert(100%);
              width: 2.438rem;
              height: 1.813rem;
            }
          }
        }

        #asset-train-page-trends-section-chart-buttons-container {
          margin: 0.5rem 0 0 0;
        }

        .asset-train-section-content {
          padding: 1rem 2rem;

          @media (max-width: $tablet-break-point) {
            padding: 1rem 1rem;
          }

          @media (max-width: $mobile-break-point) {
            padding: 1rem 0.5rem;
          }

          h2 {
            margin-left: 0;
          }
        }

        #asset-train-page-trend-selected-period {
          #asset-train-period-selector-slide-over {
            .slide-over-container {
              background-color: #ffffff;
              min-width: 15%;
              width: 24.938rem;
              padding-left: 0.563rem;
              grid-template-rows: unset;

              h2 {
                margin-bottom: 1.5rem;
              }

              #asset-train-trend-period-form-title {
                margin-top: 2.5rem;
              }

              .radio-label {
                display: none;
              }

              .form-fields-container {
                min-height: calc(100vh - 10.65rem);
              }

              .form-button-container {
                padding: 0 0.875rem 0.5rem 0;
              }
            }
          }
        }
      }

      #asset-train-page-sensors-section {
        .asset-train-section-content {
          background-color: transparent;
          padding-left: 0;
          padding-right: 0;

          h2 {
            padding: 0 0 0 0.75rem;
            margin: 0.625rem 0 1.5rem 0;
          }
        }
      }

      #asset-train-page-documents-section {
        .grid {
          //.grid-above-table {
          //  height: unset;
          //  .grid-data-found {
          //    display: none;
          //  }
          //}
          .action-btn {
            $action-btn-padding-left: 0.875rem;
            padding: 0.375rem $action-btn-padding-left;
            width: auto;
          }

          .column-content {
            padding-left: 1.425rem;
          }
          .column:first-of-type .column-content {
            @media (max-width: $tablet-break-point) {
              padding-left: 0;
            }
          }

          .asset-document-description-box {
            .edit-asset-document-description {
              padding: 0;
              display: flex;
              justify-content: center;

              svg {
                width: 0.563rem;
                height: 0.563rem;
                color: $ittPortalGrey3;
                align-self: center;
              }
            }
          }
        }

        .grid-buttons-applied-filters-container {
          //margin: 0.013rem 0 0 0;
        }

        .add-new-asset-document {
          height: 2.563rem;
          padding: 0.75rem 1.125rem 0.813rem 1rem;
          border-radius: 0.25rem;
          background-color: $ittPortalGreenSuccess;
          cursor: pointer;
          font-size: 0.75rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $ittPortalGrey3;
          display: inline-flex;
          justify-content: center;
          align-items: end;

          svg {
            width: 1rem;
            height: 1rem;
            margin: 0 0.5rem 0 0;
          }
        }

        .form {
          button#asset_document_description_cancel {
            width: 100%;
          }

          button#asset_document_description_submit {
            width: 100%;
          }

          .form-group {
            .form-field textarea {
              height: 21.063rem;
            }
          }
        }

        .slide-over {
          .slide-over-container {
            overflow: hidden;
            padding-left: 0.438rem;

            .form-button-container {
              padding: 0 0.438rem 0.5rem 0.438rem;
            }
          }

          #asset-document-add-slide-over-container {
            padding-left: 0.563rem;
            padding-top: 2.5rem;
            padding-right: 0.875rem;

            #assets-field-container {
              margin: 1.5rem 0 0 0;
            }
          }
        }

        .asset-document-description-edit {
          .slide-over-container {
            padding-top: 0;
            width: 24.938rem;
            min-width: unset;
            padding-right: 0.875rem;

            #document-form-title {
              padding-top: 2.457rem;
              padding-left: 0.563rem;
              margin-bottom: 0.5rem;
            }

            .form-field {
              padding-left: 0.563rem;
            }
          }
        }

        .asset-train-page-documents-header-container {
          display: flex;
          justify-content: space-between;
        }

        h2 {
          padding-top: 0.089rem;
          align-self: center;
        }

        #assetTrainDocumentsGrid {
          margin-top: 0.5rem;
        }

        .btn-rectangle {
          height: 2.563rem;
          width: 9.375rem;
          padding: 0.75rem 1.375rem 0.813rem 1rem;
          border-radius: 0.25rem;
          background-color: $ittPortalGreenSuccess;
          cursor: pointer;
          font-weight: 700;

          .bold-btn {
            width: 1rem;
            height: 1rem;
            margin: 0 0.5rem 0 0;
            object-fit: contain;
          }

          .btn-text {
            height: 1rem;
            font-size: 0.75rem;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-left: 0.75rem;
            color: $ittPortalGrey3;
          }
        }

        .asset-train-section-content {
          padding: 1.5rem 1.5rem 2rem 2rem;
          @media (max-width: $tablet-break-point) {
            padding-left: 1rem;
            padding-right: 1rem;
          }

          h2 {
            margin: 0.075rem 0 0 0;
          }

          .grid-above-table {
            .grid-buttons-applied-filters-container {
              @media (max-width: $tablet-break-point) {
                margin-left: 0;
              }
            }
          }

          .grid-data {
            padding: 1.664rem 1.625rem 0 0.625rem;
            height: auto;
            @media (max-width: $tablet-break-point) {
              padding-left: 0;
              padding-right: 0;
            }

            .grid-header-row {
              .column:last-child .column-content {
                padding-left: 2.113rem;
              }
            }

            .align-util {
              text-align: left;
              padding-left: 1.438rem;
              width: 4rem;
              display: flex;
              justify-content: space-between;

              button {
                width: 1rem;
                height: 1rem;
                background: transparent;
                padding: 0;
              }

              svg {
                width: 1rem;
                height: 1rem;
              }
            }
          }

          .asset-train-section-empty-content {
            background-color: transparent;
            height: 23.875rem;
            text-align: center;
            display: flex;
            justify-content: center;

            .empty-data-btn-container {
              margin-top: 1.875rem;
              display: flex;
              justify-content: center;
            }

            .empty-data-container {
              width: 15.625rem;
              height: 12.5rem;
              background-image: url("../../../assets/images/asset-train-documents/empty-data.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 10.438rem auto;
              display: flex;

              .empty-data-text {
                align-self: end;
                color: $ittPortalGrey9B;
                font-size: 1.5rem;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                margin-bottom: -0.9rem;
              }
            }
          }
        }

        #asset-document-add-slide-over-container {
          min-width: 40%;

          #document-add-form-title {
            padding-top: 0;
            padding-left: 0.438rem;
            margin-bottom: 1rem;
            margin-top: 0;
          }

          .form-fields-container {
            padding-left: 0.438rem;
            padding-right: 1.5rem;
          }

          .form-group.form-group__radio.add-document-asset-label {
            .form-field {
              .radio-label {
                height: 1.063rem;
                font-size: 0.875rem;
                font-weight: 700;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $ittPortalGrey3;
              }

              label {
                height: 3.313rem;
                width: 100%;
                padding: 1.063rem 2.063rem 0 0;
                border-bottom: 0.015rem solid lightgray;

                &:before {
                  margin-top: 1rem;
                  border: 0.125rem solid $ittPortalGreyDF;
                }

                input[type="radio"]:checked:after {
                  margin-top: 1rem;
                }

                span {
                  display: inline-block;
                  width: 100%;
                  height: 1.063rem;
                  font-size: 0.875rem;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: $ittPortalGrey3;
                }
              }
            }
          }

          .form-button-container {
            padding: 0 0 0.75rem 0.31rem;
          }
          .form-group .form-field label {
            margin-bottom: 0;
          }
        }
      }

      #asset-train-page-basic-section {
        background-color: #fff;
        padding: 0;
        margin: 0 0 1.5rem 0;

        .asset-train-page-basic-horizontal-container {
          display: grid;
          grid-template-areas:
            "asset-health svg diagnostics"
            "button button button";
          grid-template-columns: 1.9fr 3.1fr 0.8345fr;

          @media (max-width: 1100px) {
            grid-template-areas:
              "asset-health diagnostics"
              "svg svg"
              "button button";
            grid-template-columns: 2fr 1fr;
          }

          @media (max-width: $mobile-break-point) {
            grid-template-areas:
              "asset-health"
              "svg"
              "diagnostics"
              "button";
            grid-template-columns: 1fr;
          }
        }

        .asset-page-basic-left-container {
          grid-area: asset-health;
          margin: 1.375rem 0 0 2.625rem;

          .asset-page-title {
            font-size: 1.5rem;
            margin: 0 0 0.375rem 0;
            max-width: fit-content;
            display: inline-block;
          }

          .asset-train-gateway-access {
            display: inline-block;
            margin-right: 0.25rem;

            svg {
              font-size: 1.5rem;
            }
          }

          .asset-page-left-text {
            font-size: 0.75rem;
            text-transform: capitalize;
            margin: 0 0 0.125rem 0;
          }

          .asset-page-basic-left-health {
            border: solid 1px $ittPortalGreyDF;
            margin: 0.44rem 0 1.5rem 0;
            width: fit-content;

            .asset-page-basic-left-health-text {
              display: flex;
              justify-content: space-between;
              font-size: 0.625rem;

              #asset-page-basic-left-health-asset-health-text {
                margin-left: 1rem;
                font-weight: bold;
              }

              #asset-page-basic-left-health-see-graph-text {
                margin-right: 1rem;
                color: $ittBlueLight;
                font-weight: bold;
              }
            }

            .asset-page-basic-left-health-battery {
              display: flex;
              justify-content: space-evenly;
              font-size: 0.625rem;
              gap: 1rem;

              .asset-page-basic-left-health-battery-item-container {
                display: flex;
                flex-direction: column;

                &:first-child {
                  margin-left: 1rem;
                }

                &:last-child {
                  margin-right: 1rem;
                }

                span {
                  margin-left: 0.2rem;

                  &:first-of-type {
                    margin-left: 0;
                  }
                }

                .asset-page-basic-left-health-battery-item-bricks-container {
                  display: flex;
                  align-items: center;
                }

                .asset-page-basic-left-health-battery-days {
                  margin-bottom: 0.563rem;
                }
              }
            }
          }
        }

        .asset-page-basic-left-error-text {
          font-size: 0.75rem;
          margin-top: 0.5rem;
          margin-bottom: 1rem;

          p {
            margin: 0;
          }
        }

        .asset-page-basic-center-container {
          grid-area: svg;
          align-self: center;
          position: relative;

          .asset-page-basic-center-other-sensors-container {
            width: 9rem;
            height: 5rem;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $ittPortalGreyF7;
            margin-right: 1.5rem;
            margin-bottom: 1.5rem;
            border-radius: 0.25rem;

            .asset-page-basic-center-other-sensors-list-container {
              margin-left: 0.5rem;
              margin-top: 0.5rem;

              .asset-page-basic-center-other-sensors-title {
                font-size: 0.75rem;
                font-weight: bold;
                margin-bottom: 0.25rem;
              }

              .asset-page-basic-center-other-sensors-list {
                padding: 0;
              }
            }

            .asset-page-basic-center-other-sensors-name {
              display: flex;
              font-weight: normal;
              font-size: 0.625rem;
              color: $ittBlueLight;
              width: auto;

              .delete-sensor-btn-popup {
                margin-left: 0.75rem;
              }
            }

            .asset-label-sensor-status-container {
              align-self: center;

              .asset-label-sensor-status-dot {
                height: 0.375rem;
                width: 0.375rem;
                border-radius: 50%;
                margin-right: 0.9px;
                margin-left: 0.6px;
              }
            }

            .delete-sensor-btn {
              background: none;
              color: black;
              border: none;
              padding: 0;
              cursor: pointer;
              outline: inherit;
              font-size: 0.625rem;
            }

            .show-more-sensors-btn {
              background: none;
              border: none;
              padding: 0;
              cursor: pointer;
              font-size: 0.625rem;
              color: $ittBlueLight;
              font-weight: normal;
              margin-left: 0.5rem;
            }

            .popup-overlay {
              box-shadow: 0 2px 0.5rem 0 rgba(0, 0, 0, 0.08);
              padding: 0;
              display: flex;
              flex-direction: column;

              &::after {
                height: 0;
                width: 0;
              }

              & > div {
                padding-bottom: 0.25rem;
              }

              &:last-child {
                padding-bottom: 0.75rem;
              }

              .close-popup-btn {
                align-self: end;
                margin: 0.67rem 0.75rem 0 0;
              }
            }
          }
        }

        .asset-page-basic-right-container {
          grid-area: diagnostics;
          font-size: 0.75rem;
          box-shadow: -2px -4px 4px 0 rgba(0, 0, 0, 0.08);
          padding: 0 0.5rem;

          .asset-page-basic-right-diagnostic-container {
            //max-height: 21rem;
            overflow-y: auto;

            .btn-rerun-diagnostics {
              padding: 0.3rem;
              height: fit-content;
              margin-left: auto;
            }
          }

          .asset-page-basic-right-diagnostic {
            display: flex;
            border-bottom: 1px solid $ittPortalGreyF7;
            margin-top: 0.438rem;
            cursor: pointer;

            &:first-of-type {
              #asset-page-basic-right-diagnostic-date::after {
                font-weight: bold;
                content: "\00a0 \2022 \00a0 Latest \00a0";
              }
            }

            &:last-of-type {
              border: none;
            }

            .asset-page-basic-right-diagnostic-icon {
              margin: 0 0.5rem 0 0.5rem;
              font-size: 1.5rem;
            }

            .asset-page-basic-right-diagnostic-priority {
              font-weight: bold;
              margin-bottom: 0.125rem;
            }

            .asset-page-basic-right-diagnostic-date {
              color: $ittPortalGrey9B;
              margin-bottom: 0.5rem;
            }

            p {
              font-size: 0.625rem;
            }
          }

          #asset-page-basic-right-title {
            font-weight: bold;
            margin: 1rem 0 1.5rem 1rem;
          }

          p {
            margin: 0;
          }
        }

        .asset-page-basic-buttons-row-container {
          grid-area: button;
          text-align: center;
          border-top: 1px solid $ittPortalGreyF7;
          padding: 0.438rem 0 0 0;
          margin-right: 3.21rem;

          .asset-page-basic-buttons-row-button-text {
            margin-left: 0.25rem;
            position: relative;
            bottom: 2.1px;
            padding: 0.5px 0;
          }

          button {
            background-color: #fff;
            border: solid 0.063rem $ittPortalGreyDF;
            border-radius: 0.25rem;
            padding: 0.645rem 0.438rem;
            margin: 0 0.25rem 0.5rem 0.25rem;
            font-size: 0.625rem;
            font-weight: bold;
            cursor: pointer;
            max-height: 2.25rem;

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              margin-right: 0;
            }

            svg {
              font-size: 0.875rem;
            }
          }
        }

        #schedule-asset-train-diagnostics-container {
          max-width: max(57%, $tablet-break-point);
          padding: 0;
          display: grid;
          grid-template-areas: "schedule-diagnostics-form schedule-diagnostics-list";
          grid-template-columns: 45.35% 54.65%;
          min-height: calc(100vh - 8.0875rem);
          @media (max-width: $tablet-break-point) {
            max-width: 90%;
            grid-template-areas: "schedule-diagnostics-list schedule-diagnostics-form";
          }

          @media (max-width: $mobile-break-point) {
            grid-template-columns: 1fr;
            grid-template-areas:
              "schedule-diagnostics-list"
              "schedule-diagnostics-form";
          }

          #schedule-asset-train-diagnostics-left-section {
            grid-area: schedule-diagnostics-form;
            border-right: 1px solid #d8d8d8;

            @media (max-width: $mobile-break-point) {
              border-right: 0;
            }
          }

          #schedule-asset-train-diagnostics-right-section {
            grid-area: schedule-diagnostics-list;

            ul.scheduled-diagnostics-list {
              list-style-type: none;
              margin: 0;
              padding: 0;

              li.scheduled-diagnostic-single {
                border-bottom: 1px solid #f7f7f7;
                padding: 0 0 1rem 0;
                position: relative;

                .schedule-diagnostic-field {
                  margin: 0.125rem 0 0.125rem 0;
                  @include fontsize(0.75rem, normal, 400);
                }

                .schedule-diagnostic-field-frequency {
                  margin: 0.475rem 0 0 0;
                  height: 0.75rem;
                  font-size: 0.625rem;
                  color: black;
                  font-weight: bold;
                  text-transform: capitalize;
                }

                .delete-schedule-diagnostic {
                  cursor: pointer;
                  position: absolute;
                  right: 0;
                  bottom: 3.188rem;
                }
              }
            }
          }

          .schedule-asset-train-diagnostics-section {
            padding: 2.5rem 1.781rem 0 1.781rem;
          }

          .schedule-asset-train-diagnostics-section-header {
            padding: 0;
            margin: 0 0 0.5rem 0;
            text-align: left;
            .form-field-tooltip {
              margin-left: 1rem;
              font-size: 1rem;
              position: relative;
              font-weight: 400;
              cursor: pointer;
              .i-alert-popup {
                right: calc(100% + 0.7rem);
                @include fontsize(0.75rem, normal, 400);
              }
            }
          }

          .schedule-asset-train-diagnostics-description {
            text-align: justify;
            @include fontsize(0.75rem, normal, 400);
          }

          .schedule-asset-train-diagnostics-form-container {
            padding-right: 0.5rem;

            .form-fields-container {
              min-height: calc(100vh - 13rem);
            }
          }
        }

        .asset-page-basic-buttons-row-outer-container {
          .form-button-container {
            padding: 0 0 0.438rem 0;
          }
        }
      }

      #asset-train-page-bom-section {
        .asset-train-section-content {
          /*padding: 1rem;
          @include roundedcornersmixin(0.5rem);
          margin-top: 0.5rem;
          background-color: #fff;
          .title {
            padding: 1rem 1rem 0 1rem;
            margin-bottom: 0.625rem;
          }*/

          #assetTrainPageBomGrid {
            margin-top: 0;

            .grid-above-table {
              //display: none;
            }

            .grid-data {
              padding: 0 2rem 0 2.6rem;
              @media (max-width: $tablet-break-point) {
                padding: 0;
              }

              .grid-header-row {
                .column {
                  border: 0;
                  border-right: 1px solid $ittPortalGreyF7;

                  &:nth-child(3) {
                    border: 0;
                  }
                }
              }

              .grid-data-row {
                .column {
                  border: 0;
                  border-right: 1px solid $ittPortalGreyF7;

                  &:nth-child(3) {
                    border: 0;
                  }
                }
              }
            }
          }
        }
      }

      #asset-train-page-curve-section {
        .asset-train-section-content {
          /*padding: 1rem;
          @include roundedcornersmixin(0.5rem);
          margin-top: 0.5rem;
          background-color: #fff;
          .title {
            padding: 1rem 1rem 0 1rem;
          }*/
          .curve {
            padding: 1.75rem;
            margin-bottom: 0.75rem;

            .img-responsive {
              width: 70%;
              margin: auto;

              @media (max-width: $tablet-break-point) {
                width: 100%;
              }
            }
          }
        }
      }

      #asset-train-page-fft-section {
        .asset-train-page-trend-section-buttons .form-field {
          min-width: 8rem;
        }
        .asset-train-page-trend-selected-sensors {
          margin-left: 1rem;
          @media (max-width: $tablet-break-point) {
            margin-left: 0;
          }

          .asset-train-page-trend-selected-sensor {
            padding: 0 0 0 0.825rem;
            &-name {
              padding-right: 0.25rem;
            }
          }

          .add-sensor {
            margin-left: 0.5rem;
          }
          .fft-orientation-list-normal-operation {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            margin-left: 1rem;
            font-size: 0.825rem;

            .asset-train-page-fft-item-mark-as-baseline {
              margin-left: 0.25rem;
            }
          }
        }

        .fft-twf-acceleration-view-switch {
          .switch-container {
            height: auto;

            .btn-color-mode-switch-inner {
              $font-size: 0.875rem;
              padding: 0;
              border: 0;
              height: 3.4375rem;
              @include fontsize($font-size, normal, 400);

              &:before,
              &:after {
                width: 50%;
                height: $font-size;
                background-color: transparent;
                color: $ittPortalGrey3;
                top: #{calc(50% - $font-size/2)};
                text-underline-offset: 0.5rem;
              }

              &:before {
                content: attr(data-off);
                font-weight: 400;
                text-decoration: none;
                left: 0;
              }

              &:after {
                content: attr(data-on);
                font-weight: 700;
                text-decoration: underline;
                left: 50%;
              }
            }

            input[type="checkbox"] {
              &:checked {
                + label.btn-color-mode-switch-inner {
                  &:after {
                    content: attr(data-off);
                    font-weight: 700;
                    text-decoration: underline;
                    left: 0;
                  }

                  &:before {
                    content: attr(data-on);
                    font-weight: 400;
                    text-decoration: none;
                    left: 50%;
                  }
                }
              }
            }
          }
        }
        #twf-acceleration-switch {
          .btn-color-mode-switch-inner {
            height: 1.4375rem;
          }
        }

        #asset-train-page-fft-section-buttons {
          $buttons-height: 3.4375rem;
          display: grid;
          grid-gap: 0 1rem;
          grid-template-columns: 0.1fr 0.6fr 0.4fr 0.4fr 1fr 1fr;
          grid-template-areas: "fft-3d-switch fft-harmonics fft-fft-unit fft-vibration-unit fft-filter-orientations fft-acceleration-switch";
          margin: 0 1rem;
          @media (max-width: 1300px) {
            grid-template-columns: 1fr 2fr 1fr 1fr;
            grid-template-areas:
              "fft-3d-switch fft-harmonics fft-fft-unit fft-vibration-unit fft-filter-orientations"
              "... ... ... ... fft-acceleration-switch";
          }
          @media (max-width: 1110px) {
            grid-template-columns: 0.5fr 1fr 1fr;
            grid-template-areas:
              "fft-3d-switch fft-harmonics fft-fft-unit"
              "fft-vibration-unit fft-filter-orientations fft-acceleration-switch";
          }
          @media (max-width: 820px) {
            grid-template-columns: 0.5fr 1fr;
            grid-template-areas:
              "fft-3d-switch fft-harmonics"
              "fft-fft-unit fft-vibration-unit"
              "fft-filter-orientations fft-acceleration-switch";
          }
          @media (max-width: $tablet-break-point) {
            grid-template-columns: 0.5fr 1fr;
            margin: 0;
          }
          @media (max-width: 520px) {
            grid-template-columns: 1fr;
            grid-template-areas:
              "fft-3d-switch"
              "fft-harmonics"
              "fft-fft-unit"
              "fft-vibration-unit"
              "fft-filter-orientations"
              "fft-acceleration-switch";
          }

          .asset-train-page-trend-section-buttons {
            margin: 0;
          }

          .form-field .arrow-down {
            top: 1.5rem;
            @include transform(rotate(-45deg));
          }

          select {
            height: $buttons-height;
            color: $ittPortalGrey3;
          }

          #fft-harmonics {
            height: $buttons-height;
            border: 1px solid $ittPortalGreyDF;
            padding: 0 0.5rem;
            margin: 0;
            @include roundedcornersmixin(0.25rem);
            @include fontsize(0.875rem, 0.875rem, 400);
            position: relative;
          }

          #fft-3d-switch {
            grid-area: fft-3d-switch;

            .switch-container {
              height: auto;

              .btn-color-mode-switch-inner {
                padding: 1rem;
                border: 1px solid $ittPortalGreyDF;
                @include fontsize(1.125rem, normal, 700);
                width: 7.875rem;
                height: $buttons-height;

                &:after {
                  width: 50%;
                  height: 100%;
                  top: 0;
                  left: 0;
                }

                &:before {
                  color: $ittPortalGrey9B;
                }
              }

              input[type="checkbox"] {
                &:checked {
                  + label.btn-color-mode-switch-inner {
                    &:after {
                      left: 50%;
                    }
                  }
                }
              }
            }
          }

          #fft-harmonics {
            grid-area: fft-harmonics;
          }

          #fft-fft-unit {
            grid-area: fft-fft-unit;

            #asset-train-page-fft-fft-unit-selector-slide-over-container {
              min-width: 40%;

              .form-fields-container {
                min-height: calc(100vh - 7.5rem);
              }

              .form-button-container {
                padding: 0 2.5% 0.5rem 2.5%;
              }
            }
          }

          #fft-vibration-unit {
            grid-area: fft-vibration-unit;

            #asset-train-page-fft-section-vibration--selector-slide-over-container {
              min-width: 40%;

              .form-fields-container {
                background: white;
                padding-left: 0.563rem;
                padding-top: 2.5rem;
                padding-right: 0.875rem;

                .radio-label {
                  margin-bottom: 2.5rem;
                }
              }

              .form-button-container {
                padding: 0 2.5% 0 2.5%;
              }
            }
          }

          #fft-filter-orientations {
            grid-area: fft-filter-orientations;
          }

          #fft-acceleration-switch.fft-twf-acceleration-view-switch {
            grid-area: fft-acceleration-switch;
            display: grid;
            justify-content: right;
          }
        }

        #asset-train-page-fft-list:not(:empty) {
          margin: 0.875rem 0 2.4375rem 1rem;
          border: 1px solid $ittPortalGreyDF;
          @include roundedcornersmixin(0.5rem);
          @media (max-width: $tablet-break-point) {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      .asset-train-empty-box {
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $ittPortalGrey9B;
        @include fontsize(1.5rem, normal, 700);
      }

      .sensor-status-popup {
        padding: 1rem;
        @include roundedcornersmixin(0.5rem);
        margin-bottom: 0.5rem;
        background-color: #fff;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      #asset-train-report-page {
        #form-section-assetTrainReportSection {
          .form-section-content {
            .form-section-title {
              padding: 0 1rem;
              font-size: 1.5rem;
            }

            .form-section-fields {
              #includeCsv-field-container {
                padding: 1rem;
              }

              .form-group__multiple-checkbox-and-text {
                margin: 0;
                padding: 1rem 0 1rem 1rem;

                .checkbox-list {
                  max-height: 500px;
                  overflow-y: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

.asset-train-add-comments {
  .slide-over-container {
    width: 25rem;

    form {
      .form-fields-container {
        min-height: calc(100vh - 8.6875rem);
      }
    }
  }
}

#vibration-analysis-page {
  #vibration-analysis-page-container {
    background-color: $ittPortalGreyF7;
    padding: 0;
    height: 100%;

    #vibration-analysis-content {
      padding: 0;
      height: 100%;
      position: relative;

      form {
        overflow-y: auto;
      }

      .form-sections-container {
        background-color: #fff;
        margin: 3.325rem 2rem 2.625rem 2rem;
        position: relative;
      }
      .form-group .form-field {
        input[type="text"],
        textarea {
          &:disabled {
            background-color: rgba(255, 255, 255, 0.3);
            color: $ittPortalGrey9B;
          }
        }
      }

      section {
        .form-section-title {
          margin-bottom: 1rem;
          font-size: 1.125rem;
          line-height: 1.125rem;
          padding-top: 2rem;
        }
        .form-section-content {
          padding-top: 0;
        }
        .img-container {
          @media (max-width: $tablet-break-point) {
            margin-top: 1rem;
          }
          @media (max-width: $mobile-break-point) {
            margin-top: 0;
          }
        }
      }
    }
  }
  .vibration-config-field-container {
    @include slideOverOneThirdFormColumn();
  }
}

.path {
  fill: $ittPortalGrey9B;
}

.asset-label-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  align-content: end;
  .asset-page-basic-center-other-sensors-name {
    display: flex;
    font-weight: normal;
    font-size: 0.625rem;
    color: $ittBlueLight;
    width: auto;
    justify-content: center;
    .asset-label-sensor-name {
      display: flex;
      align-items: center;
    }
    .asset-label-sensor-status-container {
      align-self: center;
      .asset-label-sensor-status-dot {
        height: 0.375rem;
        width: 0.375rem;
        border-radius: 50%;
        margin-right: 0.9px;
        margin-left: 0.6px;
      }
    }
  }
  .sensor-title {
    font-size: 0.75rem;
    font-weight: bold;
    color: black;
    text-align: center;
  }
  .asset-label-text {
    font-weight: normal;
    font-size: 0.625rem;
    color: $ittBlueLight;
    width: auto;
  }
  button {
    font-weight: normal;
  }
}

#asset-label-container-side {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  align-content: flex-start;
}

.asset-label-container-bottom {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  align-content: start;
  .asset-page-basic-center-other-sensors-name {
    display: flex;
    font-weight: normal;
    font-size: 0.625rem;
    color: $ittBlueLight;
    width: auto;
    justify-content: center;
    .asset-label-sensor-name {
      display: flex;
      align-items: center;
    }
    .asset-label-sensor-status-container {
      align-self: center;
      .asset-label-sensor-status-dot {
        height: 0.375rem;
        width: 0.375rem;
        border-radius: 50%;
        margin-right: 0.9px;
        margin-left: 0.6px;
      }
    }
  }
  .sensor-title {
    font-size: 0.75rem;
    font-weight: bold;
    color: black;
    text-align: center;
  }
  .asset-label-text {
    font-weight: normal;
    font-size: 0.625rem;
    color: $ittBlueLight;
    width: auto;
  }
  button {
    font-weight: normal;
  }
}

.delete-sensor-btn,
.move-sensor-btn {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.move-sensor-btn {
  &::after {
    margin-left: 1px;
    content: ">";
  }
}

.delete-sensor-btn {
  margin-right: 0.188rem;
}

.move-sensor-btn {
  color: $ittPortalRedDark;
  margin-left: 0.188rem;
}

foreignObject p {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  align-content: end;
}

.svg-text {
  .svg-text-title {
    font-weight: bold;
    @include ellipsis();
  }
  div {
    color: white;
    font-size: 0.625rem;
    text-align: center;
    padding: 0.188rem 0;
  }
}

.asset-page-basic-svg-add-sensor-btn {
  width: 5rem;
  height: 1.5rem;
  font-size: 0.75rem;
  padding: 0;
  position: relative;
  top: 50%;
}
button.asset-page-basic-svg-add-sensor-btn {
  font-size: 0.75rem;
}
button.asset-page-basic-svg-add-sensor-btn.filled {
  font-size: 0.45rem;
}

#asset-page-basic-svg-add-sensor-btn-low {
  top: 0;
}

.asset-details-popup {
  position: static;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 10.688rem;
  height: fit-content;
  min-width: auto;
  &::after {
    height: 0;
    width: 0;
  }
}

.close-popup-btn {
  align-self: end;
  margin: 0.125rem 0.25rem 0 0;
}
.asset-popup-content-container {
  padding: 0 0.5rem;
  .asset-popup-image-container {
    text-align: center;
    max-height: 6.5rem;
    max-width: 100%;
    margin: auto;

    .asset-popup-image {
      margin: 0 auto 0.75rem auto;
      @extend .img-responsive;
      max-height: 6.5rem;
    }

    .asset-popup-image-text {
      margin-bottom: 1.313rem;
    }
  }

  .asset-popup-type,
  .asset-popup-name,
  .asset-popup-title {
    height: fit-content;
    width: fit-content;
    font-size: 0.625rem;
  }

  .asset-popup-image-text,
  .asset-popup-title {
    font-weight: bold;
    font-size: 0.75rem;
  }

  .asset-popup-title {
    margin-bottom: 0.5rem;
  }

  .asset-popup-type {
    margin-bottom: 0.25rem;
  }
}
.asset-popup-button-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  button {
    background-color: #fff;
    padding: 0.5rem;
    border-top: 1px solid rgb(247, 247, 247);
    width: inherit;
    cursor: pointer;
    font-size: 0.75rem;
    margin: 0 0.5rem 0 0.5rem;
  }
  .popup-edit-button-text {
    margin-left: 0.375rem;
  }
}

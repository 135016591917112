.authentication {
  $authentication-first-break-point: 930px;
  $authentication-second-break-point: 630px;
  $border-padding: 1.85rem;

  display: grid;
  grid-gap: 0;
  min-height: 100vh;
  overflow-y: auto;
  grid-template-columns: 52.85% 47.15%;
  grid-template-rows: auto;
  grid-template-areas: "sidebar content";
  background-color: $ittPortalGreyF7;
  @media (max-width: $authentication-second-break-point) {
    grid-template-columns: 100%;
    grid-template-areas:
      "content"
      "sidebar";
  }
  .btn {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);
  }
  .content {
    grid-area: content;
    position: relative;
    min-height: 100vh;
    display: grid;
    align-items: center;
    justify-items: center;
    z-index: 2;
    background-color: #fff;

    @media (max-width: $authentication-second-break-point) {
      border-right: none;
      padding: 5.25rem 0 0 0;
      min-height: auto;
      height: fit-content;
    }

    #content-main {
      width: 100%;
      align-items: center;
      justify-items: center;
    }

    h1 {
      color: $ittPortalGrey3;
      padding: 0;
      display: block;
      font-size: 1.5rem;
      width: fit-content;
      margin: 0 auto;
    }
    .subtitle {
      color: #000;
      font-size: 0.875rem;
      text-align: center;
      margin: 0 auto 1rem auto;
      a {
        color: $ittBlueLight;
        text-decoration: none;
      }
    }
    .below-btn-link {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      font-weight: 700;
      box-shadow: 0 -0.125rem 0.25rem 0 rgba(0, 0, 0, 0.08);
      padding: 2rem 1rem;
      text-align: center;
      @media (max-width: $authentication-second-break-point) {
        position: relative;
        width: 100%;
      }
      a {
        color: $ittBlueLight;
      }
    }
    .authentication-success,
    .form-container {
      padding: 0 2rem;
      max-width: 468px;
      width: 100%;
      @media (max-height: 500px) {
        padding: 1rem 2rem 0;
      }
    }
    .authentication-success {
      text-align: center;
      .authentication-success-title {
        color: $ittPortalGrey3;
        margin-top: 1.5rem;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: normal;
      }
      .authentication-success-subtitle {
        color: $ittPortalGrey3;
        margin-top: 0.5rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: normal;
      }
    }
    .form-group {
      input {
        border: none;
        border-bottom: 1px solid #000000;
        height: 1.5rem;
        background-color: transparent;
        font-size: 0.9rem;
        &[type="checkbox"] {
          height: 1rem;
          & + span {
            line-height: 1.3rem;
          }
        }
      }
      button[type="submit"] {
        width: 100%;
        padding: 1rem 0;
        margin: 1rem auto 1rem auto;
        font-size: 1rem;
        &:focus {
          outline: none;
          outline-offset: 0;
          border-color: $ittBlue;
        }
      }
    }
    .authentication-button-container {
      position: relative;
      width: 100%;
      padding: $border-padding 0;
      border-top: 1px solid $ittPortalGrey;
      text-align: center;
      .in-line-text {
        position: absolute;
        max-width: 210px;
        width: 100%;
        padding: 0.625rem;
        top: -1.375rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #ffffff;
      }
      .authentication-button {
        width: 100%;
        max-width: 150px;
        margin: 0.625rem auto;
        padding: 0.313rem;
        display: block;
        color: $ittPortalGrey3;
        background-color: #e6e6e6;
        cursor: pointer;
        border: 1px solid $ittPortalGreyA;
        @include transition(all 0.3s ease-in);
        @include roundedcornersmixin(0.313rem);
        &:hover {
          text-decoration: none;
          @include box-shadow(0 0.313rem 0.938rem rgba(0, 0, 0, 0.5));
        }
      }
    }
    .success-circle {
      $success-circle-width: 188px;
      $black-ring-width: 8px;
      $green-circle: 176px;

      width: $success-circle-width;
      height: $success-circle-width;
      position: relative;
      margin: 0 auto;
      .success-circle-ring {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 50%;
        border: $black-ring-width solid #000;
        z-index: 3;
      }
      .success-circle-checkmark {
        width: $green-circle;
        height: $green-circle;
        background-color: $ittPortalGreenSuccess;
        position: absolute;
        z-index: 2;
        border-radius: 50%;
        left: #{$black-ring-width * 2};
        bottom: #{$black-ring-width * 2};
        &:before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          z-index: 5;
          top: 68px;
          left: 20px;
          @include transform(rotate(135deg));
          background-color: $ittPortalGreenSuccess;
        }
        &:after {
          content: "";
          position: absolute;
          left: calc($green-circle/4);
          top: calc($green-circle/5);
          width: calc(4 * $green-circle/10);
          height: calc(4 * $green-circle/10);
          border: solid white;
          border-width: 0 $black-ring-width $black-ring-width 0;
          @include transform(rotate(45deg));
          z-index: 4;
        }
      }
    }
  }

  .sidebar {
    grid-area: sidebar;
    display: grid;
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-align: center;
    justify-self: center;
    align-content: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;

    @media (max-width: $authentication-second-break-point) {
      min-height: auto;
      height: fit-content;
    }
    .oval {
      $oval-width: 100%;
      $oval-width-small: 120%;
      $oval-left: -30%;
      $oval-inner-width: 70%;
      width: $oval-width;
      padding-bottom: $oval-width;
      height: 0;
      background-color: #fff;
      position: absolute;
      left: $oval-left;
      bottom: calc($oval-left/2);
      border-radius: 50%;
      @media (max-width: $authentication-second-break-point) {
        width: $oval-width-small;
        padding-bottom: $oval-width-small;
      }
      &:after {
        content: "";
        width: $oval-inner-width;
        padding-bottom: $oval-inner-width;
        height: 0;
        background-color: $ittPortalGreyF7;
        position: absolute;
        left: calc((100% - $oval-inner-width) / 2);
        top: calc((100% - $oval-inner-width) / 2);
        border-radius: 50%;
      }
      &:before {
        content: "";
        width: calc($oval-width/2);
        padding-bottom: calc($oval-width/2);
        height: 0;
        background-color: $ittPortalGreyF7;
        position: absolute;
        left: 0;
        bottom: 0;
        @media (max-width: $authentication-second-break-point) {
          width: calc($oval-width-small/2);
          padding-bottom: calc($oval-width-small/2);
        }
      }
    }
    .sidebar-inner {
      max-width: 600px;
      padding: 0 2rem;
      position: relative;
      z-index: 2;
      @media (max-width: $authentication-second-break-point) {
        padding: 1.25rem 1.25rem 2.5rem;
      }
    }
    p {
      margin: 0 0 0.5rem;
      line-height: normal;
      a {
        color: $ittBlueLight;
        text-decoration: none;
      }
    }
    .i-alert-pc {
      margin: auto;
      max-width: 90%;
    }
    h2 {
      font-size: 1.5rem;
      margin: 2rem auto 0.5rem;
      color: $ittPortalGrey3;
      font-weight: 700;
    }

    .more-info {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .browser-support {
      @media (max-width: $mobile-break-point) {
        margin-bottom: 1rem;
      }
      .browser-support-container {
        display: flex;
        justify-content: center;
        .item {
          color: $ittPortalGrey3;
          &:not(:last-of-type) {
            margin-right: 1rem;
          }
          img {
            margin: 0 auto;
            display: block;
          }
        }
      }
    }
  }
}

#main-menu,
.form-tabs-menu,
.form-sections-menu {
  text-align: left;
  padding-right: 0.3125rem;

  ul.main-menu-ul,
  ul.form-tabs-menu-ul,
  ul.form-sections-menu-ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: auto;

    li {
      padding: 0;
      display: inline-block;
      list-style: none;

      a {
        position: relative;
        margin-right: 1.5rem;
        color: $ittPortalGrey3;
        @include fontsize(0.875rem, normal, 400);
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          height: 2px;
          bottom: -0.5rem;
          left: 0;
          background-color: $ittPortalGrey3;
          width: 0;
          @include transition(width 0.25s ease-in);
        }
        &.active,
        &:hover {
          font-weight: 700;
          &:after {
            width: 100%;
          }
        }
        &:before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
        &.error {
          color: $ittPortalRedDark;
          &:after {
            background-color: $ittPortalRedDark;
          }
        }
      }
    }
  }
  ul.main-menu-ul {
    display: flex;
    li.go-back {
      margin-left: auto;
      a:after {
        border: solid rgb(51, 51, 51);
        border-width: 0 0.26rem 0.26rem 0;
        display: inline-block;
        padding: 0.26rem;
        cursor: pointer;
        transform: translateY(-50%) rotate(135deg);
        position: absolute;
        top: 50%;
        content: "";
        left: -1rem;
        width: auto;
        background: transparent;
      }
    }
  }
}

@mixin profile-section-padding-bg-color {
  background-color: #fff;
  padding: 2.5rem 2rem;
}

#profile-page {
  $profile-margin-inline: 2.625rem;

  .form-sections-container {
    margin-top: 7.625rem;
  }

  #delete-profile-slideover-container {
    .form-fields-container {
      min-height: calc(100vh - 10.3rem);
    }
    .form-button-container {
      padding: 0 1.5rem;
    }
    #delete-profile-form-title {
      margin-top: 0;
    }
    #deleteProfileInput-field-container {
      margin-top: 1.5rem;
    }
  }

  #profile-page-container {
    background-color: $ittPortalGreyF7;
    padding: 0;
    height: 100%;

    #profileContent {
      padding: 0;
      height: 100%;
      overflow-y: auto;
      position: relative;
      display: grid;
      grid-template-areas:
        "back-to-sensors"
        "profile-form-fields"
        "delete-profile-section"
        "profile-form-buttons";
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto auto;

      #back-to-sensors {
        position: sticky;
        margin-left: 1rem;
        grid-area: back-to-sensors;
      }

      .profileContent-delete-profile-section {
        grid-area: delete-profile-section;
        height: auto;
        @include profile-section-padding-bg-color;
        margin: 0 $profile-margin-inline 6rem $profile-margin-inline;
        font-size: 0.8rem;
        @include roundedcornersmixin(0.5rem);

        #portalContent-delete-profile-section-btn {
          padding: 0.75rem 1rem;
        }

        h3 {
          margin-top: 0;
          font-weight: normal;
        }
      }

      .form-sections-menu {
        li {
          display: inline-block;
        }
      }

      form {
        height: 100%;

        .form-fields-container {
          grid-area: profile-form-fields;
        }

        .form-button-container {
          grid-area: profile-form-buttons;
          position: absolute;
        }
      }
      section {
        @include profile-section-padding-bg-color;
        margin: 0 $profile-margin-inline 1rem $profile-margin-inline;
        @include roundedcornersmixin(0.5rem);
        @media (max-width: $mobile-break-point) {
          margin: 0 1.625rem 1rem 1.625rem;
        }
        .form-section-content {
          padding: 0;
          .form-section-title {
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin-bottom: 1rem;
          }
        }
        &#form-section-profileImageSection {
          position: relative;
          padding: 0;
          margin: 0;
          #profileImage-field-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: fit-content;
            z-index: 2;
            position: absolute;
            top: 2.5rem;
            left: 0;
            right: 0;
            margin: 0 auto;
            @media (max-width: $mobile-break-point) {
              top: 4.5rem;
            }
            .form__error {
              width: max-content;
            }
          }
        }
        &#form-section-userProfileSection {
          position: relative;
          .form-section-title {
            margin-bottom: 5.3125rem;
            @media (max-width: $mobile-break-point) {
              margin-bottom: 6.3125rem;
            }
          }
        }
      }

      #form-section-preferencesSection {
        .profile-field-container,
        .profile-field-container-small {
          @media (min-width: #{$tablet-break-point + 1px}) {
            margin-right: 0.25rem;
            margin-left: 0.25rem;
            &:nth-of-type(4n + 1) {
              margin-left: 0;
            }
            &:nth-of-type(4n) {
              margin-right: 0;
            }

            &.form-group__date-with-example {
              width: calc(33.33% - 0.25rem);
            }
          }
        }
      }
    }
    .profile-field-container,
    .profile-field-container-small {
      @include slideOverOneThirdFormColumn();
      &.small {
        width: calc(16.67% - 0.4rem);
        @media (max-width: $tablet-break-point) {
          width: calc(50% - #{$slide-over-field-container});
        }
        @media (max-width: $mobile-break-point) {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
      &#dateFormatCharts-field-container {
        margin-left: 0.5rem;
        margin-right: 0;
      }
      .profile-field-container,
      .profile-field-container-small {
        width: 100%;
      }
    }
  }
}

.form-sections-menu {
  background-color: #fff;
  padding: 1.875rem;
  @include box-shadow(0 2px 14px 0 rgba(0, 0, 0, 0.08));
}
.form-sections-container {
  position: relative;
  .form-section-content {
    padding-top: 1.5rem;

    .form-section-title {
      font-weight: 700;
      margin-bottom: 0.625rem;
    }
    .form-section-subtitle {
      @include fontsize(0.75rem, normal, 400);
      margin-bottom: 0.5rem;
    }
  }
}

main {
  position: relative;
}
.portal {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  min-height: 100vh;
  grid-template-rows: $header-height calc(100vh - #{$header-height});
  grid-template-areas:
    "header"
    "content";
  @media (max-width: $header-break-point) {
    grid-template-rows: $header-height-mobile calc(
        100vh - #{$header-height-mobile}
      );
  }
  .sidebar {
    display: none;
  }

  &.has-expiring-message {
    grid-template-rows: $header-height $expiring-message-height calc(
        100vh - #{$header-height + $expiring-message-height}
      );
    grid-template-areas:
      "header"
      "expiring-message"
      "content";

    @media (max-width: $header-break-point) {
      grid-template-rows: $header-height-mobile $expiring-message-height calc(
          100vh - #{$header-height-lower-part + $expiring-message-height}
        );
    }

    .content {
      .grid-data {
        height: calc(
          100vh - #{$header-height + $expiring-message-height + $grid-margin-top +
            $min-grid-buttons-height}
        );
        @media (max-width: $header-break-point) {
          height: calc(
            100vh - #{$header-height-mobile + $expiring-message-height +
              $grid-margin-top + $min-grid-buttons-height}
          );
        }
      }
      .grid__with-applied-filters {
        .grid-data {
          height: calc(
            100vh - #{$header-height + $expiring-message-height +
              $grid-margin-top + $min-grid-buttons-height +
              $min_grid-filters-applied-height}
          );
          @media (max-width: $header-break-point) {
            height: calc(
              100vh - #{$header-height-mobile + $expiring-message-height +
                $grid-margin-top + $min-grid-buttons-height +
                $min_grid-filters-applied-height}
            );
          }
        }
      }
    }
  }

  &.header-absolute {
    grid-template-rows: 0 auto auto;
    .content {
      min-height: 100vh;
    }
  }
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.65);
    z-index: $overlay-z-index;
    top: $header-height;
  }
  .expiring-message {
    $expiring-icon-width: 2.5rem;
    $expiring-message-margin: 0.625rem;
    position: relative;
    grid-area: expiring-message;
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: $expiring-icon-width auto;
    margin-top: $expiring-message-margin;
    height: 4.875rem;
    width: 100%;
    color: $ittPortalRedDark;
    background-color: $ittPortalPink;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;
    z-index: $expiring-message-z-index;
    @include fontsize(0.875rem, normal, 700);
    &:empty {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: #{-1 * $expiring-message-margin};
      left: 4rem;
      @include triangleDown($ittPortalPink, 1rem);
      @include transform(rotate(-180deg));
    }
    .expiring-message-icon {
      @include fontsize($expiring-icon-width, normal, 900);
    }
    .expiring-message-inner {
    }
  }

  .content {
    grid-area: content;
    text-align: left;
    padding: 0;
    align-self: start;
    justify-self: start;
    min-height: calc(100vh - #{$header-height});
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    @media (max-width: $header-break-point) {
      min-height: calc(100vh - #{$header-height-mobile});
    }

    &__with-hierarchy-sidebar {
      display: grid;
      grid-template-columns: 1fr 4fr;
      grid-template-areas: "hierarchy-sidebar content-main";

      @media (max-width: $hierarchy-pinned-break-point) {
        grid-template-columns: 1fr;
        grid-template-areas: "content-main";
      }

      #hierarchy-sidebar {
        grid-area: hierarchy-sidebar;
        padding-top: 1rem;
        border-right: 1px solid $ittPortalGreyDF;
        overflow-y: auto;
        height: 100%;
        @media (max-width: $hierarchy-pinned-break-point) {
          display: none;
        }

        .hierarchy-tree-view,
        #asset-hiearchy-level-container {
          padding-inline: 0.5rem;
        }
      }
      #content-main {
        grid-area: content-main;
      }
    }

    .portalContent {
      $portalContentPadding: 1rem;
      padding: $portalContentPadding;
      min-height: calc(100vh - #{$header-height});
      height: 100%;
      @media (max-width: $header-break-point) {
        min-height: calc(100vh - #{$header-height-mobile});
      }
    }
  }
}
.message-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  display: inline-block;
  padding: 1.25rem;
  margin-bottom: 1rem;
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $ittPortalGrey9B;
  &:hover {
    background: $ittPortalGrey3;
  }
}

.sensor-status-battery {
  align-self: center;
  text-align: center;
  @include roundedcornersmixin(0.25rem);
  img {
    height: 0.875rem;
  }
  &.alert {
    position: relative;
    background-color: #fff;
    @include box-shadow(0.125rem 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.16));
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 0.25rem;
      height: 0.25rem;
      @include roundedcornersmixin(0.25rem);
      background-color: $ittPortalRedDark;
    }
  }
}

.sensor-status-last-communication {
  $sensor-source-icons-width: 0.875rem;
  $sensor-source-icons-margin: 0.25rem;
  .svg-inline--fa {
    margin-right: $sensor-source-icons-margin;
    @include fontsize($sensor-source-icons-width, normal, 900);
  }
}
.sensor-status-box {
  $battery-width: 30%;
  $battery-gap: 5%;
  cursor: pointer;
  display: grid;
  padding-right: 0.75rem;
  grid-template-columns: $battery-width #{100% - $battery-width - $battery-gap};
  grid-template-rows: auto auto;
  grid-gap: 0.25rem $battery-gap;
  grid-template-areas:
    "sensor-battery sensor-status"
    "sensor-battery sensor-last-communication";

  &.no-battery {
    grid-template-columns: 100%;
    grid-template-areas:
      "sensor-status"
      "sensor-last-communication";
  }

  .sensor-status-battery {
    grid-area: sensor-battery;
  }
  .sensor-status-last-communication {
    grid-area: sensor-last-communication;
  }
}
.hierarchy-box,
.asset-train-comments-box,
#fft-harmonics {
  position: relative;
  margin: 0.5rem 0.75rem 0.5rem 0;
  border: 1px solid $ittPortalGreyDF;
  padding: 0.5rem;
  @include roundedcornersmixin(0.25rem);

  &-title {
    position: absolute;
    top: -1.125rem;
    left: 0;
    font-size: 0.625rem;
    font-weight: bold;
  }

  .plant-name,
  .area-name,
  .zone-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  .edit-hierarchy,
  .edit-asset-train-comments,
  #edit-harmonics-btn {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    right: 0;
    background-color: $ittPortalGreyDF;
    border: none;
    width: 1.188rem;
    height: 1.063rem;
    @include roundedcornersmixin(0.25rem 0 0 0);
    padding: 0;
    display: flex;
    justify-content: center;

    svg {
      width: 0.563rem;
      height: 0.563rem;
      color: $ittPortalGrey3;
      align-self: center;
    }

    .fa-pen {
      color: $ittPortalGrey3 !important;
      @include fontsize(0.5625rem, normal, 900);
    }
  }

  .overflow {
    @include showLines(2, 1em);
  }
}

#fft-harmonics {
  #edit-harmonics-btn {
    z-index: 3;
  }
}
.asset-document-description-box {
  position: relative;
  margin: 0.5rem 0.75rem 0.5rem 0;
  border: 1px solid $ittPortalGreyDF;
  padding: 0.5rem;
  @include roundedcornersmixin(0.25rem);
  .plant-name,
  .area-name,
  .zone-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  .edit-asset-document-description {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    right: 0;
    background-color: $ittPortalGreyDF;
    border: none;
    width: 1.188rem;
    height: 1.063rem;
    @include roundedcornersmixin(0.25rem 0 0 0);
    .fa-pen {
      @include fontsize(0.5625rem, normal, 900);
      color: $ittPortalGrey3;
      width: 0.563rem;
      height: 0.563rem;
      object-fit: contain;
    }
  }
}
.sensor-settings {
  margin-top: 0.125rem;
  cursor: pointer;
  color: $ittBlueLight;
  .svg-inline--fa {
    margin-right: 0.25rem;
    color: $ittBlueLight;
    @include fontsize(0.625rem, normal, 900);
  }
}
.diagnostics-box {
  padding: 0.375rem 0.25rem;
  display: inline-block;
  margin-bottom: 0.375rem;
  @include roundedcornersmixin(0.25rem);
  .svg-inline--fa {
    margin-right: 0.25rem;
    @include fontsize(0.625rem, normal, 900);
  }
}

.upgrade-to-ia3-btn {
  border: 0;
  background-color: $ittPortalGreenSuccess;
  text-align: center;
  margin: 0;
  padding: 0.75rem 1rem;
  color: $ittPortalGrey3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include fontsize(0.75rem, normal, 900);

  svg {
    color: $ittPortalGrey3;
    margin-right: 0.5rem;
    @include fontsize(1.25rem, normal, 400);

    &.fa-arrow-up {
      @include transform(rotate(45deg));
    }
  }
}

#unassigned-sensors-container {
  width: 32.938rem;
  min-width: unset;
  max-width: 50%;
  overflow: visible;
  .unassigned-sensor-slideover-title {
    @include fontfamily($font-family, 1.5rem, 1.5rem, 700);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $ittPortalGrey3;
    margin: 0;
  }
  #assignSensorToAssetForm {
    padding-left: 0.188rem;
    padding-right: 0.188rem;
    .form-button-container {
      padding: 0 0 0.5rem 0;
      .btn {
        margin-top: 0;
      }
    }
  }
}
.action-btn {
  background-color: $ittPortalGrey3;
  text-align: center;
  margin: 0;
  padding: 0.375rem 0 0.375rem 0.5rem;
  width: calc(100% - 0.75rem);
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include fontsize(0.75rem, normal, 700);
  @include roundedcornersmixin(0.25rem);

  svg {
    color: #fff;
    margin-right: 0.375rem;
    @include fontsize(0.5rem, normal, 900);
  }
}
.in-battery-alarm {
  background-color: $ittPortalRedLight !important;
}

.search-expanding-input {
  $icons-width: 1rem;
  $search-padding-multiplier: 2;
  width: 15rem;
  top: -0.3rem;

  label {
    cursor: pointer;
  }

  &:before {
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    font-weight: 900;
    font-size: $icons-width;
    position: absolute;
    top: #{0.85 * $icons-width};
    width: $icons-width;
    height: $icons-width;
    left: 0;
    right: 0;
    cursor: pointer;
  }
  input,
  #searchAccount {
    width: #{$search-padding-multiplier * $icons-width};
    height: #{2.4 * $icons-width};
    overflow: hidden;
    border: 0;
    position: relative;
    padding: 0 0 0 #{$search-padding-multiplier * $icons-width};
    background-color: transparent;
    @include roundedcornersmixin(0);
    @include transform(all 0.2s ease);
    &:not(:placeholder-shown),
    &:focus {
      width: 100%;
      border-bottom: 1px solid $ittPortalGreyDF;
      @include roundedcornersmixin(0);

      & + label {
        opacity: 1;
        padding-left: #{$search-padding-multiplier * $icons-width};
        left: 0;
        position: absolute;
      }
    }

    & + label {
      padding-left: #{$search-padding-multiplier * $icons-width};
      opacity: 0;
    }
  }
}

.ota-message-container {
  .ota-message {
    white-space: normal;
  }
  .edit-ota {
    text-decoration: none;
    margin-top: 0.5rem;
    margin-right: 0;
    margin-left: auto;
    display: inline-block;
    border: 1px solid rgb(223, 223, 223);
    padding: 0.75rem 0.5rem;
    background-color: white;
    color: rgb(51, 51, 51);
    @include fontsize(0.625rem, normal, 700);
    @include roundedcornersmixin(0.25rem);
  }
}

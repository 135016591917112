#notes-page {
  padding: 0;
  @media (max-width: $tablet-break-point) {
    #notes-page-container {
      overflow-y: auto;
    }
  }
  .notes-sections-container {
    display: grid;
    grid-template-columns: 60% 40%;

    #notesForm {
      @media (max-width: $tablet-break-point) {
        height: 50vh;
      }
    }

    .form-sections-container {
      display: grid;
      grid-template-columns: 42.86% 57.14%;
      height: 100vh;
      box-shadow: 0.25rem 0 0.875rem 0 rgba(0, 0, 0, 0.08);

      #form-section-writeNoteSection {
        margin: 0 2.5rem;
        padding-top: 2.2rem;

        .form-section-content {
          padding-top: 0;

          .form-section-title {
            font-size: 1.5rem;
            margin-bottom: 1rem;
          }

          textarea {
            height: 14.563rem;
          }
        }
      }

      #form-section-causesOfFailureSection {
        height: 100%;
        padding-top: 2.2rem;
        margin: 0;
        padding-right: 4.5rem;

        .form-section-content {
          height: 100%;
          padding-top: 0;

          .form-section-title {
            font-size: 1.5rem;
            margin: 0;
          }
          .form-section-fields {
            height: 100%;
            .form-group__multiple-checkbox-and-text {
              height: 100%;
              padding-top: 0.5rem;
              border-right: 0;
              padding-right: 0;

              .checkbox-list {
                overflow-y: auto;
                height: calc(100vh - 16rem);
              }
            }
          }
        }
        @media (max-width: $tablet-break-point) {
          padding-right: 0;
        }
        @media (max-width: $mobile-break-point) {
          margin: 0 2.5rem;
        }
      }
      @media (max-width: $tablet-break-point) {
        height: auto;
        grid-template-columns: 42% auto;
      }

      @media (max-width: $mobile-break-point) {
        display: flex;
        flex-direction: column;
      }
    }

    #notes-list-container {
      padding: 1rem 1.5rem 1rem 2.5rem;
      overflow-y: auto;
      height: 100vh;

      @media (max-width: $tablet-break-point) {
        height: 50vh;
        order: -1;
      }

      .notes-list-header {
        display: flex;
        align-items: center;

        .title {
          margin-bottom: 1rem;
        }

        .count {
          width: 1.875rem;
          height: 1.875rem;
          align-items: center;
          justify-content: center;
          background-color: $ittPortalGreyF7;
          border-radius: 50%;
          text-align: center;
          display: flex;
          margin-left: 0.75rem;
          font-weight: bold;
        }
      }

      .notes-list-items {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        height: 100%;

        .note-item {
          border-bottom: 1px solid $ittPortalGreyF7;
          padding-bottom: 0.5rem;

          .column-icon {
            font-size: 0.875rem;
            cursor: pointer;
          }

          .user-delete {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .user {
              font-size: 1rem;
              font-weight: bold;
              margin: 0;
            }
          }

          .asset-type-date,
          .message,
          .reasons-list {
            font-size: 0.75rem;
          }

          .message {
            margin-bottom: 0.5rem;
          }

          .asset-type-date {
            margin-bottom: 0.25rem;
            margin-top: 0.125rem;
          }

          .reasons-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: calc(100% - 1.875rem);
            gap: 0.625rem;
          }
        }
      }

      .notes-list-empty {
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $ittPortalGrey9B;
        @include fontsize(1.5rem, normal, 700);
        height: 60%;

        img {
          margin: 0 auto;
        }

        figcaption {
          margin-top: 2rem;
        }
      }
    }

    @media (max-width: $tablet-break-point) {
      display: flex;
      flex-direction: column;
    }
  }
  .form-button-container {
    box-shadow: 0 -0.125rem 0.25rem 0 rgba(0, 0, 0, 0.08);
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    .form-group {
      height: 4rem;
      padding: 0;
      grid-gap: 1.8125rem;
      #notes_cancel,
      #notes_save {
        margin: 0;
        &:disabled {
          display: none;
        }
      }
    }
  }
}

.note-reasons-container {
  position: absolute;
  top: 28rem;
  left: 2.563rem;
  max-width: 30%;
  height: calc(100% - 37rem); //60%;

  @media (max-width: 1110px) {
    max-width: 14rem;
  }

  @media (max-width: 820px) {
    //max-width: 8rem;
    top: 30rem;
    //height: 50%;
  }
  @media (max-width: $tablet-break-point) {
    max-width: 10rem;
    //height: 45%;
  }

  @media (max-width: $mobile-break-point) {
    position: unset;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    max-width: 14rem;
  }

  .form-field {
    margin-top: 0.5rem;
    height: 95%;

    @media (max-width: $tablet-break-point) {
      max-width: 14rem;
      //height: 8.875rem;
    }

    @media (max-width: $mobile-break-point) {
      max-width: 14rem;
      //height: 8.875rem;
    }

    @media (max-width: 1110px) {
      //height: 8.875rem;
    }
    overflow-y: auto;
  }
  .label {
    @include fontsize(1.125rem, 1.5rem, 700);
  }
}

.note-reasons-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 1.875rem);
  gap: 0 0.625rem;
  font-size: 0.75rem;

  @media (max-width: 1030px) {
    max-width: 10rem;
  }

  .note-reason {
    color: #fff;
    padding: 0.25rem 0.5rem;
    @include roundedcornersmixin(0.25rem);
    text-align: center;
    width: fit-content;
    margin-bottom: 0.5rem;
  }
}

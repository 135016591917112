#analysis-card-page {
  #analysis-card-page-container {
    $analysis-card-margin: 1rem;
    background-color: $ittPortalGreyF7;
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding: 0;

    #observationCategories-field-container {
      p {
        font-size: 1.5rem;
        font-weight: 700;
      }
      .form-field label {
        margin-bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
    #analysis-card-add-observation-slideover-container {
      overflow-y: auto;
      .form-fields-container {
        min-height: calc(100vh - 7.5rem);
        .trends-chart-container,
        .fft-chart-container,
        .twf-chart-container {
          margin-bottom: 7rem;
        }
      }
      .form-group__radio {
        min-height: calc(100vh - 11rem);
      }
    }
    #analysis-card-page-container .slide-over {
      @extend .no-print;
    }

    .visibility-level-threshold {
      display: flex;
      cursor: pointer;
      align-items: center;
      @include fontsize(0.625rem, normal, 700);
      &:after {
        content: "";
        @include arrow($ittPortalGrey3, 0.11rem);
        cursor: pointer;
        @include transform(rotate(45deg));
        margin-left: 0.25rem;
      }
    }
    .twf-content .fft-twf-chart-container-section-buttons {
      grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) 4fr;
      grid-template-areas: "fft-harmonics fft-filter-orientations ...";
      @media (max-width: $tablet-break-point) {
        grid-template-areas:
          "fft-harmonics ..."
          "fft-filter-orientations ...";
        grid-template-columns: minmax(200px, 1fr) 1fr;
        grid-gap: 0.25rem;
      }
    }

    .header {
      .title {
        margin-bottom: 1rem;
      }
    }
    button {
      @extend .no-print;
    }
    .analysis-card-button-close-btn {
      border: none;
      background-color: transparent;
      position: absolute;
      right: 0;
      padding: 0;
      margin: 0;
    }

    #analysis-card-observation-chart-view {
      .asset-train-empty-box {
        display: flex;
        justify-content: center;
      }

      .analysis-card-button-close-btn {
        right: 1rem;
      }
    }

    #assetTrainsGrid {
      margin-top: 0.5rem;
      .grid-above-table {
        display: none;
      }

      .grid-data {
        height: auto;
        .grid-header-row {
          min-height: 2.3rem;
        }
        .grid-data-row .column {
          border: 0;

          .hierarchy-box {
            margin-top: 0.625rem;
            margin-right: 0.75rem;
            margin-bottom: 0;
          }
        }
      }
    }

    button:not(.analysis-card-button-close-btn):not(.form-button-container
        *):not(.form-field-with-delete *):not(.form-group__checkbox *):not(.grid
        *):not(.asset-train-page-trend-section
        *):not(#analysis-card-add-observation-slideover-container
        *):not(#analysis-card-observation-chart-view
        *):not(.section-header-name-delete-btn):not(#analysis-card-share-slideover-container
        *):not(.recommendation-content *):not(.root-cause-content
        *):not(#analysis-card-section-top-account-card-edit):not(#comment-content-delete-btn) {
      border: 1px solid $ittPortalGreyDF;
      padding: 0.6875rem 0.5rem;
      background-color: #fff;
      grid-gap: 0.25rem;
      align-content: center;
      display: grid;
      grid-auto-flow: column;
      margin: auto;
      height: auto;
      @include roundedcornersmixin(0.25rem);
      @include fontsize(0.625rem, normal, 700);
      @extend .no-print;

      &#analysis-card-delete {
        grid-gap: 0;
      }

      &#analysis-card-add-observation {
        width: fit-content;
        margin-right: 0;
        @media (max-width: $mobile-break-point) {
          margin: auto auto 1rem 0;
        }
      }

      .svg-inline--fa,
      img {
        width: 0.875rem;
        height: 0.875rem;
        margin-top: -0.2rem;
        @include fontsize(1rem, normal, 700);
      }
    }

    .analysis-card-section-observations-header {
      display: grid;
      grid-template-areas:
        "observations-title add-observation"
        "observations-visibility add-observation";
      grid-template-columns: 1fr 1fr;
      padding: 0 3.75rem;
      margin-top: 0.375rem;

      @media (max-width: $mobile-break-point) {
        padding-left: 1.2rem;
        gap: 0.5rem;
        align-items: self-start;
        grid-template-areas:
          "observations-title"
          "observations-visibility"
          "add-observation";
        grid-template-columns: 1fr;

        h2 {
          margin-bottom: 0;
        }
      }
      .visibility-level-threshold {
        grid-area: observations-visibility;
        margin-bottom: 1.438rem;
      }
      #visibility-level-threshold-slideover-OBSERVATIONS {
        .form-button-container .form-group {
          display: flex;
        }
      }

      .analysis-card-section-observations-header-title {
        grid-area: observations-title;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;

        @media (max-width: $mobile-break-point) {
          margin: 0;
          padding: 0;
        }
      }

      .btn {
        grid-area: add-observation;
        font-family: $font-family;
        align-items: center;
        span {
          white-space: nowrap;
        }
      }
    }

    #analysis-card-section-observations {
      .section-header-name-delete-btn {
        background-color: transparent;
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
        padding-bottom: 0;
      }
      .section-content {
        .section-content.empty {
          align-items: center;
          display: block;
        }
        .section-content {
          display: grid;
          grid-template-areas:
            "observation-type screenshot"
            "comments ...";
          grid-template-columns: 2fr 1fr;
          gap: 1.63rem;
          padding-top: 0;

          @media (max-width: $tablet-break-point) {
            grid-template-areas:
              "observation-type"
              "screenshot"
              "comments";
            grid-template-columns: 1fr;
          }
        }
        &.observation-section {
          margin: 0 0 0.938rem 0;
          h2 {
            margin-top: 1.5rem;
          }

          .observation-section-created {
            display: grid;
            grid-template-areas: "created timestamp";
            grid-template-columns: max-content max-content;
            width: fit-content;
            gap: 0.75rem;
            font-size: 0.625rem;
            margin: 0.25rem 0 0 0;
          }
        }

        .section-header-buttons {
          #analysis-card-expand-graph {
            display: flex;
            align-items: flex-end;
          }
        }
      }

      .observation-content {
        grid-area: observation-type;
        width: 100%;
        margin-top: 1rem;
        position: relative;
        align-items: center;
        height: fit-content;

        &-type {
          width: 100%;
          @include roundedcornersmixin(0.25rem);
          border: solid 1px $ittPortalGreyDF;
          height: fit-content;
          padding: 0.5rem 0 0.5rem 1rem;

          @media (max-width: $tablet-break-point) {
            margin: 1rem 0;
          }

          &-title {
            font-size: 0.75rem;
            font-weight: normal;
            color: $ittPortalGrey9B;
          }

          &-category {
            font-weight: bold;
            font-size: 1rem;
            color: $ittPortalBlack;

            li {
              padding-bottom: 0.2rem;
            }

            > li:before {
              height: 0;
              width: 0.35rem;
              margin: 0 0.3rem 0.3rem 0;
            }
          }
        }

        &-screenshot {
          grid-area: screenshot;
          position: relative;
          img {
            cursor: pointer;

            @media (max-width: $tablet-break-point) {
              margin-bottom: 1rem;
            }
          }
        }

        &-comments {
          grid-area: comments;
          margin-top: 0.313rem;
        }
      }
    }

    .analysis-card-section-top {
      padding: 0;
      margin-bottom: 0.6rem;
      box-shadow: 0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.08);

      @media (max-width: 950px) {
        height: auto;
      }
      @media (max-width: $tablet-break-point) {
        height: auto;
      }
      @media (max-width: $mobile-break-point) {
        height: auto;
      }

      .section-content {
        padding: 1rem 0.75rem 0.75rem 1rem;

        .analysis-card-section-separator {
          margin-top: 1rem;
          border-bottom: 1px solid $ittPortalGreyLight3;
        }
      }

      .analysis-card-section-bottom-header {
        display: flex;
        align-items: start;
        width: fit-content;
        max-width: calc(100% - 3rem);
        grid-gap: 0.375rem;
        margin-top: 0.85rem;
        flex-wrap: wrap;
        @extend .no-print;

        #analysis-card-delete {
          grid-gap: 0;
          right: 0.5rem;
          position: absolute;
          margin-top: 1px !important;
        }

        .green {
          border: 1px solid $ittPortalGreen;
          background-color: #eefcf7 !important;
        }

        .red {
          border: 1px solid $ittPortalRedDark;
          background-color: #fceeee !important;
        }

        .none {
          border: none;
        }

        .analysis-card-menu-button-published,
        .analysis-card-menu-button-view-by {
          justify-content: center;
          font-weight: bold;
          padding: 0;

          &-content-img {
            width: 0.875rem;
            height: 0.875rem;
          }
        }

        .analysis-card-menu-button-quarterly-status,
        .analysis-card-menu-button-published,
        .analysis-card-menu-button-view-by {
          display: flex;
          flex-direction: column;
          width: fit-content;
          padding: 0.25rem 0.5rem;
          min-height: 2.25rem;
          background-color: #f7f7f7;
          @include roundedcornersmixin(0.25rem);
          cursor: pointer;

          .green {
            @include boolet($ittPortalGreen, $size: 0.563rem);
          }

          .red {
            @include boolet($ittPortalRedDark, $size: 0.563rem);
          }

          &-title {
            font-weight: bold;
            font-size: 0.625rem;

            @media (max-width: $tablet-break-point) {
              font-size: 0.45rem;
            }
          }

          &-content {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            font-size: 0.625rem;

            &-profile-img {
              width: 0.563rem;
              height: 0.563rem;
            }

            &-chevron-down {
              height: 0.188rem;
              width: 0.188rem;
              @include arrow($ittPortalBlack, 0.1rem);
              transform: rotate(45deg);
            }
          }
        }

        .analysis-card-menu-button-quarterly-status {
          &-content {
            gap: 0.35rem;
            align-items: center;
            font-size: 0.625rem;
          }
        }

        .analysis-card-menu-button-published {
          width: 8rem;
          &-content {
            flex-direction: row;
            justify-content: space-between;
            gap: 0.55rem;
            align-items: center;
            font-size: 0.625rem;
          }
        }
      }

      &-header {
        display: flex;
        justify-content: space-between;

        @media (max-width: $mobile-break-point) {
          flex-direction: column;
        }
        @media (max-width: $tablet-break-point) {
          flex-direction: column;
        }
      }

      #analysis-card-menu {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        @extend .no-print;

        @media (max-width: $tablet-break-point) {
          justify-content: start;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 0.75rem;
          align-items: center;
          position: relative;
          overflow: auto;
          white-space: nowrap;
          -ms-overflow-style: none;
          scrollbar-width: none;
          list-style: none;
          padding: 0;
          margin: 0.5rem 1rem 0 0;

          @media (max-width: $tablet-break-point) {
            flex-wrap: wrap;
            margin: 1rem 0;
            height: auto;
            gap: 0.7rem;
          }
        }

        li {
          $bottom-gap: 0.25rem;
          cursor: pointer;
          font-size: 0.75rem;
          position: relative;
          margin-bottom: $bottom-gap;
          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0.125rem;
            background-color: $ittPortalGrey3;
            bottom: #{-1 * $bottom-gap};
            left: 0;
            @include transition(all 0.3s ease);
          }

          &.activeSection {
            font-weight: bold;
            &:after {
              width: 100%;
            }
          }
        }

        .analysis-card-close-btn-container {
          height: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid $ittPortalGreyF7;
          @extend .no-print;

          @media (max-width: $tablet-break-point) {
            height: fit-content;
            position: absolute;
            top: 2.18rem;
            right: 0.8rem;
          }

          .analysis-card-close-btn {
            padding: 0 0.75rem 0 1.25rem;
            font-size: 0.2rem;
            cursor: pointer;
          }
        }
      }

      &-account {
        &-name {
          font-size: 0.625rem;
        }

        &-card {
          display: flex;
          flex-flow: wrap;
          gap: 0.55rem;
          align-items: center;
          h2 {
            margin: 0;
            padding: 0;
            @include fontsize(1.5rem, normal, 700);
          }
          #analysis-card-section-top-account-card-edit {
            @include fontsize(0.625rem, normal, 400);
            align-items: center;
            gap: 0.2rem;
            cursor: pointer;
            @include roundedcornersmixin(0.25rem);
            padding: 0.25rem 0.375rem;
            background-color: $ittPortalGreyF7;
            @extend .no-print;

            svg {
              font-size: 0.563rem;
              margin-right: 0.5rem;
            }
          }
          &-name {
            font-weight: bold;
            font-size: 1.5rem;

            @media (max-width: 950px) {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .analysis-card-section-middle-header {
      display: grid;
      grid-template-areas: "analysis-card-section-middle-header-left analysis-card-section-middle-header-right";
      grid-template-columns: 2fr 3fr;
      gap: 1.5rem;
      width: 100%;
      margin-top: 1rem;
      font-size: 0.625rem;
      align-items: baseline;

      @media (max-width: $tablet-break-point) {
        grid-template-columns: 1fr 1fr;
      }

      .green {
        color: $ittPortalGreen !important;
      }

      .red {
        color: $ittPortalRedDark !important;
      }

      .orange {
        color: $ittOrange !important;
      }

      .analysis-card-section-middle-header-left {
        grid-area: analysis-card-section-middle-header-left;
        display: flex;
        gap: 0.5rem 1rem;
        justify-items: left;
        align-items: start;
        flex-wrap: wrap;

        .analysis-card-generate {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;

          @media (max-width: $tablet-break-point) {
            align-items: center;
            justify-content: center;
          }

          @media (max-width: $mobile-break-point) {
            align-items: center;
          }

          .analysis-card-generate-type {
            display: flex;
            align-items: baseline;
            gap: 0.4rem;
            background-color: $ittBlueLighter;
            @include roundedcornersmixin(0.25rem);
            padding: 0.375rem 0.625rem;
            font-weight: bold;
            color: $ittBlueLight;

            .analysis-card-generate-type-profile-img {
              width: 0.563rem;
              height: 0.563rem;
            }
          }

          .analysis-card-generate-auto {
            display: flex;
            align-items: center;
            gap: 0.3rem;
            color: #333;
            cursor: pointer;
            width: fit-content;
            white-space: nowrap;
            margin-left: 0.3rem;

            @media (max-width: $mobile-break-point) {
              margin-right: auto;
            }

            .analysis-card-generate-auto-chevron-right {
              padding-top: 0.04rem;
              font-size: 0.4rem;
            }
          }
        }

        .analysis-card-top-created-due-date {
          .analysis-card-top-due-date {
            display: flex;
            align-items: center;
            font-weight: bold;
            cursor: pointer;

            &-chevron-down {
              margin-left: 0.2rem;
              height: 0.188rem;
              width: 0.188rem;
              @include arrow($ittPortalBlack, 0.1rem);
              transform: rotate(45deg);
            }
          }
          #analysis-cards-card-due-date-form {
            .delete-btn-with-arrow {
              svg {
                right: 3rem;
              }
            }

            .form-button-container {
              position: absolute;
            }
          }
        }
      }

      .analysis-card-section-middle-header-right {
        grid-area: analysis-card-section-middle-header-right;
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        justify-content: right;

        .green,
        .open {
          border: 1px solid $ittPortalGreen;
          background-color: #eefcf7 !important;
        }

        .red,
        .closed {
          border: 1px solid $ittPortalRedDark;
          background-color: #fceeee !important;
        }

        .none {
          border: none;
        }

        .analysis-card-menu-button-priority,
        .analysis-card-menu-button-assignee,
        .analysis-card-menu-button-status {
          display: flex;
          flex-direction: column;
          width: fit-content;
          min-width: 6.5rem;
          padding: 0.375rem 0.5rem;
          background-color: #f7f7f7;
          @include roundedcornersmixin(0.25rem);
          cursor: pointer;

          .green,
          .open {
            @include boolet($ittPortalGreen, 0.563rem);
          }

          .red,
          .closed {
            @include boolet($ittPortalRedDark, 0.563rem);
          }

          &-title {
            font-weight: bold;
            margin-bottom: 0.25rem;
          }

          &-content {
            display: flex;
            gap: 0.25rem;
            align-items: center;

            .archived {
              background-color: $ittPortalGrey6 !important;
              border: none;
              height: 0.375rem;
              width: 0.375rem;
              border-radius: 50%;
            }

            .high,
            .critical,
            &.critical {
              color: $ittPortalRedDark;
            }

            .medium,
            .major,
            &.major {
              color: $ittOrange;
            }

            .low,
            .minor,
            &.minor {
              color: $ittPortalGreen;
            }

            &-profile-img {
              width: 0.563rem;
              height: 0.563rem;
            }

            &-chevron-down {
              height: 0.188rem;
              width: 0.188rem;
              @include arrow($ittPortalBlack, 0.1rem);
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .slide-over .slide-over-container {
      #tagToAdd-field-container {
        width: 100%;
      }
    }

    .slide-over .slide-over-container .header {
      margin-bottom: 0;
    }
    .analysis-card-empty-box {
      text-align: center;
      figure {
        margin: 0 0 0.5rem 0;
        img {
          max-width: none;
          max-height: none;
          display: inline;
          width: 7.375rem;
        }
        figcaption {
          @include fontsize(0.75rem, normal, 400);
        }
      }
    }

    #add-observation-chart-form {
      #add-observation-chart-1,
      #add-observation-chart-5 {
        width: 33.33%;
        @media (max-width: $tablet-break-point) {
          width: 50%;
        }
        @media (max-width: $mobile-break-point) {
          width: 100%;
        }
      }
      .form .form-group__checkbox {
        padding-block: 0;
      }
      .trends-chart-container-section
        .trend-section-buttons
        .slide-over-container {
        padding: 2.5rem 1rem 0 1rem;
        background-color: #fff;

        #asset-train-trend-period-form-title {
          margin-top: 0;
        }

        #trendTimePeriodForm {
          .form-fields-container {
            min-height: calc(100% - 11rem);
          }
        }
      }
    }

    #priority-watch-form {
      .radio-label {
        font-size: 1rem;
        font-weight: bold;
      }

      .form-group__radio {
        min-height: auto;
      }

      .form-button-container {
        position: absolute;
        bottom: 0;
      }
    }

    .form {
      .form-group__checkbox {
        border-bottom: 0;
      }
      .form-group {
        .search-field {
          margin: 0 0 1.5rem 0;
          position: relative;
        }
        .form-field label.checkbox-label {
          padding-top: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid $ittPortalGreyF7;
          color: $ittPortalGrey3;
        }
        input[type="checkbox"] + .checkmark {
          top: 1rem;
        }
        .sensor-status-popup-measurements,
        &[id$="-visibility-field-container"] {
          input[type="checkbox"] + .checkmark {
            top: 0;
          }
        }
      }

      #dueDate-field-container {
        width: 100%;
      }
    }
    #fft-harmonics {
      .form {
        .form-group__checkbox {
          border-bottom: 0;
          padding-block: 0;
        }
        .form-group {
          .form-field label.checkbox-label {
            border-bottom: 0;
          }
        }
      }
    }

    section {
      padding: 0 3.75rem 1rem 3.75rem;
      @media (max-width: $tablet-break-point) {
        padding: 0 1rem 1rem 1rem;
      }

      &.form-section {
        padding: 0;
      }

      &.analysis-card-section-roi {
        div > div.section-content {
          padding: 0.5rem 0 0 0.2rem !important;
        }

        #rOIsForm {
          margin-top: 0.6875rem;

          .form-group {
            &.third-width {
              $roi-margin: 0.35rem;
              width: calc(33.33% - #{$roi-margin});
              @media (max-width: $large-break-point) {
                width: calc(50% - #{$roi-margin});
                margin-right: calc($roi-margin/2);
                margin-left: calc($roi-margin/2);
                &:nth-of-type(2n) {
                  margin-right: 0;
                }
                &:nth-of-type(2n + 1) {
                  margin-left: 0;
                }
              }
            }
          }

          .form-fields-container {
            min-height: auto;

            .form-field {
              label {
                max-width: calc(100% - 1rem);
                line-height: normal;
              }
            }
          }
          .form-button-container:not(.visibility-level-threshold-slideover-content-form) {
            position: unset;
            max-width: 33%;
            padding: unset;
            margin: auto;

            .form-group {
              button {
                padding: 0.5rem 0;
              }

              #rois_save:disabled {
                background-color: $ittPortalGreyLight2;
              }
            }
            @media (max-width: $large-break-point) {
              max-width: 40%;
            }
            @media (max-width: $tablet-break-point) {
              max-width: 50%;
            }
            @media (max-width: $mobile-break-point) {
              max-width: 75%;
            }
          }
        }
        .section-header-container-with-tooltip {
          .element-with-tooltip {
            display: inline-block;
            .element-tooltip {
              right: -1.3rem;
              .i-alert-popup {
                &.opposite:after {
                  padding: 0.2rem 0.2rem 0.2rem 0.5rem;
                  top: 0.6rem;
                }
              }
            }
          }
          h2 {
            grid-area: unset;
          }
        }
      }

      &.analysis-card-section-comments,
      &.analysis-card-section-observations,
      &.analysis-card-section-recommendations,
      &.analysis-card-section-roi,
      &.analysis-card-section-root-causes,
      &.analysis-card-section-tags {
        .section-content {
          padding: $analysis-card-margin 0.625rem 1.313rem $analysis-card-margin;
          @include fontsize(0.75rem, normal, 400);

          .section-content {
            padding: 0.5rem 0.2rem 0 0.2rem;

            div:last-child:not(.title) {
              margin-bottom: 0;
            }
          }

          &.empty {
            padding-top: 0;
            padding-bottom: 0;
          }

          ul {
            padding: 0;
            margin: 0.5rem auto 0.375rem auto;

            li {
              padding: 0 0 0.5rem 0;
              list-style: none;
              &:before {
                content: "";
                @include circle(
                  $ittPortalGrey3,
                  $ittPortalGrey3,
                  $ittPortalGrey3,
                  0.5rem
                );
                display: inline-block;
                margin-right: 0.5rem;
              }

              .recommendation-content,
              .root-cause-content {
                display: inline-flex;
                align-items: center;

                #recommendation-content-delete-btn,
                #root-cause-content-delete-btn {
                  display: flex;
                  align-items: center;
                  padding: 0;
                  margin-left: 0.6rem;
                  background-color: transparent;

                  img {
                    height: 0.375rem;
                    width: 0.375rem;
                  }
                }
              }

              .analysis-card-separator {
                margin: 0.5rem -4rem;
                border-bottom: 1px solid $ittPortalGreyF7;
                position: relative;
              }
            }
          }

          &-tag-container {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 1.125rem;

            &-content {
              background-color: $ittPortalGreyF7;
              padding: 0.25rem 0.494rem 0.25rem 0.5rem;
              height: 1.438rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0.25rem;

              &-name {
                font-size: 0.75rem;
              }
              &-delete-btn {
                height: 0.375rem;
                width: 0.375rem;
                margin-left: 0.688rem;
                cursor: pointer;
              }
            }
          }
        }
      }

      &.analysis-card-section-tags {
        #addAnalysisCardTagForm {
          .step-content {
            display: flex;
          }
        }

        .section-content {
          padding-bottom: 1.625rem;
        }

        #addAnalysisCardAddCommentFileForm {
          .form-button-container {
            position: absolute;
            bottom: 0;
          }
        }
      }

      &.analysis-card-section-asset-train {
        $analysis-card-section-asset-train-left: 1.125rem;
        padding-bottom: 0.563rem;
        .section-content {
          padding-inline: 0;

          .assetTrainsGrid-description {
            @include fontsize(0.875rem, normal, 700);
            margin: 0.75rem 0 0 $analysis-card-section-asset-train-left;
          }

          h2 {
            margin: 0.75rem 0 0 $analysis-card-section-asset-train-left;
          }
          h3 {
            @include fontsize(0.875rem, normal, 700);
            margin: 0 0 0 $analysis-card-section-asset-train-left;
          }
        }
      }
      .analysis-card-observation-created-text {
        margin-top: 1rem;
      }

      .analysis-card-observation-created-text,
      .analysis-card-comment-file {
        @include fontsize(0.625rem, normal, 400);
        margin-bottom: 0.5rem;
        .created {
          display: flex;
          align-items: center;

          img {
            width: 0.675rem;
            height: 0.675rem;
            margin-right: 0.5rem;
          }
          b {
            &:after {
              content: "";
              @include circle(
                $ittPortalGrey3,
                $ittPortalGrey3,
                $ittPortalGrey3,
                0.1rem
              );
              display: inline-block;
              margin: auto 0 0.1rem 0.2rem;
            }
          }
          @media (max-width: $mobile-break-point) {
            display: flex;
            flex-direction: column;
          }
        }
        .comment {
          color: $ittPortalGrey9B;
          margin-top: 0.25rem;
        }

        #comment-content-delete-btn {
          display: flex;
          align-items: center;
          background-color: transparent;
          padding: 0;
          margin: 0 0 0 0.5rem;
          img {
            height: 0.375rem;
            width: 0.375rem;
          }
        }

        .comment-image-container {
          color: $ittPortalGrey3;
          margin-top: 0.25rem;
          display: inline-block;
          max-height: 25rem;
          max-width: 70%;
        }
        .comment-image {
          @extend .img-responsive;
        }
        .analysis-card-comment-file-document {
          display: unset !important;
        }
      }

      .section-content {
        background-color: #fff;
        padding: 0.7rem 1rem 0.7rem 1rem;
        height: 100%;
        @include roundedcornersmixin(0.5rem);
      }

      h2 {
        @include fontsize(1rem, normal, 700);
        margin: 1.25rem 0 0.125rem $analysis-card-margin;
      }

      .section-header {
        display: grid;
        grid-template-areas:
          "title buttons"
          "visibility-level-threshold buttons";
        grid-template-columns: 1fr 1fr;
        margin-top: -1rem;
        @media (max-width: $mobile-break-point) {
          display: flex;
          flex-direction: column;
        }

        h2 {
          grid-area: title;
          margin-left: 0;
        }

        &-name {
          display: grid;
          grid-template-areas: "name button";
          grid-template-columns: max-content max-content;
          grid-area: title;

          h2 {
            grid-area: name;
            @include fontsize(1rem, normal, 700);
          }
          #observation-delete-btn {
            grid-area: button;
            cursor: pointer;
            border: none;
            font-size: 0.625rem;
            margin: 1.6rem 0 0 1rem;
            padding: 0;
            height: fit-content;
          }
        }
        .visibility-level-threshold {
          grid-area: visibility-level-threshold;
        }
        .section-header-buttons {
          grid-area: buttons;
          grid-auto-flow: column;
          display: grid;
          grid-gap: 0.5rem;
          justify-content: right;
          padding-top: 1rem;
          align-content: baseline;

          @media (max-width: $mobile-break-point) {
            grid-auto-flow: row;
            justify-content: left;
            margin-top: 0.625rem;
            margin-right: 0;
          }

          button {
            border: 1px solid $ittPortalGreyDF;
            padding: 0.688rem 0.5rem;
            background-color: #fff;
            grid-gap: 0.25rem;
            align-content: center;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            margin: auto;
            height: 2.25rem;
            @include roundedcornersmixin(0.25rem);
            @include fontsize(0.625rem, normal, 700);

            .svg-inline--fa,
            img {
              margin-top: -0.2rem;
              @include fontsize(1rem, normal, 700);
            }

            @media (max-width: $mobile-break-point) {
              min-width: 11rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .form .form-group__radio {
      label {
        color: $ittPortalGrey3;
        margin-bottom: 2rem;
        font-size: 0.875rem;
      }
      input[type="text"] {
        &:not(:placeholder-shown) + label,
        &:focus + label {
          font-size: 0.75rem;
        }
      }
      .search-field-placeholder {
        font-size: 1rem;
      }
    }

    .form .form-group__radio.hideRadioIcon {
      label {
        background-color: $ittPortalGreyF7 !important;
        border: 0;
        display: -webkit-box;
        &.selected {
          border: 1px solid $ittPortalGrey3 !important;
        }
        > span {
          display: block;
          position: relative;
          @include fontsize(1rem, normal, 700);
          text-transform: uppercase;
          margin: 0 auto;
          &:before {
            position: absolute;
            content: "";
            margin-left: -1rem;
            margin-top: 0.3rem;
          }
          span {
            text-transform: none;
            @include fontsize(0.75rem, normal, 400);
          }
        }
        &.green > span:before {
          @include boolet($ittPortalGreen, $size: 0.563rem);
        }
        &.red > span:before {
          @include boolet($ittPortalRedDark, $size: 0.563rem);
        }
        &.grey > span:before {
          @include boolet($ittPortalGrey6, $size: 0.563rem);
        }
      }
    }

    #severity-form {
      label {
        > span {
          text-transform: none;
        }
      }
      .radio-label {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .analysis-card-menu-button-share-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.625rem;
      padding: 0 0.4rem;
      position: relative;
      h2 {
        padding: 0;
        margin: 0;
      }
    }

    .analysis-card-menu-button-share-content {
      display: flex;
      flex-direction: column;
      gap: 0.563rem;

      #analysis-card-menu-button-share-content-btn {
        border: 1px solid $ittPortalGreyDF;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1.125rem 1rem 1.063rem 1rem;
        @include fontsize(1rem, normal, 700);
        background-color: transparent;
      }

      .svg-inline--fa {
        width: 0.75rem !important;
        height: 0.75rem !important;
      }
    }

    #analysis-card-logs-history {
      #analysis-card-history-logs-header {
        margin-top: 0;
        .analysis-card-history-logs-close-btn-container {
          float: right;
          cursor: pointer;
        }
      }

      .analysis-card-history-log-entry {
        @include fontsize(0.625rem, normal, 400);
        margin-bottom: 0.5rem;
        .created {
          display: flex;
          align-items: center;

          img {
            width: 0.675rem;
            height: 0.675rem;
            margin-right: 0.5rem;
          }
        }
        .message {
          color: $ittPortalGrey9B;
          margin-top: 0.25rem;
        }
      }
    }
  }
}

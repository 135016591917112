.hierarchy-tree-view {
  position: relative;
  &.disabled {
    &:after {
      content: "";
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      position: absolute;
    }
  }
  .add-plant-search {
    form {
      display: inline-block;
      width: auto;
      .form-fields-container {
        min-height: auto;
        .form-field {
          $non-focused-width: 1rem;
          $z-index: 4;
          &:before {
            font-family: "Font Awesome 5 Free";
            content: "\f002";
            font-weight: 900;
            font-size: 1rem;
            position: absolute;
            top: $non-focused-width;
            left: $non-focused-width;
            width: $non-focused-width;
            height: $non-focused-width;
            z-index: $z-index;
          }
          #searchHierarchy {
            width: $non-focused-width;
            position: relative;
            border: 0;
            z-index: #{$z-index + 1};
            padding-left: #{2.5 * $non-focused-width};
            padding-right: 0;
            margin-right: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0;
            height: 2.5rem;
            border-bottom: 0;
            @include roundedcornersmixin(0);
            @include transition(width 0.3s ease-in);
            @include fontsize(0.75rem, normal, 400);
            &:focus,
            &:not(:placeholder-shown) {
              width: #{10 * $non-focused-width};
              border-bottom: 1px solid $ittPortalGreyDF;
            }

            + label {
              font-size: 0.6rem;
              padding-left: #{2.5 * $non-focused-width};
            }
          }
        }
      }
    }
  }
  ul {
    $account-img-width: 1.5rem;
    $li-padding: 0.625rem;
    &.hierarchy-plants li,
    &.account-name li.hierarchy-account {
      list-style: none;
      @include fontsize(0.75rem, normal, 700);
    }
    &.hierarchy-plants {
      padding-left: 0.43rem;
      margin-top: 0;
    }
    &.account-name {
      padding-left: 0;
      margin: 0;
      li.hierarchy-account {
        padding-left: #{$account-img-width + $li-padding};
      }
      .account-img {
        @include roundImage(
          $account-img-width,
          calc($account-img-width/4),
          $ittPortalGreyDF,
          $ittPortalBlue
        );
        left: 0;
      }
    }
    li {
      > ul {
        height: auto;
      }
      &:not(.open) {
        div .fa-chevron-down {
          @include rotate(-180deg);
        }

        & > ul {
          height: 0;
          overflow: hidden;
        }
      }
    }
    .hierarchy-account,
    .hierarchy-plant,
    .hierarchy-area,
    .hierarchy-zone {
      position: relative;
      border-bottom: 1px solid $ittPortalGreyF7;
      padding: $li-padding 0;

      .fa-chevron-down {
        cursor: pointer;
        font-size: 1rem;
        margin-right: 1.25rem;
        @include transition(all 0.3s ease-in);
      }
      .hierarchy-label {
        cursor: pointer;
      }
      .checkbox-container {
        $checkbox-padding: 1.3rem;
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: $li-padding $checkbox-padding 0 $checkbox-padding !important;
        border-left: 1px solid $ittPortalGreyF7;
        padding: 0 $checkbox-padding;
        height: 100%;
        width: #{2 * $checkbox-padding + 1rem};

        label {
          span {
            top: $li-padding !important;
            left: $checkbox-padding !important;
          }
        }
      }
    }
  }
  img,
  .fa-industry,
  .fa-map-marker-alt,
  .fa-location-dot,
  .fa-location-arrow {
    font-size: 1rem;
    margin-right: 0.625rem;
  }
}

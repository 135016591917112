.form {
  $form-gap: 1rem;
  $checkbox-width: 1.25rem;
  $number-width: 2rem;
  $gap: 0.5rem;
  $color-active: $ittPortalGrey3;

  width: 100%;
  scroll-behavior: smooth;

  .form__step {
    display: none;
    height: 0;

    .form-field-with-delete {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-field-with-unit {
        width: 100%;
      }

      button {
        margin-top: 0;
        background-color: transparent;
        z-index: 3;
        width: fit-content;
        padding-inline: 0;

        svg {
          position: absolute;
          right: 2.5rem;
          top: 6.4rem;
        }
      }

      .react-calendar__tile--active {
        background: $ittBlue2;
      }

      .delete-btn-with-arrow {
        right: 2.7rem;
      }

      .delete-btn-no-arrow {
        right: 1.55rem;
      }

      .delete-btn-with-threshold {
        right: 4rem;
      }
    }

    &.active {
      display: block;
      height: auto;
    }
    .filters-title {
      color: $ittPortalGrey3;
      @include fontsize(1.5rem, normal, 700);
      margin: 0.5rem 0 1rem 0;
    }
    .title {
      margin-bottom: 1.312rem;
      text-align: center;
      @include fontsize(1.5rem, 1.5rem, 700);
      + .subtitle {
        margin-top: -1rem;
      }
      .step-number {
        position: relative;
        display: inline-block;
        background-color: $color-active;
        color: #fff;
        text-align: center;
        height: $number-width;
        width: $number-width;
        margin-right: 1rem;
        @include fontsize(1rem, $number-width, 700);
        @include roundedcornersmixin(50%);
      }
    }
    .subtitle {
      margin-bottom: 1rem;
      text-align: center;
      @include fontsize(0.875rem, 0.875rem, 400);
    }
  }

  .arrow-down {
    @include arrow(#000, 2px);
    @include transform(rotate(45deg));
    position: absolute;
    right: 1.1rem;
    top: 1.2rem;
    height: 0.55rem;
    width: 0.55rem;
    z-index: auto;
    transition: all 0.2s;
    cursor: pointer;
  }
  .clear-field {
    cursor: pointer;
    height: 1.5rem;
    width: 1rem;
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 3;
  }
  .field-with-slide-over {
    position: relative;
    z-index: auto;

    .form-field {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: transparent !important;
        cursor: pointer;
      }
    }
    &.disabled-field {
      .form-field {
        &:after {
          cursor: not-allowed;
        }
      }
    }

    .radio-label {
      @include fontsize(1.5rem, normal, 700);
      margin-bottom: 1.5rem;
    }

    .slide-over-container {
      > .form-group {
        //height: calc(100vh - 6rem);
        height: auto;
        overflow-y: auto;

        .form-field {
          label {
            @include fontsize(0.875rem, normal, 400);
            color: $ittPortalGrey3;
            margin-bottom: 2rem;
          }
        }
      }
      .form-button-container {
        padding: 0 10% 0.5rem 10%;
      }
    }
    select:disabled,
    input[type="text"]:disabled {
      //background-color: transparent !important;
      cursor: pointer;
    }
  }
  .form-field-with-unit {
    position: relative;

    &.half-width {
      $half-width-margin: 0.5rem;
      width: calc(50% - #{$half-width-margin});
      display: inline-grid;
      position: relative;
      &:nth-of-type(2n + 1) {
        margin-right: $half-width-margin;
      }
      &:nth-of-type(2n) {
        margin-left: $half-width-margin;
      }

      .form-group.half-width {
        width: 100%;
      }
    }

    .form-field-unit {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 1rem;
      @include fontsize(0.875rem, normal, 400);
      color: $ittPortalGrey9B;
      display: grid;
      align-content: center;
      z-index: 2;

      &__threshold {
        right: 3.5rem;
      }
    }
  }
  .form-group {
    margin: 0 0 0.5rem 0;
    &.half-width {
      $half-width-margin: 0.5rem;
      width: calc(50% - #{$half-width-margin});
      display: inline-grid;
      position: relative;
      &:nth-of-type(2n + 1) {
        margin-right: $half-width-margin;
      }
      &:nth-of-type(2n) {
        margin-left: $half-width-margin;
      }
    }
    &.two-thirds-width {
      $two-thirds-width-margin: 0.5rem;
      width: calc(66.67% - #{$two-thirds-width-margin});
      display: inline-grid;
      position: relative;
      &:nth-of-type(2n + 1) {
        margin-right: $two-thirds-width-margin;
      }
      &:nth-of-type(2n) {
        margin-left: $two-thirds-width-margin;
      }
    }
    &.one-third-width {
      $one-third-width-margin: 0.5rem;
      width: calc(33.33% - #{$one-third-width-margin});
      display: inline-grid;
      position: relative;
      &:nth-of-type(2n + 1) {
        margin-right: $one-third-width-margin;
      }
      &:nth-of-type(2n) {
        margin-left: $one-third-width-margin;
      }
    }
    &.third-width {
      $third-width-margin: 0.5rem;
      width: calc(33.33% - #{$third-width-margin});
      display: inline-grid;
      margin-right: calc($third-width-margin/2);
      margin-left: calc($third-width-margin/2);
      &:nth-of-type(3n + 1) {
        margin-left: 0;
      }
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    &__select {
      width: auto;
      /*overflow-x: hidden;*/
      overflow: hidden;
    }
    &__multiple-checkbox-and-text {
      padding-right: 1rem;
      border-right: 3px solid $ittPortalGreyF7;

      + .form-group__multiple-checkbox-and-text {
        border-right: 0 solid $ittPortalGreyF7;
      }
      .label {
        font-weight: 700;
        margin-bottom: 0.625rem;
      }
      .desc {
        @include fontsize(0.75rem, normal, 400);
        margin-bottom: 0.5rem;
      }
    }
    &__checkbox {
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $ittPortalGreyF7;
      label {
        line-height: 1.1rem;
        margin: 0;
      }
      .form-field {
        min-height: $checkbox-width;
      }
    }
    &__password {
      svg[class*="fa-eye"] {
        position: absolute;
        right: $form-gap;
        top: $form-gap;
        cursor: pointer;
        z-index: 2;
      }
    }
    &__radio {
      $radio-button-width: 1.25rem;
      $radio-button-inner-percentage: 0.666;
      margin-bottom: $form-gap;
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        width: 0;
        padding: 0;
        margin: 0;
        height: 0;
      }

      [type="radio"]:checked:after {
        content: "";
        width: #{$radio-button-width * $radio-button-inner-percentage};
        height: #{$radio-button-width * $radio-button-inner-percentage};
        background: $ittPortalGreen;
        position: absolute;
        top: #{$radio-button-width * calc($radio-button-inner-percentage / 4)};
        left: #{$radio-button-width * calc($radio-button-inner-percentage / 4)};
        border-radius: 100%;
        @include transform(all 0.2s ease);
      }
      label {
        position: relative;
        cursor: pointer;
        padding-left: #{$form-gap + $radio-button-width} !important;
        min-height: 1.5rem;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: $radio-button-width;
          height: $radio-button-width;
          border: 1px solid $ittPortalGreyDF;
          border-radius: 100%;
          background: #fff;
        }
        .choice-desc {
          display: block;
          color: $ittPortalGrey9B;
          @include fontsize(0.75rem, normal, 400);

          ul {
            margin: 0.25rem 0 0 0;
            padding: 0 0 0 1rem;

            li {
              padding: 0;
            }
          }
        }
      }
      svg {
        margin-right: 0.5rem;
      }

      &.hideRadioIcon {
        [type="radio"]:checked:after {
          display: none;
        }
        label {
          padding: 0.875rem !important;
          background-color: $ittPortalGreyF7;
          @include roundedcornersmixin(0.25rem);
          text-align: center;
          &:not(:last-of-type) {
            margin-bottom: 0.25rem;
          }

          &:before {
            display: none;
          }

          &.selected {
            border: 1px solid $ittPortalGrey3;
          }
        }
      }
    }
    &__date-with-example {
      input {
        position: relative !important;
        z-index: 2 !important;
        background-color: #fff !important;
        width: calc(100% - #{2.5 * $form-gap});
        border-right: 0 !important;
        border-radius: 0.25rem 0 0 0.25rem !important;
      }
      select {
        position: absolute !important;
        z-index: 1 !important;
        width: 100% !important;
        color: transparent;
        option {
          color: #000;
        }
        &.error {
          background-color: transparent !important;
        }
      }
      .open-date-format {
        @include arrow(#000, 2px);
        @include transform(rotate(45deg));
        cursor: pointer;
        position: absolute;
        right: 1.1rem;
        top: 1.2rem;
        height: 0.55rem;
        width: 0.55rem;
        z-index: 3;
        transition: all 0.2s;
        &.open {
          @include transform(rotate(-135deg));
        }
      }
    }
    &__asset-image {
      $container-width: 8rem;
      $container-height: 4.6875rem;
      position: relative;
      height: 13.313rem;
      width: 15.688rem;
      order: 1;
      .form-field {
        width: 100%;
        height: 100%;
        align-items: center;
        border: 1px solid $ittPortalGreyDF;
        .img-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            min-width: 5.188rem;
            min-height: 5.188rem;
            max-height: 9.188rem;
            max-width: 14rem;
          }
          .asset-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 1rem;
            z-index: 3;
            color: transparent;
            cursor: pointer;
            opacity: 0;
          }
        }
        .asset-image-upload-text {
          width: 100%;
          text-align: center;
          font-size: 0.75rem;
          font-weight: bold;
          border-top: 1px solid $ittPortalGreyDF;
          padding: 1rem;
        }
      }
    }
    &__account-image,
    &__profile-image {
      $container-width: 8rem;
      $container-height: 4.6875rem;
      $icon-width: 4rem;
      width: $container-width;
      height: $container-height;
      .form-field {
        display: block !important;
        position: relative;
        .img-container {
          width: $container-width;
          height: $container-height;
          display: inline-block;
          text-align: center;
          position: relative;

          .fa-pen {
            $pen-width: 1.5rem;
            font-weight: 900;
            font-size: $pen-width;
            width: $pen-width;
            height: $pen-width;
            position: absolute;
            right: 0;
            top: 1.25rem;
            z-index: 2;
          }

          img {
            width: $icon-width;
            height: $icon-width;
            object-fit: contain;
            @include roundedcornersmixin(calc($icon-width / 2));
          }

          .initial-letters {
            position: absolute;
            bottom: 0;
            left: 0.5rem;
            padding: 0.125rem 0.25rem;
            background-color: $ittPortalGrey3;
            color: #fff;
            @include roundedcornersmixin(0.25rem);
            @include fontsize(1.5rem, 1.5rem, 700);
          }
          .account-image,
          .profile-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 3;
            color: transparent;
            cursor: pointer;
            opacity: 0;
          }
        }
      }
    }
    &__account-image {
      $picture-width: 4rem;
      $picture-padding: calc($picture-width / 4);
      $fapen-width: 1.5rem;
      $fapen-padding: 0.5rem;
      $fapen-mobile-width: 1rem;
      $change-account-width: 3.5rem;
      $picture-grid-width: #{calc($picture-width + $picture-padding)};
      $picture-grid-width-mobile: #{calc(
          0.75 * ($picture-width + $picture-padding)
        )};
      $fapen-grid-width: 0; //#{calc($fapen-width + $fapen-padding)};
      $fapen-grid-width-mobile: 0; //#{calc($fapen-mobile-width + $fapen-padding)};
      $account-name-grid-padding: 1rem;
      $account-name-grid-width: #{calc(
          100% - $picture-grid-width - $change-account-width +
            $account-name-grid-padding
        )};
      $account-name-grid-width-mobile: #{calc(
          100% - $picture-grid-width-mobile - $change-account-width +
            $account-name-grid-padding
        )};
      position: relative;
      padding-right: 1.5rem;
      height: 100%;
      width: auto;
      margin: 0 auto;
      cursor: pointer;
      vertical-align: middle;
      @include fontsize(1.5rem, normal, 700);
      @media (max-width: $mobile-break-point) {
        font-size: 1rem;
      }
      .form-field {
        width: fit-content;
        margin: 0 auto;
        .img-container {
          display: grid;
          align-items: center;
          width: auto;
          height: $picture-width;
          grid-template-columns: $picture-grid-width $account-name-grid-width $change-account-width;
          @media (max-width: $mobile-break-point) {
            grid-template-columns: $picture-grid-width-mobile $account-name-grid-width-mobile $change-account-width;
          }
          img {
            margin-right: 1rem;
            @include roundImageRelative(
              $picture-width,
              $picture-padding,
              $ittPortalGreyDF,
              $ittPortalBlue
            );
            @media (max-width: $mobile-break-point) {
              @include roundImageRelative(
                #{calc(0.75 * $picture-width)},
                #{calc(0.75 * $picture-padding)},
                $ittPortalGreyDF,
                $ittPortalBlue
              );
            }
          }

          .account-name {
            align-self: center;
            display: inline-block;
            padding-right: $account-name-grid-padding;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
          }
          .fa-pen {
            position: relative;
            top: 0;
            width: $fapen-width;
            height: $fapen-width;
            @media (max-width: $mobile-break-point) {
              width: $fapen-mobile-width;
              height: $fapen-mobile-width;
            }
          }
          .account-image {
            width: 100%;
          }
          .change-account {
            @include arrow($ittPortalGrey9B, 0.25rem);
            @include transform(translateY(-50%) rotate(45deg));
            transition: all 0.2s;
            width: 1rem;
            height: 1rem;
            position: relative;
            margin: auto;
            top: 0.25rem;
            right: 0.5rem;
            z-index: 3;
            &.open {
              @include transform(translateY(-50%) rotate(-135deg));
            }
          }
        }
      }
    }
    &__profile-initialize-image {
      $container-width: 11.5rem;
      $icon-width: 4rem;
      .form-field {
        display: block !important;
        .img-container {
          width: $container-width;
          height: $container-width;
          display: inline-block;
          text-align: center;
          background-color: $ittPortalGreyF7;
          position: relative;

          & + .img-container {
            margin-left: 0.875rem;
          }
          img {
            width: $icon-width;
            height: $icon-width;
            margin: 2.5625rem auto 0;
          }
          .initial-letters {
            position: absolute;
            top: calc(1.5rem + #{$icon-width});
            left: 2.5rem;
            padding: 0.125rem 0.5rem;
            background-color: $ittPortalGrey3;
            color: #fff;
            @include roundedcornersmixin(0.25rem);
            @include fontsize(1.5rem, 1.5rem, 700);
          }
          .text-container {
            position: absolute;
            bottom: 1rem;
            height: auto;
            font-weight: 700;
            left: 0;
            right: 0;
          }
          .profile-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 3;
            color: transparent;
            cursor: pointer;
            opacity: 0;
          }
          .avatar {
            @include vertical-align(absolute);
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            right: 0;
            left: 0;
            z-index: 2;
          }
        }
      }
    }
    &__calendar {
      position: relative;

      .form-field {
        input[type="text"] {
          cursor: pointer !important;
        }
      }
      .react-calendar-container {
        position: absolute;
        z-index: 4;
        top: #{3.5 * $form-gap};
        display: none;
        background-color: white;
        border: 1px solid $ittPortalGrey3;
        @include roundedcornersmixin(0.25rem);
        width: 100%;

        &.active {
          display: block;
        }
        .react-calendar {
          width: 100%;
        }
      }
    }
    &__with-slideover {
      + .form-group .form-field {
        cursor: pointer;
        input[type="text"] {
          padding: 1rem 2.1rem 1rem 1rem;
          cursor: pointer;
        }
      }
    }
    .file-upload-container {
      label {
        position: relative;
        cursor: pointer;
        display: inline-block;
        border: 0.125rem solid $ittPortalGreyDF;
        width: 100%;
        border-radius: 0.25rem;

        &:after {
          content: "+";
          position: absolute;
          right: 1rem;
          color: $ittPortalGrey3;
          @include fontsize(1.4rem, normal, 400);
          @include vertical-align(absolute);
        }

        .file-upload-placeholder-inner {
          margin-top: 0.5rem;
          font-size: 0.75rem;
        }

        .selected-file-name {
          margin: 0.25rem 0 0.5rem 0;
          color: $ittPortalGrey3;
          font-weight: 700;
        }

        input {
          position: absolute;
          left: -1000rem;
          display: none;
        }
      }
    }
    &.read-only {
      &.hide-on-configuration {
        display: none;
      }
      .form-field {
        input[type="text"] {
          &:disabled {
            background-color: transparent;
            border: none;
            padding: 0;
            cursor: auto;

            + label {
              cursor: default;
              padding-left: 0;
            }
          }
        }
      }
    }
    .form__message {
      font-size: 0.75rem;
      line-height: 0.75rem;
      margin-top: 0.25rem;
      color: $ittPortalGrey9B;
    }
    .secondary-label-desc {
      @include fontsize(0.875rem, normal, 400);
      margin-bottom: 0.8125rem;
    }
    .add-new-choice-container {
      $font-size: 0.625rem;
      $font-weight: 700;
      @include fontsize($font-size, normal, $font-weight);
      margin-bottom: 0.5rem;
      button {
        @include fontsize($font-size, normal, $font-weight);
        width: auto;
        margin: 0;
        padding: 0 0 0 1rem;
        border: none;
        background-color: transparent;
        position: relative;
        z-index: 3;
        &:before {
          content: "+";
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .form-field {
      display: flex;
      flex-flow: column; //column-reverse;
      position: relative;
      overflow: hidden;
      &.error {
        border: 1px solid $ittPortalRedDark;
        background-color: $ittPortalPink;
        @include roundedcornersmixin(0.25rem);
        label {
          color: $ittPortalRedDark;
        }
        span {
          color: $ittPortalRedDark;
        }
        .arrow-down {
          border-color: $ittPortalRedDark;
        }
      }

      label,
      input {
        transition: all 0.2s;
        touch-action: manipulation;
      }

      input[type="text"],
      input[type="number"],
      input[type="email"],
      input[type="password"],
      input[type="url"],
      input[type="tel"],
      input[type="submit"],
      input[type="time"],
      select,
      textarea,
      .on-slideover-form {
        position: relative;
        margin: 0;
        font-weight: 700;
        font-size: $form-gap;
        line-height: $form-gap;
        border: 1px solid $ittPortalGreyDF;
        font-family: inherit;
        padding: $form-gap;
        cursor: text;
        min-height: 3.125rem;
        outline: 0;
        z-index: 2;
        background-color: transparent;
        @include roundedcornersmixin(0.25rem);
        @include appearance(none);
        &:disabled {
          color: $ittPortalGreyDF;
          cursor: not-allowed;
          + label {
            color: $ittPortalGreyDF;
          }
        }
        &:focus {
          outline: 0;
          border: 1px solid $ittPortalGreyDF;
        }
        &.error {
          border: 1px solid $ittPortalRedDark;
          background-color: $ittPortalPink;
          & + label {
            color: $ittPortalRedDark;
          }
        }
        &:-webkit-autofill + label,
        &:placeholder-shown + label {
          cursor: text;
          max-width: 66.66%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          font-weight: 700;
          transform: translate(0rem, 1.2rem) scale(1);
        }
        &:focus::-webkit-input-placeholder {
          opacity: 1;
        }
        &:not(:placeholder-shown) + label,
        &:focus + label {
          transform: translate(0rem, 0.2rem) scale(1);
          cursor: pointer;
          font-weight: 400;
          font-size: 0.75rem;
        }

        + label {
          position: absolute;
          top: 0;
        }
      }

      .on-slideover-form {
        label {
          padding-left: 0;
          cursor: text;
          max-width: 66.66%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          font-weight: 700;
          transform: translate(0rem, 1.2rem) scale(1);
          position: absolute;
          top: 0;
        }
      }
      .on-slideover-form.has-value {
        label {
          transform: translate(0rem, 0.2rem) scale(1);
          cursor: pointer;
          font-weight: 400;
          font-size: 0.75rem;
        }
      }
      textarea {
        height: 160px;
        resize: none;
        padding-top: 1.5rem;
        //padding: 0.5em 0;
        &:-webkit-autofill + label,
        &:placeholder-shown + label {
          max-width: 100%;
        }
      }

      select {
        cursor: pointer;
        padding: 1rem 2rem 1rem 1rem;
      }
      .required {
        color: $ittPortalRedDark;
      }

      label {
        font-weight: 400;
        font-size: $form-gap;
        line-height: $form-gap;
        padding-left: $form-gap;
        color: $ittPortalGrey9B;
        z-index: 3;
        &.checkbox-label {
          padding-left: #{$checkbox-width + $form-gap};
          font-size: 0.875rem;
          position: relative;
          &-threshold {
            position: absolute;
            top: 1rem;
            right: 1rem;
            span.label-checkbox {
              width: 1rem;
              height: 1rem;
              position: absolute;
              color: transparent;
            }
          }

          a {
            color: $ittBlueLight;
            text-decoration: none;
          }
        }
      }
      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
      }
    }

    .checkbox-list {
      .checkbox-label {
        border-bottom: 1px solid $ittPortalGreyF7;
        padding: 1rem 0;
        input[type="checkbox"] + .checkmark {
          margin-top: 0.8rem;
        }
      }
    }
  }
  .button-holder {
    box-shadow: 0 -0.125rem 0.25rem 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
  }
  input[type="submit"],
  button {
    margin-top: $form-gap;
    font-size: $form-gap;
    width: 100%;
    &:focus {
      outline: none;
      outline-offset: 0;
    }
  }
  &.form-containing-grid .grid-buttons-container {
    input[type="submit"],
    button {
      margin-top: 0;
      width: unset;
    }
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    + .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: $checkbox-width;
      width: $checkbox-width;
      border: 2px solid $ittPortalGreyDF;
      @include roundedcornersmixin(3px);
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    &:hover ~ .checkmark {
      background-color: $ittPortalGreyC;
    }
    &:checked ~ .checkmark {
      background-color: $ittPortalGreen;
      border: 0;
      &:after {
        display: inline-block;
        left: 0.35rem;
        top: 0.05rem;
        width: 0.45rem;
        height: 0.9rem;
        border: solid white;
        border-width: 0 2px 2px 0;
        @include transform(rotate(45deg));
      }
    }
  }

  #sensorsGrid-grid-data {
    .form-fields-container {
      min-height: fit-content;
      .form__step {
        padding: 0;
      }
    }
  }
}
